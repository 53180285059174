import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  split,
  from,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError as ErrorLink } from '@apollo/client/link/error';
import { ApolloProvider } from '@apollo/client/react';
import { createUploadLink } from 'apollo-upload-client'; // 使用graph上傳資料
import { IS_CLIENT, HTTP_URI } from '@/const';
import { sentryCaptureErr } from '@/plugin/sentry';
import useAlertMessage from '@/hook/useAlertMessage';
import { useLogoutProcess } from '@/hook/useLogout';
import {
  getToken,
  getDeviceId,
  isLogoutErrorCode,
  isSendSentryErrorCode,
  getErrorMessage,
} from '@/utils';
import { wsLink } from './ws';

/**
 * authMiddleware
 */
const traceID = () => Math.random().toString(36).substring(2, 9);

const authMiddleware = new ApolloLink((operation, forward) => {
  // 處理 app & client header
  let headers = {};
  headers = {
    Authorization: IS_CLIENT
      ? `${getToken(true, 'clientUserInfo')}`
      : `${getToken(true)}`,
    'X-Device-ID': IS_CLIENT
      ? `${getDeviceId('clientUserInfo')}`
      : `${getDeviceId()}`,
    'X-Trace-ID': traceID(),
  };
  operation.setContext({ headers: { ...headers } });
  return forward(operation);
});

/**
 * http 連線處理
 */
const httpLink = createUploadLink({
  uri: HTTP_URI,
  credentials: 'include', // TODO 不確定
});

// 這裡區分 query 的時候要用哪種模式 wsLink 與 httpLink
// 由 apollo 提供的 getMainDefinition 去判斷
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

/**
 * 建立連線
 *  */

const createErrorLink = onError => {
  return new ErrorLink(({ graphQLErrors, networkError, operation }) => {
    onError({
      graphQLErrors,
      networkError,
      extras: operation?.getContext(),
      operation,
    });
  });
};

const createApolloClient = ({ onError }) => {
  return new ApolloClient({
    link: from([authMiddleware, createErrorLink(onError), splitLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            listMenu: {
              merge(existing = [], incoming = []) {
                return incoming;
              },
            },
            authority: {
              merge(existing = [], incoming = []) {
                return incoming;
              },
            },
          },
        },
        MsgInvite: { keyFields: ['inviteeUserIDs'] },
        ListUserRoomResp: { keyFields: ['userRooms'] },
        ConsultingRoomOrigin: { keyFields: ['id', 'customerServices'] },
        RoomConsultingDetail: { keyFields: ['roomID'] },
      },
    }),
  });
};

const CusApolloProvider = ({ children, ...props }) => {
  const { onErrorMsg } = useAlertMessage();
  const { logoutProcess } = useLogoutProcess();

  const handleApiError = ({
    graphQLErrors,
    networkError,
    operation,
    extras,
  }) => {
    const { operationName, variables } = operation;
    const headers = operation.getContext().headers;
    const { isShowGeneralError = true, getError = () => {} } = extras || {};
    if (graphQLErrors) {
      graphQLErrors.forEach(err => {
        const errCode = err?.message || '';
        console.log('errCode', errCode);
        console.log('variables', variables);
        console.log('operationName', operationName);
        console.log('errStr', JSON.stringify(err || ''));

        // 需要登出的錯誤
        if (isLogoutErrorCode(errCode)) logoutProcess();
        // 需要送sentry的錯誤
        if (isSendSentryErrorCode(errCode)) {
          // sentry capture
          sentryCaptureErr(
            `SendSentryErrorCode[${errCode}]-${networkError?.name || ''}`,
            {
              networkError: networkError ? JSON.stringify(networkError) : '',
              graphQLErrors: JSON.stringify(err || ''),
              operationName,
              traceID: headers['X-Trace-ID'],
              variables: JSON.stringify(variables),
            },
          );
        }
        if (isShowGeneralError) onErrorMsg(getErrorMessage(errCode));
        // 將錯誤碼和原來的處理方式傳回使用api的組件
        getError(errCode);
      });
    } else if (networkError) {
      let errorTitle = `${networkError}`;
      // network offline
      if (typeof window !== 'undefined' && !window.navigator.onLine) {
        errorTitle = '网路连线不稳';
      }
      onErrorMsg(errorTitle);
      // sentry capture
      // sentryCaptureErr(
      //   `${networkError}-${process.env.REACT_APP_NAME}`,
      //   {
      //     traceID: headers['X-Trace-ID'],
      //     graphQLErrors: JSON.stringify(graphQLErrors || ''),
      //     operationName,
      //     variables: JSON.stringify(variables),
      //   });
    }
  };

  const client = createApolloClient({ onError: handleApiError });

  return (
    <ApolloProvider client={client} {...props}>
      {children}
    </ApolloProvider>
  );
};

export { CusApolloProvider };
