import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { CusApolloProvider } from './plugin/apollo';
import { CusThemeProvider } from './plugin/styled';
import { initSentry } from './plugin/sentry';
import store from './store';
import App from '@APP_NAME';
import './globel.css';

initSentry();
function Root() {
  return (
    <ReduxProvider store={store}>
      <CusApolloProvider>
        <BrowserRouter>
          <CusThemeProvider>
            <App />
          </CusThemeProvider>
        </BrowserRouter>
      </CusApolloProvider>
    </ReduxProvider>
  );
}
render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
