import { Base64 as base64 } from 'js-base64';

//加密規則
const encode = value => {
  try {
    return base64.encode(encodeURIComponent(JSON.stringify(value)));
  } catch (error) {
    console.log('encode error', error, value);
    return '';
  }
};

//解密規則

export const decode = value => {
  try {
    return value ? decodeURIComponent(base64.decode(value)) : '';
  } catch (error) {
    console.log('decode error', error, value);
    return '';
  }
};

export const decodeToJson = value => {
  try {
    if (typeof value === 'undefined') return '';
    return value ? JSON.parse(decodeURIComponent(base64.decode(value))) : '';
  } catch (error) {
    console.log('decodeToJson error', error, value);
    return '';
  }
};

const storageList = {
  loginInfo: 'loginInfo', // {Object}
  meInfo: 'meInfo', // {Object}
  authRoutesInfo: 'authRoutesInfo', // [array]
  errorInfo: 'errorInfo', // {Object}
  clientUserInfo: 'clientUserInfo',
  businessSystemMsgLog: 'businessSystemMsgLog',
  authRoutesKey: 'authRoutesKey',
};

export const isStorageAccessAllow = () => {
  try {
    if ('localStorage' in window && window['localStorage'] !== null) {
      return true;
    } else {
      console.log('getStorage error');
      return false;
    }
  } catch (e) {
    console.log('getStorage error', e);
    return false;
  }
};

/**
 * 設置 Storage
 */
export const setStorage = (key = '', value = '') => {
  if (isStorageAccessAllow()) {
    if (key && storageList[key]) {
      localStorage && localStorage.setItem(storageList[key], encode(value));
    } else {
      console.error('儲存 localstorage 的格式錯誤，請確認有設定相關的數值');
    }
  }
};

/**
 * 取得 Storage
 */
export const getStorage = key => {
  if (isStorageAccessAllow()) {
    let value = localStorage ? localStorage.getItem(storageList[key]) : '';
    return value ? decodeToJson(value) : '';
  }
  return '';
};

export const asyncLocalStorage = {
  setItem: async (key, value) => {
    await null;
    if (isStorageAccessAllow()) return localStorage.setItem(key, encode(value));
    return '';
  },
  getItem: async key => {
    await null;
    if (isStorageAccessAllow()) {
      const value = decodeToJson(localStorage.getItem(key));
      return value;
    }
    return '';
  },
  removeItem: async key => {
    await null;
    if (isStorageAccessAllow()) localStorage.removeItem(key);
  },
};

/**
 * 取得 token
 */
export const getToken = (showBearer = false, key = 'loginInfo') => {
  const token = getStorage(key)?.token || '';
  return token && showBearer ? `Bearer ${token}` : token;
};

/**
 * 取得 device id
 */
export const getDeviceId = (key = 'loginInfo') => {
  return getStorage(key)?.deviceUID || '';
};

/**
 * 刪除 Storage
 */
export const removeStorage = key => {
  if (key && storageList[key]) {
    localStorage.removeItem(key);
  } else {
    console.error('欲刪除的 localstorage 格式有誤，請確認key值');
  }
};

/**
 * 刪除全部 Storage
 */
export const removeAllStorage = () => {
  let list = Object.keys(storageList);
  list.forEach(i => {
    localStorage.removeItem(i);
  });
};
