import { createSlice } from '@reduxjs/toolkit';

/**
 * 房間相關
 */
const initialState = {
  currentInRoomID: 0, // 當前打inRoom 的房間
  currentRoomID: 0,
  roomDetail: null,
  roomUsersMap: {},

  // 其他
  csTotalUnRead: 0, // 諮詢房未讀訊息總數
  redPoint: {},
};

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setCurrentInRoomID: (state, { payload }) => {
      state.currentInRoomID = payload;
    },
    setRoomDetail: (state, { payload }) => {
      state.roomDetail = payload;
      state.currentRoomID = payload?.room.roomID || 0;
    },
    setRoomUsersMap: (state, { payload }) => {
      state.roomUsersMap = payload;
    },
    // 其他
    setCsTotalUnRead: (state, { payload }) => {
      state.csTotalUnRead = payload;
    },
    setRedPoint: (state, { payload }) => {
      state.redPoint = payload;
    },
  },
});

export const {
  setCurrentInRoomID,
  setRoomDetail,
  setRoomUsersMap,
  setCsTotalUnRead,
  setRedPoint,
} = roomSlice.actions;
export default roomSlice.reducer;
