/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';
import useRWD from '@/hook/useRWD';
import { Box } from '@/plugin/wrap-comp';

function Container({
  headerLogoURL = '',
  headerBgColor = '',
  bannerImgURL = '',
  bannerLinkURL = '',
  chatRoomHeader = null,
  chatRoomContent = null,
  chatRoomContentCourse = null,
}) {
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const { isMobileSize, isMobileDevice } = useRWD();
  const showLogoHeader = !!headerLogoURL;
  const showBanner = !!bannerImgURL;

  const borderSize = isMobileSize || isMobileDevice ? 0 : 12;
  const wrapStyle =
    isMobileSize || isMobileDevice ? { height: '100%' } : { height: '700px' };
  const chatRoomHeaderStyle = showLogoHeader
    ? {}
    : {
        borderTopRightRadius: borderSize,
        borderTopLeftRadius: borderSize,
        overflow: 'hidden',
      };

  useEffect(() => {
    forceUpdate();
  }, [bannerImgURL]);

  // 偵測橫豎屏自動 reload
  // useEffect(() => {
  //   window.addEventListener(
  //     'onorientationchange' in window ? 'orientationchange' : 'resize',
  //     function () {
  //       if (window.orientation === 180 || window.orientation === 0) {
  //         alert('偵測橫豎屏自動 reload');
  //         window.location.reload();
  //       }
  //       if (window.orientation === 90 || window.orientation === -90) {
  //         alert('偵測橫豎屏自動 reload');
  //         window.location.reload();
  //       }
  //     },
  //     false,
  //   );
  // }, []);

  return (
    <>
      <Box
        position='relative'
        boxShadow='0px 0px 10px #19191936'
        display='flex'
        flexDirection='column'
        width={isMobileDevice ? '100%' : '500px'}
        borderRadius={borderSize}
        {...wrapStyle}>
        {showLogoHeader && (
          <LogoHeader
            logoURL={headerLogoURL}
            bgColor={headerBgColor}
            borderSize={borderSize}
          />
        )}
        <Box {...chatRoomHeaderStyle}>{chatRoomHeader}</Box>

        {showBanner && <Banner imgURL={bannerImgURL} link={bannerLinkURL} />}

        {chatRoomContent}
        {chatRoomContentCourse}
      </Box>
    </>
  );
}

const LogoHeaderWrap = styled.div`
  border-top-right-radius: ${({ borderSize }) => `${borderSize}px`};
  border-top-left-radius: ${({ borderSize }) => `${borderSize}px`};
  padding: 10px;
  background: ${({ theme, bgColor }) => bgColor || theme.secondary};
  & img {
    width: 30%;
  }
`;

const LogoHeader = ({ logoURL = '', bgColor = '', borderSize = 0 }) => (
  <LogoHeaderWrap bgColor={bgColor} borderSize={borderSize}>
    {logoURL && <img src={logoURL} alt='LogoUrl' />}
  </LogoHeaderWrap>
);

const BannerWrap = styled.div`
  width: 100%;
  cursor: pointer;
  & img {
    width: 100%;
  }
`;

const Banner = ({ imgURL = '', link = '' }) => {
  const onClick = () => {
    if (!link) return;
    let a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    a.click();
  };
  return (
    <BannerWrap onClick={onClick}>
      {imgURL && <img src={imgURL} alt='LogoUrl' />}
    </BannerWrap>
  );
};

export default Container;
