import { gql } from '@apollo/client';

export const HOSTS_DENY_FIELD = gql`
  fragment hostsDenyField on HostsDeny {
    id
    ipAddress
    isEnabled
    remark
    createdAt
    createUserID
    updatedAt
    updateUserID
  }
`;

/**
 *  行銷問候語設定
 */
export const LIST_HOSTS_DENY = gql`
  ${HOSTS_DENY_FIELD}
  query listHostsDeny(
    $filter: HostsDenyFilterInput
    $pagination: PaginationInput
  ) {
    listHostsDeny(filter: $filter, pagination: $pagination) {
      hostsDenys {
        ...hostsDenyField
      }
      meta {
        total
      }
    }
  }
`;

export const CREATE_HOSTS_DENY = gql`
  ${HOSTS_DENY_FIELD}
  mutation createHostsDeny($in: HostsDenyCreateInput!) {
    createHostsDeny(in: $in) {
      ...hostsDenyField
    }
  }
`;

export const UPDATE_HOSTS_DENY = gql`
  ${HOSTS_DENY_FIELD}
  mutation updateHostsDeny(
    $filter: HostsDenyFilterInput!
    $in: HostsDenyUpdateInput!
  ) {
    updateHostsDeny(filter: $filter, in: $in) {
      ...hostsDenyField
    }
  }
`;

export const DELETE_HOSTS_DENY = gql`
  mutation deleteHostsDeny($filter: HostsDenyFilterInput!) {
    deleteHostsDeny(filter: $filter)
  }
`;
