import React from 'react';
import { Box } from '@WRAP_COMP';
import { MsgSystemType } from '@/utils';
import PayList from './PayList';
import Oder from './Oder';

function MsgSystem({ msgID, type, content, roomID, postMsg }) {
  const systemType = {
    [MsgSystemType['PayList']]: content => (
      <PayList msgID={msgID} roomID={roomID} {...content} />
    ),
    [MsgSystemType['UserOrder']]: content => (
      <Oder msgID={msgID} roomID={roomID} postMsg={postMsg} {...content} />
    ),
    [MsgSystemType['Text']]: content => (
      <div dangerouslySetInnerHTML={{ __html: `<div>${content.text}</div>` }} />
    ),
  };
  return systemType[type] ? <Box>{systemType[type](content)}</Box> : null;
}

export default MsgSystem;
