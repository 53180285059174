import React from 'react';
import styled from 'styled-components';
const ResetHtml = styled.div`
  p {
    margin-bottom: 0px;
  }
`;

function ResetHtmlWrap({ html }) {
  return <ResetHtml dangerouslySetInnerHTML={{ __html: html }} />;
}

export default ResetHtmlWrap;
