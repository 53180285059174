import { gql } from '@apollo/client';
import { COMMENT_MENU } from './menuList';

// 登入
export const POST_LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    Login(in: { username: $username, password: $password }) {
      token
      deviceUID
      userAgent
    }
  }
`;

// 登出
export const POST_LOGOUT = gql`
  mutation Logout {
    Logout
  }
`;

// user login 資訊
export const LIST_USER_LOGIN_INFO = gql`
  query listUserLoginHistory(
    $filter: UserLoginHistoryFilterInput!
    $pagination: PaginationInput
  ) {
    listUserLoginHistory(filter: $filter, pagination: $pagination) {
      userLoginHistories {
        id
        userID
        ipAddress
        country
        administrativeArea
        deviceOS
        createdAt
      }
      meta {
        total
      }
    }
  }
`;

// 取得個人資料 以及 可見的 menu
export const GET_ME = gql`
  ${COMMENT_MENU}
  query Me {
    Me {
      id
      spinachUserID
      accountType
      status
      username
      aliasName
      avatarURL
      isOnline
      isStealth
      devices {
        id
        userID
        deviceUID
        isBind
        createdAt
        updatedAt
      }
      whitelists {
        id
        userID
        ipAddress
        createdAt
      }
      setting {
        id
        allowLoginStartAt
        allowLoginEndAt
      }
      menu {
        ...menuFields
        next {
          ...menuFields
          next {
            ...menuFields
          }
        }
      }
    }
  }
`;
