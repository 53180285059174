import React from 'react';
import styled from 'styled-components';
import alipayUrl from './alipay.png';
import wxUrl from './wx.png';
import bankUrl from './bank.png';
// import bankUrl from './bank.png';
import { Box } from '@WRAP_COMP';
import { PayType } from '@/utils';

const imgType = {
  [PayType['wx'].key]: wxUrl, // 微信
  [PayType['alipay'].key]: alipayUrl, // 支付寶
  [PayType['bank'].key]: bankUrl, // 銀行卡
};

const IconsWrap = styled(Box)`
  & .icon {
    width: 32px !important;
  }
`;

export function Icon({ payType = '', style }) {
  return <img className='icon' src={imgType[payType] || ''} alt={payType} style={style} />
}

function Icons({ payType = '' }) {
  return (
    <IconsWrap
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      bgColor='#eef3f9'
      borderRadius='10px'
      px='3'
      pb='1'
      pt='2'
      style={{ cursor: 'pointer' }}>
      {imgType[payType] && (
        <Icon payType={payType} />
      )}
      <span style={{ fontSize: '12px', color: '#7f7f7f', paddingTop: '4px' }}>
        {PayType[payType]?.label || payType}
      </span>
    </IconsWrap>
  );
}

export default Icons;
