import React, { lazy, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sentryCaptureErr } from '@/plugin/sentry';
import { IS_CLIENT } from '@/const';
import { setRenew } from '@/store/client';
import { useMutationApi, apiType } from '@HOOK/useApi';
import useCrypto from '@HOOK/useCrypto';
import useRecMessage from '@HOOK/useRecMessage';
import { headerAvatarURL } from '@HOOK/useRoom';
import useAlertMessage from '@HOOK/useAlertMessage';
import {
  AccountTypeKeys,
  MsgStatusKeys,
  MsgContentTypeKeys,
  RoomTypeKey,
  lazyRetry,
} from '@/utils';
import AvatarFetch from '../Avatar/AvatarFetch';
import MsgControlItem from './MsgControlItem';
import MsgWrap from './MsgWrap';
import CsAvatarUrl from '@/assets/cs-avatar.jpg';

const MsgContextMenu = lazy(() =>
  lazyRetry(
    () => import(/*webpackChunkName: "msg-context-menu" */ './MsgContextMenu'),
    'MsgContextMenu',
  ),
);

/**
 * 對話狀態的訊息
 * @param {} param0
 * @returns
 */

const MGM_TYPE = [
  AccountTypeKeys['CustomerService'],
  AccountTypeKeys['Monitor'],
  AccountTypeKeys['Manager'],
  AccountTypeKeys['System'],
];

const Msg = ({
  children,
  roomID = 0,
  msg: originalMsg = {},
  usersMap = {},
  lastReadID = 0, // TODO
  updateMsg = () => {},
}) => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector(({ global }) => global);
  const { clientUserInfo } = useSelector(({ client }) => client);
  const { meInfo: auth } = useSelector(({ auth }) => auth);
  const meInfo = IS_CLIENT
    ? clientUserInfo
    : { ...auth, token: loginInfo.token };

  const { onErrorMsg } = useAlertMessage();
  const [msgWrapNode, setMsgWrapNode] = useState(null);

  const [isRetract, setIsRetract] = useState(
    originalMsg.status === MsgStatusKeys['Retract'],
  );
  const [msg, setMsg] = useState(originalMsg);

  const { id, from = null, timestamp = null, file, text, contentType } = msg;

  const isSystem = contentType === MsgContentTypeKeys['System'];

  const isSelf = isSystem ? false : from ? meInfo.id === from.userID : true;

  const avatarURLHandle = () => {
    if (!IS_CLIENT) return '';
    if (isSystem) {
      return headerAvatarURL(usersMap) || CsAvatarUrl;
    } else {
      if (
        !from ||
        [AccountTypeKeys['CommonUser'], AccountTypeKeys['Tourist']].includes(
          from.accountType,
        )
      ) {
        return meInfo.avatarURL
          ? require(`@/assets/${meInfo.avatarURL}`).default
          : '';
      }
      return usersMap[from.userID]?.avatarURL || CsAvatarUrl;
    }
  };

  const avatarURL = avatarURLHandle();

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [postMsg] = useMutationApi(apiType.POST_MSG, {
    context: { isClient: IS_CLIENT, isShowGeneralError: false },
  });

  const [updateMsgStatus, { loading: retractLoading }] = useMutationApi(
    apiType.UPDATE_MSG,
  );

  /**
   * 傳送訊息
   */
  // 加解密
  const { encryptStrHandle } = useCrypto(meInfo.token);
  const isTextEncrypt = str => {
    try {
      const encryptStr = encryptStrHandle(str);
      return { text: { text: encryptStr, isEncrypt: true } };
    } catch (error) {
      console.log('postMsgHandle encrypt error', error);
      return {};
    }
  };

  const postMsgHandle = async ({ contentType, text, file, lastMessageID }) => {
    setLoading(true);
    const isText = contentType === MsgContentTypeKeys['Text'];
    let encryptTextObj = isText ? isTextEncrypt(text.text) : {};
    const postDefaultObj = {
      roomID,
      type: RoomTypeKey['Consulting'],
      contentType,
      lastMessageID: lastMessageID ? String(lastMessageID) : '',
      ...(isText ? { text } : { file }),
    };
    let resMsg = {};
    const [err, encryptPostData] = await postMsg({
      in: { ...postDefaultObj, ...encryptTextObj },
    });

    if (err) {
      let errorMsg = '';
      let errorLevel = 'info';
      let sentryErrorData = {
        title: `PostMessage Error Info ${err.message}`,
        roomID,
        params: JSON.stringify({ ...postDefaultObj, ...encryptTextObj }),
        resError: JSON.stringify(err),
      };
      // 諮詢房已封存
      if (err.message === '400004') {
        errorMsg = '咨询房已封存';
      }
      // 加密回傳錯誤 重新發送post
      if (err.message === '400000' && isText) {
        sentryErrorData.tokenKey = meInfo.token;
        sentryErrorData.originMsg = text?.text;
        sentryErrorData.encryptMsg = encryptTextObj?.text?.text;
        const [resentErr, data] = await postMsg({
          in: { ...postDefaultObj },
        });
        if (resentErr) {
          sentryErrorData.title = `PostMessage resent Error Info ${resentErr.message}`;
          sentryErrorData.resentError = JSON.stringify(resentErr);
          sentryErrorData.resentParams = JSON.stringify(postDefaultObj);
          onErrorMsg(resentErr.message);
        } else {
          errorMsg = '';
          resMsg = { ...data.data.postMessage };
          sentryErrorData.successResData = JSON.stringify(data);
        }
      }

      if (errorMsg) {
        onErrorMsg(errorMsg);
        setIsError(true);
        sentryCaptureErr(
          sentryErrorData.title,
          { ...sentryErrorData },
          errorLevel,
        );
      }
    } else {
      resMsg = { ...encryptPostData.data.postMessage };
    }
    if (resMsg.renew) dispatch(setRenew(true));
    setMsg(resMsg);
    updateMsg({ id, msg: resMsg });
    setLoading(false);
  };

  /**
   * 撤回訊息
   */
  const retractMsgHandle = useCallback(
    async messageID => {
      const [err] = await updateMsgStatus({
        messageID,
        in: {
          contentType: MsgContentTypeKeys['Unknown'],
          status: MsgStatusKeys['Retract'],
        },
      });
      if (!err) setIsRetract(true);
    },
    [updateMsgStatus],
  );
  const showRetractHandle = from => {
    // Monitor 可以撤回其他客服對話
    return (
      meInfo.accountType === AccountTypeKeys['Monitor'] &&
      from.accountType === AccountTypeKeys['CustomerService']
    );
  };

  /**
   * 訊息顯示處理
   * - 息預設都是自己傳的的狀態(right)
   * - client 端不需顯示 username userID
   */
  const userInfo = from => {
    let showName = meInfo.username;
    let userID = meInfo.id;
    let userAccountType = meInfo.accountType;
    // 客端顯示
    if (IS_CLIENT) {
      return isSelf
        ? { showName: '', userID: '' }
        : { showName: usersMap[from.userID]?.aliasName || '客服', userID: '' };
    }
    if (from) {
      showName = usersMap[from.userID]?.username || '';
      userID = from.userID || 0;
      userAccountType = usersMap[from.userID]?.accountType || '';
    }
    if (userAccountType === AccountTypeKeys['Tourist']) showName = '遊客';
    return { showName, userID };
  };

  const color = from => {
    const isClient = { bg: 'primary', color: 'white' };
    const isOther = { bg: 'white', color: 'black' };
    if (!from && MGM_TYPE.includes(meInfo.accountType)) return isOther;
    return MGM_TYPE.includes(from?.accountType) ? isOther : isClient;
  };

  /**
   * 監聽
   */
  useRecMessage({
    msgID: id,
    msgStatusRetract: () => setIsRetract(true),
  });

  useEffect(() => {
    if (!msg.timestamp && roomID) {
      postMsgHandle(msg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (IS_CLIENT && isRetract)
    return (
      <MsgControlItem
        key={id}
        text={`${usersMap[from.userID]?.aliasName || '客服'} 已撤回讯息`}
        bgColor='secondary-light'
      />
    );

  return (
    <>
      <MsgWrap
        ref={n => setMsgWrapNode(n)}
        timestamp={timestamp}
        right={isSelf}
        {...color(from)}
        {...userInfo(from)}
        isShowMsgStatus={isSelf}
        isLoading={loading || retractLoading}
        isSend={!!timestamp}
        isRead={typeof id === 'string' && id > 0 && id <= lastReadID}
        isRetract={isRetract}
        isError={isError}
        avatar={<AvatarFetch avatarURL={avatarURL} />}>
        {React.cloneElement(children, {
          fileURL: file?.fileURL,
          text: file?.text || text?.text,
          isEncrypt: text?.isEncrypt,
        })}
      </MsgWrap>
      {from && typeof id === 'string' && !IS_CLIENT && (
        <MsgContextMenu
          node={msgWrapNode}
          isSelf={isSelf}
          showCopyImage={!!file?.fileURL}
          showRetract={isSelf || showRetractHandle(from)}
          fileURL={file?.fileURL || ''}
          onRetract={() => retractMsgHandle(id)}
        />
      )}
    </>
  );
};
export default Msg;
