import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import ClientDrawerWrap from '@/components/Drawer/ClientDrawerWrap';
import { IS_CLIENT } from '@/const';
import useOrder from '@/hook/useOrder';
import { CopyBtn } from '@/hook/useCopy';
import { Button, Box, Divider } from '@WRAP_COMP';
import {
  PayType,
  formatDateTime,
  ConsultingOrderTypeKeys,
  OrderStatusKeys,
} from '@UTILS';

function ClientOrderDetailDrawer(
  { roomID, payType, amount, orderNo, status, createdAt },
  ref,
) {
  const orderDetailDrawerWrapRef = useRef(null);

  const { consultingOrder, loading } = useOrder();

  const _consultingOrder = async () => {
    await consultingOrder({
      in: {
        roomID,
        orderNo,
        type: ConsultingOrderTypeKeys['Consulting'],
      },
    });
    orderDetailDrawerWrapRef.current.setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    setVisible: orderDetailDrawerWrapRef.current.setVisible,
  }));

  return (
    <ClientDrawerWrap
      ref={orderDetailDrawerWrapRef}
      title='订单详情'
      height='40%'>
      <Box p='3'>
        <Box display='flex' justifyContent='space-between'>
          <div>付款方式</div>
          <div>{PayType[payType]?.label}</div>
        </Box>
        <Divider style={{ margin: '12px 0' }} />
        <Box display='flex' justifyContent='space-between'>
          <div>存款金额</div>
          <div>{amount}</div>
        </Box>
        <Divider style={{ margin: '12px 0' }} />
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <div>订单号</div>
          <div>
            {orderNo}
            {IS_CLIENT ? (
              <Button
                onClick={_consultingOrder}
                loading={loading}
                size='small'
                style={{
                  borderColor: '#1591dc',
                  borderRadius: '4px',
                  color: '#1591dc',
                  padding: '0 5px',
                  height: '22px',
                  fontSize: '12px',
                  marginLeft: '4px',
                }}>
                咨询此单
              </Button>
            ) : (
              <CopyBtn value={orderNo} style={{ marginLeft: '4px' }} />
            )}
          </div>
        </Box>
        <Divider style={{ margin: '12px 0' }} />
        <Box display='flex' justifyContent='space-between'>
          <div>状态</div>
          <div>{OrderStatusKeys[status]?.label}</div>
        </Box>
        <Divider style={{ margin: '12px 0' }} />
        <Box display='flex' justifyContent='space-between'>
          <div>日期</div>
          <div>{formatDateTime(createdAt)}</div>
        </Box>
      </Box>
    </ClientDrawerWrap>
  );
}

export default forwardRef(ClientOrderDetailDrawer);
