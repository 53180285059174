/**
 * 菜單單
 */

import { gql } from '@apollo/client';

export const COMMENT_MENU = gql`
  fragment menuFields on Menu {
    name
    key
    # superKey
    # category
    # router
    # publicAPI
  }
`;

export const GET_MENU_LIST = gql`
  ${COMMENT_MENU}
  query listMenu {
    listMenu {
      ...menuFields
      next {
        ...menuFields
        next {
          ...menuFields
          next {
            ...menuFields
          }
        }
      }
    }
  }
`;
