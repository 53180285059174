import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Image, Upload } from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';
import { Button, Box } from '@/plugin/wrap-comp';
import ClientDrawerWrap from '@/components/Drawer/ClientDrawerWrap';
import useFile from '@/hook/useFile';

function UploadProofDrawer({ payDeposit = () => {} }, ref) {
  const clientDrawerWrapRef = useRef(null);
  const [uploadLdg, setUploadLdg] = useState(false);
  const [file, setFile] = useState(false);
  const [url, setUrl] = useState('');
  const { getFileUrl } = useFile();

  const _beforeUpload = file => {
    setFile(file);
    setUrl(getFileUrl(file));
    return false;
  };

  const onChangeFile = info => {
    setUploadLdg(() => info.file.status === 'uploading');
  };

  useImperativeHandle(ref, () => ({
    setVisible: visible => clientDrawerWrapRef.current.setVisible(visible),
  }));

  return (
    <>
      <Button
        onClick={() => clientDrawerWrapRef.current.setVisible(true)}
        style={{
          background: '#1c9cea',
          border: 'none',
          color: 'white',
          borderTopRightRadius: '20px',
          borderBottomRightRadius: '20px',
        }}>
        <CloudUploadOutlined />
        上传凭证
      </Button>
      <ClientDrawerWrap ref={clientDrawerWrapRef} title='上传凭证'>
        <Box p='3'>
          <p>
            请您上传这笔存款的完整信息回执单（图片）
            <br />
            <span style={{ color: '#e43737' }}>
              包含完整的卡号、金额、姓名、银行印章（不可遮盖或含*）
            </span>
          </p>
          <Box display='flex' justifyContent='space-around' mb='2'>
            <Box textAlign='center'>
              <div
                style={{
                  width: '100px',
                  height: '100px',
                  border: '1px solid #d7d7d7',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  overflow: 'hidden',
                }}>
                <Image
                  src={require('@/assets/proof-example.jpg').default}
                  alt='proofExample'
                  style={{ height: '100px' }}
                />
              </div>
              <Box pt='2' color='#1591dc'>
                回执单示例
              </Box>
            </Box>
            <Box textAlign='center'>
              <Upload
                name='file'
                beforeUpload={_beforeUpload}
                onChange={onChangeFile}
                showUploadList={false}
                accept='image/*'>
                <div
                  style={{
                    width: '100px',
                    height: '100px',
                    border: '1px solid #d7d7d7',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    overflow: 'hidden',
                  }}>
                  {uploadLdg && <LoadingOutlined />}
                  {uploadLdg ||
                    (url ? (
                      <img src={url} alt='url' style={{ height: '100px' }} />
                    ) : (
                      <PlusOutlined
                        style={{ fontSize: '20px', color: '#d7d7d7' }}
                      />
                    ))}
                </div>
              </Upload>
              <Box color='#1591dc'>点击上传凭证</Box>
            </Box>
          </Box>
          <p style={{ fontSize: '12px' }}>
            文件格式为PNG、JPEG、AVI，支持上传视频：MPEG、AVI、nAVI、ASF、MOV、3GP、WMV、DivX、XviD、RM、RMVB、FLV/F4V，文件大小不超过10M。
          </p>
          <Box textAlign='center'>
            <Button
              type='primary'
              size='large'
              onClick={() => payDeposit(file)}
              style={{ borderRadius: '8px', padding: '6.4px 36px' }}>
              提交凭证确认付款
            </Button>
          </Box>
        </Box>
      </ClientDrawerWrap>
    </>
  );
}

export default forwardRef(UploadProofDrawer);
