/**
 * 域名管理
 * - 訪問域名
 * - spinach平台管理
 * - 商戶管理
 *  */
import { gql } from '@apollo/client';
import { CS_ORIGIN_FIELD, SPINACH_FIELD } from './Fragment';

export const LIST_CS_ROOM_ORIGIN = gql`
  ${CS_ORIGIN_FIELD}
  query listConsultingRoomOrigin(
    $filter: ConsultingRoomOriginFilterInput
    $pagination: PaginationInput
  ) {
    listConsultingRoomOrigin(filter: $filter, pagination: $pagination) {
      consultingRoomOrigins {
        ...CSRoomOrigin
      }
      meta {
        total
      }
    }
  }
`;

export const CREATE_CS_ROOM_ORIGIN = gql`
  ${CS_ORIGIN_FIELD}
  mutation createConsultingRoomOrigin($in: ConsultingRoomOriginCreateInput!) {
    createConsultingRoomOrigin(in: $in) {
      ...CSRoomOrigin
    }
  }
`;

export const UPDATE_CS_ROOM_ORIGIN = gql`
  ${CS_ORIGIN_FIELD}
  mutation updateConsultingRoomOrigin(
    $filter: ConsultingRoomOriginFilterInput!
    $in: ConsultingRoomOriginUpdateInput!
  ) {
    updateConsultingRoomOrigin(filter: $filter, in: $in) {
      ...CSRoomOrigin
    }
  }
`;

export const DELETE_CS_ROOM_ORIGIN = gql`
  mutation deleteConsultingRoomOrigin(
    $filter: ConsultingRoomOriginFilterInput!
  ) {
    deleteConsultingRoomOrigin(filter: $filter)
  }
`;

/**
 * 平台管理
 *  */
// spinach 平台列表
export const LIST_SPINACH_PLATFORM = gql`
  ${SPINACH_FIELD}
  query listSpinachPlatform(
    $filter: SpinachPlatformFilterInput
    $pagination: PaginationInput
  ) {
    listSpinachPlatform(filter: $filter, pagination: $pagination) {
      spinachPlatforms {
        ...spinachPlatform
      }
      meta {
        total
      }
    }
  }
`;

// SpinachPlatform 平台 key
export const CREATE_SPINACH_PLATFORM = gql`
  ${SPINACH_FIELD}
  mutation createSpinachPlatform($in: SpinachPlatformCreateInput!) {
    createSpinachPlatform(in: $in) {
      ...spinachPlatform
    }
  }
`;

export const UPDATE_SPINACH_PLATFORM = gql`
  ${SPINACH_FIELD}
  mutation updateSpinachPlatform(
    $filter: SpinachPlatformFilterInput!
    $in: SpinachPlatformUpdateInput!
  ) {
    updateSpinachPlatform(filter: $filter, in: $in) {
      ...spinachPlatform
    }
  }
`;

export const DELETE_SPINACH_PLATFORM = gql`
  mutation deleteSpinachPlatform($filter: SpinachPlatformFilterInput!) {
    deleteSpinachPlatform(filter: $filter)
  }
`;

/**
 * 商戶管理
 */
export const MERCHANT_FIELDS = gql`
  fragment merchantFields on Merchant {
    id
    name
    merchantType
    key
    host
    secretKey
    remark
    createdAt
    createUserID
    updatedAt
    updateUserID
    endpoints {
      paymentChannel
      deposit
      cancelDeposit
      queryOrder
      paidDeposit
      expireDeposit
    }
  }
`;

export const LIST_MERCHANT = gql`
  ${MERCHANT_FIELDS}
  query listMerchant(
    $filter: MerchantFilterInput!
    $pagination: PaginationInput
  ) {
    listMerchant(filter: $filter, pagination: $pagination) {
      merchants {
        ...merchantFields
      }
      meta {
        total
      }
    }
  }
`;

export const CREATE_MERCHANT = gql`
  ${MERCHANT_FIELDS}
  mutation createMerchant($in: MerchantCreateInput!) {
    createMerchant(in: $in) {
      ...merchantFields
    }
  }
`;

export const UPDATE_MERCHANT = gql`
  ${MERCHANT_FIELDS}
  mutation updateMerchant(
    $filter: MerchantFilterInput!
    $in: MerchantUpdateInput!
  ) {
    updateMerchant(filter: $filter, in: $in) {
      ...merchantFields
    }
  }
`;

export const DELETE_MERCHANT = gql`
  mutation deleteMerchant($filter: MerchantFilterInput!) {
    deleteMerchant(filter: $filter)
  }
`;
