import React from 'react';
import { useSelector } from 'react-redux';
import { IS_CLIENT } from '@/const';
import useCrypto from '@HOOK/useCrypto';
import { Box } from '@WRAP_COMP';

function MsgText({ text, isEncrypt }) {
  const { loginInfo } = useSelector(({ global }) => global);
  const { clientUserInfo } = useSelector(({ client }) => client);
  const { meInfo: auth } = useSelector(({ auth }) => auth);
  const meInfo = IS_CLIENT
    ? clientUserInfo
    : { ...auth, token: loginInfo.token };

  const { decryptStrHandle } = useCrypto(meInfo.token);

  const _text = isEncrypt ? decryptStrHandle(text) : text;
  const __html = _text.replace(/\n/g, '<br/>');

  return (
    <Box
      display='inline-block'
      style={{ wordBreak: 'break-all' }}
      letterSpacing='0.08rem'
      dangerouslySetInnerHTML={{ __html }}
    />
  );
}
export default React.memo(MsgText);
