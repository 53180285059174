import React from 'react';
import { Box } from '@WRAP_COMP';
import { LoadingOutlined } from '@ant-design/icons';
import { Typography, Spin } from 'antd';
function QueueInfo() {
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      bgColor='rgb(200 215 255 / 95%)'
      borderRadius='5px'
      m='3'
      p='3'>
      <Typography.Title level={5}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: 16, marginRight: '8px' }}
              spin
            />
          }
        />
        等待客服加入...
      </Typography.Title>
      <Typography.Text>目前访问人数较多</Typography.Text>
      <Typography.Text style={{ textAlign: 'center' }}>
        请您先<Typography.Text type='danger'>留言</Typography.Text>或是
        <Typography.Text type='danger'>上传图片</Typography.Text>
        来说明您的问题，客服将马上进线与您联系
      </Typography.Text>
      <Typography.Text>感谢您的耐心等候</Typography.Text>
    </Box>
  );
}

export default QueueInfo;
