import React, { useEffect, useState } from 'react';
import { Button } from '@/plugin/wrap-comp';

const defaultCounter = 15;

function CountdownButton({ children, onClick = () => {}, ...p }) {
  const [counter, setCounter] = useState(defaultCounter);
  const [showCount, setShowCount] = useState(false);

  const _onClick = async () => {
    if (showCount) return;
    await onClick();
    setCounter(defaultCounter);
    setShowCount(true);
  };

  useEffect(() => {
    let timer = null;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setShowCount(false);
    }
    return () => clearTimeout(timer);
  }, [counter]);

  return (
    <Button {...p} onClick={_onClick} disabled={showCount}>
      {showCount ? counter : children}
    </Button>
  );
}

export default CountdownButton;
