import { gql } from '@apollo/client';
import {
  COMMENT_USER_ROOMS,
  CS_DETAIL_FIELD_LESS,
  CS_DETAIL_FIELD,
} from './Fragment';

/**
 * 諮詢室 相關
 * - 諮詢室列表
 * - 封存諮詢室列表
 * - 我的諮詢室列表
 * - 諮詢室
 * - 加入諮詢室
 * - 主動聯繫
 * - 諮詢室報表下載
 */

// 諮詢室列表
export const LIST_CS_ROOM = gql`
  ${CS_DETAIL_FIELD_LESS}
  ${COMMENT_USER_ROOMS}
  query listConsultingRoom(
    $filter: ConsultingRoomFilterInput
    $pagination: PaginationInput
  ) {
    listConsultingRoom(filter: $filter, pagination: $pagination) {
      consultingRooms {
        ...consultingDetailLessField
        userRooms {
          ...userRoomsFields
        }
      }
      meta {
        total
      }
    }
  }
`;

// 我的諮詢室
export const LIST_MY_CS_ROOM = gql`
  ${CS_DETAIL_FIELD_LESS}
  ${COMMENT_USER_ROOMS}
  query listUserRoom($userID: Uint64!, $pagination: PaginationInput) {
    # 我的聊天室
    listUserRoom(
      filter: {
        userRoom: { userID: $userID, roomType: Consulting }
        roomStatusIn: [Processing]
      }
      pagination: $pagination
    ) {
      userRooms {
        id
        unRead
        lastReadMessageID
        isPinned
        room {
          type
          lastMessage {
            id
            roomID
            contentType
            timestamp
            status
            from {
              userID
              accountType
              username
              aliasName
            }
            text {
              text
              isEncrypt
            }
          }
          consultingDetail {
            ...consultingDetailLessField
            isWaitFirstResponse
            isTimeoutResponse
          }
        }
        userRooms {
          ...userRoomsFields
        }
      }
      meta {
        total
        totalUnRead
      }
    }
  }
`;

// 主動聯繫
export const CREATED_CS_CONTACT = gql`
  mutation createConsultingRoomWithContact(
    $in: ConsultingRoomWithContactCreateInput!
  ) {
    createConsultingRoomWithContact(in: $in) {
      id
      consultingDetail {
        roomID
      }
    }
  }
`;

// 待諮詢 批量封存
export const BATCH_DEACTIVATED_CS_ROOM = gql`
  mutation deactivateConsultingRooms($roomIDs: [Uint64!]!) {
    deactivateConsultingRooms(roomIDs: $roomIDs) {
      id
      uid
      type
      createdAt
      updatedAt
    }
  }
`;

// 封存諮詢室列表
export const LIST_DEACTIVATED_CS_ROOM = gql`
  ${CS_DETAIL_FIELD}
  ${COMMENT_USER_ROOMS}
  query listConsultingRoom(
    $filter: ConsultingRoomFilterInput
    $pagination: PaginationInput
  ) {
    listConsultingRoom(filter: $filter, pagination: $pagination) {
      consultingRooms {
        ...consultingDetailField
        userRooms {
          ...userRoomsFields
        }
      }
      meta {
        total
      }
    }
  }
`;

/**
 * 加入諮詢室
 */
// 自己加入
export const JOIN_CS_ROOM = gql`
  mutation joinConsultingRoom($roomID: Uint64!) {
    joinConsultingRoom(roomID: $roomID) {
      roomID
    }
  }
`;
// 拉人進入聊天室 (可以選擇進房的身份)
export const PULL_IN_CS_ROOM = gql`
  mutation pullInConsultingRoom($in: PullInConsultingRoomInput!) {
    pullInConsultingRoom(in: $in) {
      roomID
    }
  }
`;

/**
 * 諮詢室報表下載
 */
export const DOWNLOAD_CS_ROOM_REPORT = gql`
  mutation downloadConsultingRoomReport($filter: ConsultingRoomFilterInput) {
    downloadConsultingRoomReport(filter: $filter)
  }
`;
