import { useCallback } from 'react';
import { Button, message } from 'antd';

export const copyText = async val => {
  await navigator.clipboard.writeText(val);
  message.success('复制成功');
};

export const CopyBtn = ({ value, style = {} }) => (
  <Button
    onClick={() => copyText(value)}
    size='small'
    style={{
      borderColor: '#1591dc',
      borderRadius: '4px',
      color: '#1591dc',
      padding: '0 5px',
      height: '22px',
      fontSize: '12px',
      ...style,
    }}>
    复制
  </Button>
);

function useCopy() {
  const createImage = useCallback(options => {
    options = options || {};
    const img = document.createElement('img');
    if (options.src) {
      img.src = options.src;
    }
    return img;
  }, []);

  const copyMediaToClipboard = useCallback(async pngBlob => {
    try {
      await navigator.clipboard.write([
        // eslint-disable-next-line no-undef
        new ClipboardItem({
          [pngBlob.type]: pngBlob,
        }),
      ]);
      console.log('Image copied');
    } catch (error) {
      console.error(error);
    }
  }, []);

  const convertToPng = useCallback(
    imgBlob => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const imageEl = createImage({ src: window.URL.createObjectURL(imgBlob) });
      imageEl.onload = e => {
        canvas.width = e.target.width;
        canvas.height = e.target.height;
        ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
        canvas.toBlob(copyMediaToClipboard, 'image/png', 1);
      };
    },
    [copyMediaToClipboard, createImage],
  );

  const copyImg = useCallback(
    async src => {
      const img = await fetch(src);
      const imgBlob = await img.blob();
      const extension = src.split('.').pop();
      const supportedToBeConverted = ['jpeg', 'jpg', 'gif'];
      if (supportedToBeConverted.indexOf(extension.toLowerCase())) {
        return convertToPng(imgBlob);
      } else if (extension.toLowerCase() === 'png') {
        return copyMediaToClipboard(imgBlob);
      }
      console.error('Format unsupported');
      return;
    },
    [convertToPng, copyMediaToClipboard],
  );

  return { copyImg, copyText };
}

export default useCopy;
