import { useCallback } from 'react';
import { IS_CLIENT } from '@/const';

import Msg from '@/components/Msg';
import MsgControlItem from '@/components/Msg/MsgControlItem';
import MsgText from '@/components/Msg/MsgText';
import MsgSystem from '@/components/Msg/MsgSystem';
import MsgImage from '@/components/Msg/MsgImage';
import MsgBubble from '@/components/Msg/MsgBubble';

import { ReceiveMsgContentType, MsgStatusKeys } from '@/utils';

function MsgList({
  roomMsg = [],
  roomID = 0,
  usersMap = {},
  lastReadID = [],
  postSystemMsg = () => {},
  setRoomMsgHandle = () => {},
  onOrderDetail = () => {},
}) {
  /**
   *
   */
  const msgBubbleRender = msg => {
    const { id, bubble, roomID } = msg;
    return (
      <Msg
        key={msg.id}
        roomID={roomID}
        msg={msg}
        lastReadID={lastReadID}
        usersMap={usersMap}>
        <MsgBubble
          msgID={id}
          roomID={roomID}
          pushMsg={setRoomMsgHandle}
          onOrderDetail={onOrderDetail}
          {...bubble}
        />
      </Msg>
    );
  };

  /**
   * 系統類訊息處理
   * @param {*} msg
   * @returns <Msg />
   */
  const msgSystemRender = useCallback(
    msg => {
      const { id, system, roomID } = msg;
      return (
        <Msg
          key={msg.id}
          roomID={roomID}
          msg={msg}
          lastReadID={lastReadID}
          usersMap={usersMap}>
          <MsgSystem
            msgID={id}
            roomID={roomID}
            type={system.type}
            content={system.content}
            postMsg={postSystemMsg}
          />
        </Msg>
      );
    },
    [lastReadID, postSystemMsg, usersMap],
  );

  /**
   * 加入、離開、型態處理
   * @param {*} msg
   * @returns <msgControlRender />
   */
  const msgControlRender = useCallback(
    ({ id, timestamp, join, leave }) => {
      let showUserText = '';
      let text = '';

      if (join) {
        showUserText = IS_CLIENT
          ? usersMap[join.userID]?.aliasName || '客服'
          : usersMap[join.userID]?.username || '';
        text = `${showUserText} 加入群组`;
      }
      if (leave) {
        showUserText = IS_CLIENT
          ? usersMap[leave.userID]?.aliasName || '客服'
          : usersMap[leave.userID]?.username || '';
        text = `${showUserText} 离开群组`;
      }
      return <MsgControlItem key={id || timestamp} text={text} />;
    },
    [usersMap],
  );

  /**
   * 檔案
   * @param {*} msg
   * @returns <Msg />
   */
  const msgFileRender = useCallback(
    msg => {
      return (
        <Msg
          key={msg.id}
          roomID={roomID}
          msg={msg}
          lastReadID={lastReadID}
          usersMap={usersMap}
          updateMsg={({ id, msg }) => setRoomMsgHandle('UPDATE', { id, msg })}>
          <MsgImage />
        </Msg>
      );
    },
    [usersMap, roomID, lastReadID, setRoomMsgHandle],
  );

  /**
   * 文字
   * @param {*} msg
   * @returns <Msg />
   */
  const msgTextRender = useCallback(
    msg => {
      return (
        <Msg
          key={msg.id}
          roomID={roomID}
          msg={msg}
          lastReadID={lastReadID}
          usersMap={usersMap}
          updateMsg={({ id, msg }) => setRoomMsgHandle('UPDATE', { id, msg })}>
          <MsgText />
        </Msg>
      );
    },
    [roomID, lastReadID, usersMap, setRoomMsgHandle],
  );

  const type = {
    [ReceiveMsgContentType['Text']]: msg => msgTextRender(msg),
    [ReceiveMsgContentType['File']]: msg => msgFileRender(msg),
    [ReceiveMsgContentType['Join']]: msg => msgControlRender(msg),
    [ReceiveMsgContentType['Leave']]: msg => msgControlRender(msg),
    [ReceiveMsgContentType['System']]: msg => msgSystemRender(msg),
    [ReceiveMsgContentType['Bubble']]: msg => msgBubbleRender(msg),

    // 前端自己加的 cusType
    CusMsgContentType: msg => msg.render && msg.render(msg.id),
  };

  return (
    <>
      {roomMsg.map(msg => {
        if (IS_CLIENT && msg.status === MsgStatusKeys['Retract']) return null; // 收回的訊息不顯示
        return type[msg.contentType]?.(msg) ?? null;
      })}
    </>
  );
}

export default MsgList;
