import { gql } from '@apollo/client';
/**
 * 罐頭回覆
 */
export const LIST_CANNED = gql`
  query listCanned(
    $resCatList: CannedResponseCategoryFilterInput
    $resList: CannedResponseFilterInput
  ) {
    listCannedResponseCategory(filter: $resCatList) {
      cannedResponseCategories {
        id
        name
        createdAt
      }
    }
    listCannedResponse(filter: $resList) {
      cannedResponses {
        id
        categoryID
        text
        createdAt
      }
    }
  }
`;
export const CREATE_CANNED_RES_CAT = gql`
  mutation createCannedResponseCategory(
    $in: CannedResponseCategoryCreateInput!
  ) {
    createCannedResponseCategory(in: $in) {
      id
      name
      createdAt
    }
  }
`;
export const UPDATE_CANNED_RES_CAT = gql`
  mutation updateCannedResponseCategory(
    $filter: CannedResponseCategoryFilterInput!
    $in: CannedResponseCategoryUpdateInput!
  ) {
    updateCannedResponseCategory(filter: $filter, in: $in) {
      id
      name
      createdAt
    }
  }
`;
export const DELETE_CANNED_RES_CAT = gql`
  mutation deleteCannedResponseCategory(
    $filter: CannedResponseCategoryFilterInput!
  ) {
    deleteCannedResponseCategory(filter: $filter)
  }
`;

// 新增罐頭類別內容
export const CREATE_CANNED_RES = gql`
  mutation createCannedResponse($in: CannedResponseCreateInput!) {
    createCannedResponse(in: $in) {
      id
      categoryID
      text
      createdAt
    }
  }
`;
export const UPDATE_CANNED_RES = gql`
  mutation updateCannedResponse(
    $filter: CannedResponseFilterInput!
    $in: CannedResponseUpdateInput!
  ) {
    updateCannedResponse(filter: $filter, in: $in) {
      id
      categoryID
      text
      createdAt
    }
  }
`;
export const DELETE_CANNED_RES = gql`
  mutation deleteCannedResponse($filter: CannedResponseFilterInput!) {
    deleteCannedResponse(filter: $filter)
  }
`;
