import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckOutlined,
  LoadingOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Typography, Space } from 'antd';
import { Box } from '@WRAP_COMP';
import { DoubleCheckIcon } from '@/components/Icon';
import { formatTime } from '@/utils';
import { IS_CLIENT } from '@/const';
const { theme } = require('@/plugin/theme');

/**
 * 對話訊息各種狀態顯示處理
 * - 使用者資訊 (username, userID, timestamp)
 * @param {String} param0.children 嵌入文字內容
 * @param {String} param0.showName 顯示名稱
 * @param {String} param0.userID 顯示userID
 * @param {String} param0.timestamp 時間
 * @param {Boolean}} param0.isShowMsgStatus 是否顯示訊息各種狀態
 * @param {Boolean} param0.isLoading 正在傳送
 * @param {Boolean} param0.isSend 傳送成功
 * @param {Boolean} param0.isRead 對方已讀
 * @param {Boolean} param0.isRetract 已收回訊息
 * @param {Boolean} param0.isError 訊息傳送出錯
 * @param {Boolean} param0.right 訊息對齊
 * @param {String} param0.bg 背景色
 */

const MsgWrap = React.forwardRef(
  (
    {
      children,
      showName = '',
      userID = 0,
      timestamp = 0,
      isShowMsgStatus = true,
      isLoading = false,
      isSend = false,
      isRead = false,
      isRetract = false,
      isError = false,
      right = true,
      bg,
      color = 'black',
      avatar = null,
    },
    ref,
  ) => {
    const justifyContent = right ? 'flex-end' : 'flex-start';
    const checkRender = ({ isRead, isSend, isError, isLoading }) => {
      if (isLoading) return <LoadingOutlined />;
      if (isError) return <CloseOutlined style={{ color: theme.danger }} />;
      if (isRead) return <DoubleCheckIcon />;
      if (isSend) return <CheckOutlined style={{ color: theme.primary }} />;
      return null;
    };

    return (
      <Box ref={ref} display='flex' justifyContent={justifyContent} mb='2'>
        <div>
          {/* 使用者資訊 */}
          {IS_CLIENT || (
            <Box display='flex' justifyContent={justifyContent}>
              <Space size={2}>
                <Typography.Text>{showName}</Typography.Text>
                {userID ? (
                  <Typography.Text type='secondary'>
                    (#{userID})
                  </Typography.Text>
                ) : null}
                {timestamp && (
                  <Typography.Text type='secondary'>
                    {formatTime(timestamp)}
                  </Typography.Text>
                )}
              </Space>
            </Box>
          )}

          {/* 訊息資訊 */}

          <Box
            display='flex'
            justifyContent='flex-end'
            flexDirection={right ? 'row' : 'row-reverse'}>
            {/* 狀態 */}
            {isShowMsgStatus && (
              <Box display='flex' flexDirection='column-reverse' mr='1'>
                <Box mt='auto' display='flex' justifyContent='flex-end'>
                  {checkRender({ isRead, isSend, isError, isLoading })}
                </Box>
              </Box>
            )}

            {/* 撤回狀態 */}
            {isRetract ? (
              <Typography.Text
                type='secondary'
                style={{ fontSize: '12px', marginLeft: '4px' }}>
                讯息已撤回
              </Typography.Text>
            ) : null}

            {/* 內容 */}
            <Box
              alignItems='flex-end'
              bgColor={bg}
              color={color}
              borderRadius={6}
              p='2'>
              {children}
            </Box>
            {IS_CLIENT && (
              <Box style={{ margin: right ? '0 0 0 6px' : '0 6px 0 0' }}>
                {avatar}
              </Box>
            )}
          </Box>
        </div>
      </Box>
    );
  },
);

MsgWrap.propTypes = {
  timestamp: PropTypes.number,
  direction: PropTypes.string,
  bg: PropTypes.string,
};

export default MsgWrap;
