import { gql } from '@apollo/client';

// 一對一 get
export const GET_USER_SETTING = gql`
  query getUserSetting($filter: UserSettingFilterInput!) {
    getUserSetting(filter: $filter) {
      id
      userID
      autoAddFriendEnabled
      allowStrangeMessageEnabled
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_USER_SETTING = gql`
  mutation updateUserSetting(
    $filter: UserSettingFilterInput!
    $in: UserSettingUpdateInput!
  ) {
    updateUserSetting(filter: $filter, in: $in) {
      id
      userID
      autoAddFriendEnabled
      allowStrangeMessageEnabled
      isStealth
      createdAt
      updatedAt
    }
  }
`;
