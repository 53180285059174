import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRoomDetail, setRoomUsersMap } from '@/store/room';
import { useQueryApi, useMutationApi, apiType } from '@/hook/useApi';
import useMe from '@/hook/useMe';
import useRecMessage from '@HOOK/useRecMessage';
import { AccountTypeKeys, OrderStatusKeys } from '@UTILS';
/**
 * 還在房間內的所有 users
 * @returns {Array}
 */
export const existInRoomUsers = userRoom => {
  return userRoom.filter(u => u.deletedAt === 0);
};

/**
 * 是否還在房間內的 user
 * @returns {Boolean}
 */
export const existInRoomUser = (userRoom, userID) => {
  return userRoom.some(u => u.userID === userID && u.deletedAt === 0);
};

/**
 * 是否有pin住房間
 * @returns {Boolean}
 */
const isPinnedRoom = (userRoom, userID) => {
  return userRoom.find(u => u.userID === userID)?.isPinned === 'Yes' || false;
};

/**
 * ownerUser 處理
 * ownerUser.findData @returns {Object}
 * ownerUser.findID @returns {Number} ownerUserID > 找到 私聊的 ownerUserID (私聊沒有 ownerUserID 自定義為對方就是 ownerUser)
 */
const ownerUser = {
  findData: (ownerUserID = 0, userRooms = []) => {
    const { user, ...props } = userRooms.find(ur => ur.userID === ownerUserID);
    return { ...props, ...user };
  },
  findID: (userRooms = [], meID) => {
    return userRooms.find(u => u.userID !== meID && u.isOperator === 'No')
      ?.userID;
  },
};

/**
 * list room 房間格式處理
 * roomFormate.Direct // 用於 私聊
 * roomFormate.Group // 用於 群組
 * roomFormate.Consulting // 用於 諮詢單
 * @returns {Object} { room: { ... }, ownerUserData: { ... }, extraInfo: { ... } }
 * extraInfo: {
 *  isExistInRoom: Boolean // 是否已在房間內
 *  isPinned: Boolean // 是否pin
 *  isNewActiveRoom: Boolean // 是否為新的諮詢室
 * }
 */
export const roomFormate = {
  direct: (room = {}, userRooms = [], meID, extraInfo = {}) => {
    let ownerUserID = ownerUser.findID(userRooms, meID);
    let ownerUserData = ownerUser.findData(ownerUserID, userRooms);
    return { room, ownerUserData, extraInfo };
  },

  group: ({
    room: { groupDetail = {}, ...props },
    ownerUserID = 0,
    userRooms = [],
    extraInfo = {},
    meID,
  }) => {
    const isExistInRoom = existInRoomUser(userRooms, meID);
    let ownerUserData = ownerUser.findData(ownerUserID, userRooms);
    return {
      room: { ...groupDetail, ...props },
      ownerUserData,
      extraInfo: { ...extraInfo, isExistInRoom },
    };
  },

  consulting: ({
    room: { consultingDetail = {}, ...props },
    ownerUserID = 0,
    userRooms = [],
    extraInfo = {},
    meID,
  }) => {
    const isExistInRoom = existInRoomUser(userRooms, meID);
    const isPinned = isPinnedRoom(userRooms, meID);
    let ownerUserData = ownerUser.findData(ownerUserID, userRooms);
    return {
      room: {
        ...consultingDetail,
        ...props,
        csType: consultingDetail.type || props.type,
      },
      ownerUserData,
      userRooms,
      extraInfo: { ...extraInfo, isExistInRoom, isPinned },
    };
  },
};

/**
 * 組出 roomUsersMap 依照 userRooms 資訊組出
 * @returns {Object} { 1: { username: 'xx'} ...}
 */
export const createdUsersMap = (userRooms = []) => {
  return userRooms.reduce((acc, { userID, user, ...props }) => {
    acc[userID] = { ...props, ...user };
    return acc;
  }, {});
};

/**
 * 第一個房間內客服頭像
 */
export const headerAvatarURL = usersMap => {
  for (const key in usersMap) {
    if (
      usersMap[key].accountType === AccountTypeKeys['CustomerService'] &&
      !usersMap[key].deletedAt
    ) {
      return usersMap[key].avatarURL;
    }
  }
};

/**
 * useRoom
 * @returns {Object}
 */
export default function useRoom({ getRoomContext = {} } = {}) {
  const dispatch = useDispatch();
  const { roomDetail } = useSelector(({ room }) => room);
  const { meInfo, isMonitorType } = useMe();

  /**
   * 房間明細處理
   */
  /**
   * 取得房間明細
   * - roomFormate.consulting
   * - createdUsersMap
   * @param.extraInfo {Object} 而外資訊
   * @param.onSuccess {Func} ({roomDetail, roomUsersMap}) => void  完成後行為
   * @return { roomDetail, roomUsersMap }
   *  */
  const { fetchData: fetchRoom, loading: getRoomLoading } = useQueryApi(
    apiType.GET_ROOM,
    { ...getRoomContext },
  );
  const fetchRoomInfo = useCallback(
    async ({ id, extraInfo = {}, onSuccess = () => {} }) => {
      const { data, error } = await fetchRoom({ filter: { room: { id } } });
      if (error) return { error };
      const roomDetail = roomFormate.consulting({
        room: { ...data.getRoom },
        ownerUserID: data.getRoom.consultingDetail.ownerUserID,
        userRooms: data.getRoom.userRooms,
        meID: meInfo.id,
        extraInfo,
      });
      const roomUsersMap = createdUsersMap(data.getRoom.userRooms);
      dispatch(setRoomUsersMap(roomUsersMap));
      dispatch(setRoomDetail(roomDetail));
      onSuccess({ roomDetail, roomUsersMap });
      return {};
    },
    [fetchRoom, dispatch, meInfo.id],
  );

  /**
   * 進入房間 (押上即時已讀用)
   * ws 訂閱成功收到 Hi 才能呼叫
   * 離開房間需要恢復預設 0
   */
  const [updateInRoom] = useMutationApi(apiType.UPDATE_IN_ROOM, {
    context: { isShowGeneralError: false },
  });
  // 後台用 記錄當前進入的房間
  const updateInRoomHandle = useCallback(
    (roomID = 0, onCompleted = () => {}) => {
      if (isMonitorType) return; // monitor 不能 updateInRoom
      updateInRoom({ roomID });
      onCompleted(roomID);
    },
    [isMonitorType, updateInRoom],
  );

  useRecMessage({
    roomID: roomDetail?.room?.id,
    orderStatusCancel: () => {
      dispatch(
        setRoomDetail({
          ...roomDetail,
          room: {
            ...roomDetail?.room,
            userOrder: {
              ...roomDetail?.room?.userOrder,
              status: OrderStatusKeys['Canceled'].key,
            },
          },
        }),
      );
    },
  });

  return {
    getRoomLoading,
    updateInRoom,
    fetchRoom,
    updateInRoomHandle,
    fetchRoomInfo,
  };
}
