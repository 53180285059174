import { gql } from '@apollo/client';

/**
 * 後台設定
 */
export const LIST_PLATFORM_SETTING = gql`
  query listPlatformSetting($filter: PlatformSettingFilterInput) {
    listPlatformSetting(filter: $filter) {
      PlatformSettings {
        key
        value
        displayName
        sequence
        isDisplayWeb
      }
    }
  }
`;
export const GET_PLATFORM_SETTING = gql`
  query getPlatformSetting($filter: PlatformSettingFilterInput) {
    getPlatformSetting(filter: $filter) {
      key
      value
      updaterUserID
      updatedAt
    }
  }
`;
export const UPDATE_PLATFORM_SETTING = gql`
  mutation updatePlatformSetting(
    $filter: PlatformSettingFilterInput!
    $in: PlatformSettingUpdateInput!
  ) {
    updatePlatformSetting(filter: $filter, in: $in) {
      key
      value
      updaterUserID
      updatedAt
    }
  }
`;

/**
 * 更多資訊管理
 */
export const LIST_PLATFORM_MORE_INFO = gql`
  query listPlatformMoreInformation($filter: MoreInformationFilterInput) {
    listPlatformMoreInformation(filter: $filter) {
      moreInformation {
        title
        type
        content
        isEnabled
      }
    }
  }
`;
export const UPDATE_PLATFORM_MORE_INFO = gql`
  mutation updatePlatformMoreInformation(
    $in: PlatformMoreInformationUpdateInput!
  ) {
    updatePlatformMoreInformation(in: $in) {
      moreInformation {
        title
        type
        content
        isEnabled
      }
    }
  }
`;

/**
 * 諮詢排隊問題列表
 */
export const LIST_CS_QUEUE_QUS = gql`
  query listConsultingRoomQuestion($filter: ConsultingRoomQuestionFilterInput) {
    listConsultingRoomQuestion(filter: $filter) {
      consultingRoomQuestions {
        id
        question
        maxProcessingCount
        createdAt
      }
    }
  }
`;

export const CREATED_CS_QUEUE_QUS = gql`
  mutation createConsultingRoomQuestion(
    $in: ConsultingRoomQuestionCreateInput!
  ) {
    createConsultingRoomQuestion(in: $in) {
      id
      question
      maxProcessingCount
      createdAt
    }
  }
`;

export const UPDATE_CS_QUEUE_QUS = gql`
  mutation updateConsultingRoomQuestion(
    $filter: ConsultingRoomQuestionFilterInput!
    $in: ConsultingRoomQuestionUpdateInput!
  ) {
    updateConsultingRoomQuestion(filter: $filter, in: $in) {
      id
      question
      maxProcessingCount
      createdAt
    }
  }
`;

export const DELETE_CS_QUEUE_QUS = gql`
  mutation deleteConsultingRoomQuestion(
    $filter: ConsultingRoomQuestionFilterInput!
  ) {
    deleteConsultingRoomQuestion(filter: $filter)
  }
`;
