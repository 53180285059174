import React from 'react';
import { Box } from '@WRAP_COMP';
import { Spin } from 'antd';
function FullRoomLoading() {
  return (
    <Box
      flex='1'
      bgColor='gray-1'
      display='flex'
      justifyContent='center'
      alignItems='center'>
      <Spin spinning />
    </Box>
  );
}

export default FullRoomLoading;
