/**
 * user
 * - 白名單管理
 * - 裝置管理
 * - 好友管理
 *  */
import { gql } from '@apollo/client';

const USER = gql`
  fragment userFields on User {
    id
    spinachUserID
    spinachPlatformKey
    accountType
    merchantID
    merchantType
    status
    username
    aliasName
    email
    area
    notes
    avatarURL
    authCode
    isDelete
    isOnline
    consultingCount
    createdAt
    updatedAt
    updateUserID
    setting {
      id
      isStealth
      allowLoginStartAt
      allowLoginEndAt
    }
  }
`;

const SPINACH_COMMON_USER = gql`
  fragment spinachCommonUserDetailFields on SpinachCommonUserDetail {
    status
    payLevel
    riskControlLevel
    platform
    rechargeTime
    registerAt
    lastLoginAt
    lastLoginIP
    nowLoginIP
  }
`;

export const LIST_USER = gql`
  ${USER}
  query listUser($filter: UserFilterInput!, $pagination: PaginationInput) {
    listUser(filter: $filter, pagination: $pagination) {
      users {
        ...userFields
      }
      meta {
        total
      }
    }
  }
`;

// 邀請 (會員 + 員工) 列表
export const INVITE_LIST_USER = gql`
  query listUser(
    $Manager: UserFilterInput!
    $CommonUser: UserFilterInput!
    $pagination: PaginationInput
  ) {
    manager: listUser(filter: $Manager, pagination: $pagination) {
      users {
        id
        accountType
        status
        username
        aliasName
        email
        avatarURL
        authCode
      }
    }
    commonUser: listUser(filter: $CommonUser, pagination: $pagination) {
      users {
        id
        accountType
        status
        username
        aliasName
        email
        avatarURL
        authCode
      }
    }
  }
`;

// 員工&會員 明細
export const GET_USER = gql`
  ${USER}
  ${SPINACH_COMMON_USER}
  query getUser($filter: UserFilterInput!) {
    getUser(filter: $filter) {
      ...userFields
      spinachCommonUserDetail {
        ...spinachCommonUserDetailFields
      }
    }
  }
`;

// 員工&會員 明細
export const GET_USER_SPINACH_INFO = gql`
  ${SPINACH_COMMON_USER}
  query getUser($filter: UserFilterInput!) {
    getUser(filter: $filter) {
      id
      spinachUserID
      spinachPlatformKey
      accountType
      spinachCommonUserDetail {
        ...spinachCommonUserDetailFields
      }
    }
  }
`;

export const CREATED_USER = gql`
  ${USER}
  mutation createUser($in: CreateUserReqInput!) {
    createUser(in: $in) {
      user {
        ...userFields
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($filter: UserFilterInput!, $in: UserUpdateInput!) {
    updateUser(filter: $filter, in: $in)
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($filter: UserFilterInput!) {
    deleteUser(filter: $filter)
  }
`;

/**
 * 白名單管理
 */
export const LIST_USER_WHITE = gql`
  query listUserWhitelist($filter: UserWhitelistFilterInput!) {
    listUserWhitelist(filter: $filter) {
      userWhitelists {
        id
        userID
        ipAddress
        createdAt
      }
      meta {
        total
      }
    }
  }
`;

export const CREATE_USER_WHITE = gql`
  mutation createUserWhitelist($in: UserWhitelistCreateInput!) {
    createUserWhitelist(in: $in)
  }
`;

export const UPDATE_USER_WHITE = gql`
  mutation updateUserWhitelist(
    $filter: UserWhitelistFilterInput!
    $in: UserWhitelistUpdateInput!
  ) {
    updateUserWhitelist(filter: $filter, in: $in)
  }
`;

export const DELETE_USER_WHITE = gql`
  mutation deleteUserWhitelist($filter: UserWhitelistFilterInput!) {
    deleteUserWhitelist(filter: $filter)
  }
`;

/**
 * 裝置管理
 */
export const LIST_USER_DEVICE = gql`
  query listUserDevice($filter: UserDeviceFilterInput!) {
    listUserDevice(filter: $filter) {
      userDevices {
        id
        userID
        deviceUID
        isBind
        createdAt
        updatedAt
      }
      meta {
        total
      }
    }
  }
`;

export const UPDATE_USER_DEVICE = gql`
  mutation updateUserDevice(
    $filter: UserDeviceFilterInput!
    $in: UserDeviceUpdateInput!
  ) {
    updateUserDevice(filter: $filter, in: $in)
  }
`;

export const DELETE_USER_DEVICE = gql`
  mutation deleteUserDevice($filter: UserDeviceFilterInput!) {
    deleteUserDevice(filter: $filter)
  }
`;

/**
 * 好友管理
 */
export const LIST_USER_FRIEND = gql`
  query listUserFriend($filter: UserFriendFilterInput!) {
    listUserFriend(filter: $filter) {
      userFriends {
        id
        userID
        friendUserID
        createdAt
        friend {
          id
          username
          avatarURL
        }
      }
    }
  }
`;

export const DELETE_USER_FRIEND = gql`
  mutation deleteUserFriend($filter: UserFriendFilterInput!) {
    deleteUserFriend(filter: $filter)
  }
`;

/*
 * 更新密碼
 */
export const UPDATE_USER_PASS = gql`
  mutation updateUserPassword($in: UserUpdatePasswordInput!) {
    updateUserPassword(in: $in)
  }
`;
