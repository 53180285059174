/**
 * format unix timestamp
 */
import moment from 'dayjs';
var utc = require('dayjs/plugin/utc');
moment.extend(utc);

//日期格式
export const dateFormat = { YMD: 'YYYY-MM-DD' };
export const timeFormat = { hm: 'HH:mm', hms: 'HH:mm:ss' };

// unix 轉 format
export const formatDate = s => moment.unix(s).format(dateFormat.YMD);
export const formatTime = s => moment.unix(s).format(timeFormat.hm);
export const formatDateTime = s =>
  moment.unix(s).format(`${dateFormat.YMD} ${timeFormat.hms}`);

export const getNow = () => moment().unix();
export const getToday = () => moment.unix().startOf('days').subtract(1, 'day');
export const formatMatchesTime = s => moment.unix(s).format('MM-DD HH:mm:ss');

export const formatDateStr = (dataStr, format = 'YYYY-MM-DD') => {
  return moment(dataStr).format(format);
};

// time to unix
export const toUnix = dateTime => moment(dateTime).unix();

// unix to moment
export const toMoment = unix => moment.unix(unix);

// format searchDate
export const formatSearchDate = (dateArr = []) => {
  if (dateArr.length) {
    return [
      moment(dateArr[0]).startOf('day').unix(),
      moment(dateArr[1]).endOf('day').unix(),
    ];
  }
};

// format searchDateTime
export const formatSearchDateTime = (dateArr = []) => {
  if (dateArr.length) {
    return [moment(dateArr[0]).unix(), moment(dateArr[1]).unix()];
  }
};

// ----------- ----------- ----------- -----------

//時間的按鈕 0當日、1最近一週、2最近一個月、3最近三個月
export const setDateRanges = (val, isAdd = false) => {
  const subtractRange = {
    0: {
      start: moment().startOf('day'),
      end: moment().endOf('day'),
    },
    1: {
      start: moment().subtract(6, 'day').startOf('day'),
      end: moment().endOf('day'),
    },
    2: {
      start: moment().subtract(1, 'month').startOf('day'),
      end: moment().endOf('day'),
    },
    3: {
      start: moment().subtract(3, 'month').startOf('day'),
      end: moment().endOf('day'),
    },
    4: {
      start: moment().subtract(2, 'day').startOf('day'),
      end: moment().endOf('day'),
    },
  };
  const addRange = {
    0: {
      start: moment().startOf('day'),
      end: moment().endOf('day'),
    },
    1: {
      start: moment().startOf('day'),
      end: moment().add(6, 'day').endOf('day'),
    },
    2: {
      start: moment().startOf('day'),
      end: moment().add(1, 'month').endOf('day'),
    },
    3: {
      start: moment().startOf('day'),
      end: moment().add(3, 'month').endOf('day'),
    },
  };
  if (isAdd) return addRange[val] || addRange[0];
  return subtractRange[val] || subtractRange[0];
};

export const datePickerRanges = (isAdd = false) => {
  return {
    今天: [setDateRanges(0, isAdd).start, setDateRanges(0, isAdd).end],
    最近一週: [setDateRanges(1, isAdd).start, setDateRanges(1, isAdd).end],
    最近一個月: [setDateRanges(2, isAdd).start, setDateRanges(2, isAdd).end],
    最近三個月: [setDateRanges(3, isAdd).start, setDateRanges(3, isAdd).end],
  };
};

// 秒數換 hh:mm:ss
// export const secondsToHms = s => s;
export const secondsToHms = s => moment.utc(s * 1000).format('HH:mm:ss');

// 比較
export const compareRangeDateStrSize = (d1Str, d2Str) => {
  const date1 = moment(d1Str);
  const date2 = moment(d2Str);

  if (date1.isBefore(date2)) {
    console.log('date1 在 date2 之前');
    return true;
  } else if (date1.isSame(date2)) {
    console.log('date1 和 date2 相同');
    return true;
  } else {
    console.log('date1 在 date2 之後');
    return false;
  }
};

export const labelRenderer = (type, data) => {
  switch (type) {
    case 'year':
      return data + '年';
    case 'month':
      return data + '月';
    case 'day':
      return data + '日';
    case 'hour':
      return data + '时';
    case 'minute':
      return data + '分';
    case 'second':
      return data + '秒';
    default:
      return data;
  }
};
