import { useCallback } from 'react';
import { Modal } from 'antd';
import useAlertMessage from '@/hook/useAlertMessage';
import { apiType, useMutationApi } from '@/hook/useApi';

function useOrder({ onCancelSuccess = () => {} } = {}) {
  const { onErrorMsg } = useAlertMessage();

  const [cancelDeposit] = useMutationApi(apiType.cancelDeposit, {
    context: { isShowGeneralError: false },
    onError: err => {
      if (['400000', '400002', '500002', '402001'].includes(err.message)) {
        onErrorMsg('你已超出取消次数限制，请联系客服');
      } else {
        onErrorMsg(err.message);
      }
    },
    onSuccess: (_, params) => {
      onCancelSuccess({ orderNo: params.in.orderNo });
    },
  });

  const cancelDepositConfirm = useCallback(
    ({ roomID, orderNo }) => {
      Modal.confirm({
        centered: true,
        title: '取消订单',
        content: '确定要取消这笔订单？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => cancelDeposit({ in: { roomID, orderNo } }),
      });
    },
    [cancelDeposit],
  );

  const [expireDeposit] = useMutationApi(apiType.expireDeposit, {
    context: { isShowGeneralError: false },
    onError: err => {
      // if (['400000', '400002', '500002', '402001'].includes(err.message)) {
      //   onErrorMsg('你已超出取消次数限制，请联系客服');
      // } else {
      //   onErrorMsg(err.message);
      // }
      onErrorMsg(err.message);
    },
  });

  // 諮詢/崔單
  const [consultingOrder, { loading }] = useMutationApi(
    apiType.consultingOrder,
    {
      context: { isShowGeneralError: false },
      onError: err => {
        // if (['400000', '400002', '500002', '402001'].includes(err.message)) {
        //   onErrorMsg('你已超出取消次数限制，请联系客服');
        // } else {
        //   onErrorMsg(err.message);
        // }
        onErrorMsg(err.message);
      },
    },
  );

  return {
    cancelDepositConfirm,
    cancelDeposit,
    expireDeposit,
    consultingOrder,
    loading,
  };
}

export default useOrder;
