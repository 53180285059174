import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { Drawer } from 'antd';
import { Popup } from 'antd-mobile';
import { CloseOutlined } from '@ant-design/icons';
import useRWD from '@/hook/useRWD';
import { Box } from '@/plugin/wrap-comp';

const PopupC = ({
  children,
  title = 'title',
  onMaskClick,
  height,
  ...props
}) => {
  return (
    <Popup onMaskClick={onMaskClick} {...props}>
      <Box
        height='45px'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        bgColor='#6993ff'
        color='white'
        fontSize='16px'
        padding='0 12px'>
        <div></div>
        <div>{title}</div>
        <div onClick={onMaskClick}>
          <CloseOutlined />
        </div>
      </Box>
      <div style={{ height, overflowY: 'scroll' }}>{children}</div>
    </Popup>
  );
};

function ClientDrawerWrap({ children, title = '', height = '100%' }, ref) {
  const [visible, setVisible] = useState(false);

  const { isMobileSize } = useRWD();

  const Comp = isMobileSize ? PopupC : Drawer;

  const compProps = isMobileSize
    ? {
        title,
        visible,
        onMaskClick: () => setVisible(false),
        bodyStyle: {
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          overflow: 'hidden',
        },
        height,
      }
    : {
        title,
        visible,
        onClose: () => setVisible(false),
        width: '400px',
        placement: 'right',
        height,
      };

  useImperativeHandle(ref, () => ({
    setVisible,
  }));

  return <Comp {...compProps}>{children}</Comp>;
}

export default forwardRef(ClientDrawerWrap);
