import DoubleCheck from './DoubleCheckIcon';
import SoundMute from './SoundMuteIcon';
const { theme } = require('@/plugin/theme');

const DoubleCheckIcon = props => (
  <DoubleCheck style={{ color: theme.primary }} {...props} />
);
const SoundMuteIcon = props => (
  <SoundMute style={{ color: theme.primary }} {...props} />
);

export { DoubleCheckIcon, SoundMuteIcon };
