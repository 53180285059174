import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetAuth } from '@/store/auth';
import { setIsLogin, setLogout, setGLoading } from '@/store/global';
import { useMutationApi, apiType } from '@/hook/useApi';
import { removeStorage } from '@/utils';

const useLogout = () => {
  const dispatch = useDispatch();

  /**
   * 登出
   */
  const [logoutFetch] = useMutationApi(apiType.POST_LOGOUT);
  const fetchLogout = useCallback(async () => {
    dispatch(setGLoading(true));
    const [err] = await logoutFetch();
    if (err) return [err, null];

    // 登出成功處理
    dispatch(setLogout());
    dispatch(resetAuth());
    dispatch(setGLoading(false));
    dispatch(setIsLogin(false));
    removeStorage('businessSystemMsgLog');
  }, [dispatch, logoutFetch]);

  return { fetchLogout };
};

// 專給 apollo 登出使用
export const useLogoutProcess = () => {
  const dispatch = useDispatch();
  const logoutProcess = useCallback(() => {
    dispatch(setLogout());
    dispatch(resetAuth());
    dispatch(setGLoading(false));
    dispatch(setIsLogin(false));
    removeStorage('businessSystemMsgLog');
  }, [dispatch]);
  return { logoutProcess };
};

export default useLogout;
