import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  CustomerServiceOutlined,
  RightOutlined,
  SoundFilled,
  FullscreenOutlined,
  DownOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Avatar, Typography, Space } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import { setCSRoomSettingBeepEnabled } from '@/store/client';
import useRWD from '@HOOK/useRWD';
import { fullScreenHandle } from '@HOOK/useBrowser';
import useIframe from '@HOOK/useIframe';
import { SoundMuteIcon } from '@COMP/Icon';
import AvatarFetch from '@COMP/Avatar/AvatarFetch';
import CsAvatarUrl from '@/assets/cs-avatar.jpg';
import { useDispatch } from 'react-redux';

const { theme } = require('@/plugin/theme');

function ClientHeader({
  title = '',
  roomID = null,
  avatarURL = '',
  showBeepBtn = false,
  beepEnabled = false,
  showBackBtn = false,
  backUrl = '',
  backToHandle = () => {},
  rightExtra = null,
  ...props
}) {
  const { isInIframe } = useIframe();
  const { isMobileDevice } = useRWD();
  const dispatch = useDispatch();

  useEffect(() => {
    roomID && console.log('房間編號:', roomID);
  }, [roomID]);

  return (
    <HeaderWrap {...props}>
      <Space>
        {isMobileDevice && showBackBtn && (
          <Button
            type='text'
            onClick={() => fullScreenHandle(backUrl)}
            icon={<LeftOutlined />}
          />
        )}

        {avatarURL ? (
          <AvatarFetch avatarURL={avatarURL} />
        ) : (
          <Avatar
            icon={<CustomerServiceOutlined />}
            src={CsAvatarUrl}
            style={{ background: theme.secondary }}
          />
        )}
        <div>
          <Typography.Text strong>{title}</Typography.Text>
        </div>
      </Space>
      {/* button group */}
      <Space>
        {rightExtra}
        {isMobileDevice && (
          <>
            {showBeepBtn && (
              <Button
                shape='circle'
                onClick={() => dispatch(setCSRoomSettingBeepEnabled())}
                icon={
                  beepEnabled ? (
                    <SoundFilled style={{ color: theme.secondary }} />
                  ) : (
                    <SoundMuteIcon style={{ color: theme.secondary }} />
                  )
                }
              />
            )}
          </>
        )}
        {isInIframe && (
          <>
            <Button
              shape='circle'
              icon={<FullscreenOutlined />}
              onClick={() => fullScreenHandle()}
            />
            <Button
              shape={'circle'}
              variant='outline-secondary'
              icon={isMobileDevice ? <DownOutlined /> : <RightOutlined />}
              onClick={backToHandle}
            />
          </>
        )}
      </Space>
    </HeaderWrap>
  );
}

const HeaderWrap = styled.div`
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  position: relative;
  background: white;
  min-height: ${({ theme }) => theme.comp.headerHeight};
  border-bottom: 1px solid ${({ theme }) => theme['gray-2']};
`;

export default React.memo(ClientHeader);
