import { useEffect, useRef, useState, useCallback } from 'react';
import { Box } from '@WRAP_COMP';

const stopEventDefault = e => {
  e.preventDefault();
  e.stopPropagation();
};
const checkIsDragIn = e =>
  e.dataTransfer?.items && e.dataTransfer.items.length > 0;

function useDragDropFiles({
  onSetDropFiles = () => {},
  onSetDragInFiles = () => {},
  onSetDragOutFiles = () => {},
}) {
  // const dropElRef = useRef(null);
  const [dropElRef, setDropElRef] = useState(null);
  const dragCounter = useRef(0);

  // isDragging 用於判斷是否有檔案在 drag 檔案的元件之中
  const [isDragging, setDrag] = useState(false);

  // 拖進
  const handleDragIn = useCallback(
    e => {
      stopEventDefault(e);
      dragCounter.current++;
      const isDragIn = checkIsDragIn(e);
      if (isDragIn) {
        setDrag(true);
        onSetDragInFiles();
      }
    },
    [onSetDragInFiles],
  );

  // 拖出
  const handleDragOut = useCallback(
    e => {
      stopEventDefault(e);
      dragCounter.current--;
      if (dragCounter.current === 0) {
        setDrag(false);
        onSetDragOutFiles();
      }
    },
    [onSetDragOutFiles],
  );

  // 拖曳檔案
  const handleDrag = useCallback(e => {
    stopEventDefault(e);
  }, []);

  // 放下檔案
  const handleDrop = useCallback(
    e => {
      stopEventDefault(e);
      setDrag(false);
      const isDragIn = checkIsDragIn(e);
      if (isDragIn && e.dataTransfer) {
        onSetDropFiles(e.dataTransfer.files);
        e.dataTransfer.clearData();
        dragCounter.current = 0;
      }
    },
    [onSetDropFiles],
  );

  useEffect(() => {
    if (dropElRef) {
      const el = dropElRef;
      el.addEventListener('dragenter', handleDragIn);
      el.addEventListener('dragenter', handleDragIn);
      el.addEventListener('dragleave', handleDragOut);
      el.addEventListener('dragover', handleDrag);
      el.addEventListener('drop', handleDrop);
      // clear events, 清除事件監聽
      return () => {
        el.removeEventListener('dragenter', handleDragIn);
        el.removeEventListener('dragleave', handleDragOut);
        el.removeEventListener('dragover', handleDrag);
        el.removeEventListener('drop', handleDrop);
      };
    }
  }, [handleDrag, handleDragIn, handleDragOut, handleDrop, dropElRef]);

  return { setDropElRef, dropElRef, isDragging };
}

export const DragInDrop = () => {
  return (
    <Box p='3' width='100%' height='100%'>
      <Box
        width='100%'
        height='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        border='1px dashed'
        borderCr='gray-3'>
        拖曳新增图片
      </Box>
    </Box>
  );
};

export default useDragDropFiles;
