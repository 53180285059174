import React from 'react';
import { Divider } from 'antd';
import { Box, Button } from '@WRAP_COMP';
import { MsgContentTypeKeys } from '@/utils';

const layoutMap = {
  BASELINE: { display: 'block' },
  HORIZONTAL: { display: 'flex' }, // 水平
  VERTICAL: { display: 'flex', flexDirection: 'column' }, // 垂直
};

function MsgBubble({ header, hero, body, footer, pushMsg, onOrderDetail }) {
  const compMap = {
    box: ({
      children,
      layout = 'BASELINE',
      justifyContent = '',
      alignItems = '',
      padding = '',
      margin = '',
      separator = false,
    }) => (
      <>
        {separator && <Divider style={{ margin: 0 }} />}
        <Box
          {...layoutMap[layout]}
          justifyContent={justifyContent}
          alignItems={alignItems}
          style={{ padding, margin }}>
          {children}
        </Box>
      </>
    ),

    text: ({ text, color, size, weight }) => {
      const __html = text.replace(/\n/g, '<br/>');
      return (
        <div
          style={{ display: 'flex', color, fontSize: size, fontWeight: weight }}
          dangerouslySetInnerHTML={{ __html }}
        />
      );
    },

    button: ({ action, width, color, icon, iconAlign }) => {
      const { type, text, data } = action;

      const postBackMap = {
        onOrderDetail,
      };

      const actionTypeMap = {
        MESSAGE: () =>
          pushMsg('PUSH', {
            msgList: [
              {
                id: Date.now(),
                contentType: MsgContentTypeKeys['Text'],
                text: { text, isEncrypt: false },
              },
            ],
          }),
        POSTBACK: () => postBackMap[data]?.(),
        URI: () => {},
      };

      const IconComp = icon ? compMap['icon'] : null;
      const renderIconComp = () => (icon ? <IconComp {...icon} /> : null);

      return (
        <Button
          type='text'
          style={{ width, color }}
          onClick={actionTypeMap[type]}>
          <Box
            display='flex'
            justifyContent={icon ? 'space-between' : 'center'}
            alignItems='center'>
            {iconAlign === 'left' && renderIconComp()}
            {text}
            {iconAlign === 'right' && renderIconComp()}
          </Box>
        </Button>
      );
    },

    separator: ({ direction }) => (
      <Divider
        type={direction}
        style={{
          margin: 0,
          ...(direction === 'vertical' ? { height: '32px' } : {}),
        }}
      />
    ),

    icon: ({ url, style }) => {
      return (
        <img
          src={require(`@/assets/bubble/${url}`).default}
          alt={url}
          style={style && JSON.parse(style)}
        />
      );
    },
  };

  const Contents = ({ contents }) => {
    if (!contents) return null;
    return (
      <>
        {contents.map(({ type, ...item }, idx) => {
          const ContentComp = compMap[type] || null;
          if (!ContentComp) return null;
          return (
            <ContentComp key={idx} {...item}>
              <Contents contents={item.contents} />
            </ContentComp>
          );
        })}
      </>
    );
  };

  const Bubble = ({ type, contents, ...props }) => {
    const BubbleComp = compMap[type] || null;
    if (!BubbleComp) return null;
    return (
      <BubbleComp {...props}>
        <Contents contents={contents} />
      </BubbleComp>
    );
  };

  return (
    <div style={{ margin: '-8px' }}>
      {header && <Bubble {...header} />}
      {hero && <Bubble {...hero} />}
      {body && <Bubble {...body} />}
      {footer && <Bubble {...footer} />}
    </div>
  );
}

export default MsgBubble;
