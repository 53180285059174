import { useRef, useEffect } from 'react';

function useEventListener(eventName, handler) {
  const savedHandler = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = window && window.addEventListener;
      if (!isSupported) return;
      // Create event listener that calls handler function stored in ref
      const eventListener = event => savedHandler.current(event);
      // Add event listener
      window.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup
      return () => {
        window.removeEventListener(eventName, eventListener);
      };
    },
    [eventName], // Re-run if eventName or element changes
  );
}

export default useEventListener;
