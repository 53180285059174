import { useCallback } from 'react';
import useEventListener from '@/hook/useEventListener';

const msgType = { unread: 'unread', back: 'back' };
const isInIframe = window.frames.length !== window.parent.frames.length;

const useIframe = parentURL => {
  const postMsgToParent = useCallback((postData = {}, parent = '*') => {
    window.parent.postMessage(postData, parent);
  }, []);

  const postUnRead = useCallback(
    (num = 1) => {
      postMsgToParent({ type: msgType['unread'], num });
    },
    [postMsgToParent],
  );

  const postToBack = useCallback(() => {
    postMsgToParent({ type: msgType['back'], message: 'go back' });
  }, [postMsgToParent]);

  // 接收 父層訊息
  const recParentListener = useCallback(
    e => {
      // eslint-disable-next-line
      if (e.origin == parentURL) {
        if (e.data.type === 'open') postUnRead(2);
      }
    },
    [parentURL, postUnRead],
  );

  useEventListener('message', recParentListener);
  return { isInIframe, postUnRead, postToBack };
};

export default useIframe;
