import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

const TextAreaWrapStyle = styled.div`
  position: relative;
  flex: 1;
`;
const HintText = styled.span`
  position: absolute;
  bottom: 4px;
  right: 12px;
  margin-bottom: 0;
  color: ${({ theme }) => theme['gray-2']};
`;

function InputTextArea({
  value = '',
  setValue = () => {},
  onPressEnter = () => {},
  onChange = () => {},
  pasteHandle = () => {},
  onFocus = () => {},
  rows = 4,
  disabled = false,
}) {
  // 換行處理
  const onkeydown = e => {
    if (e.keyCode === 13) {
      e.shiftKey ? setValue(pre => pre + '\n') : onPressEnter();
      e.preventDefault();
    }
  };
  return (
    <TextAreaWrapStyle>
      <Input.TextArea
        value={value}
        onKeyDown={onkeydown}
        onChange={onChange}
        onPaste={pasteHandle}
        rows={rows}
        placeholder='输入讯息'
        onFocus={onFocus}
        disabled={disabled}
        style={{ letterSpacing: '0.08rem' }}
      />
      <HintText>Shift + Enter 換行</HintText>
    </TextAreaWrapStyle>
  );
}

export default InputTextArea;
