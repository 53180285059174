import { gql } from '@apollo/client';

// 諮詢室列表
export const LIST_MONITOR_CS_ROOM = gql`
  query listConsultingRoom(
    $filter: ConsultingRoomFilterInput
    $pagination: PaginationInput
  ) {
    listConsultingRoom(filter: $filter, pagination: $pagination) {
      consultingRooms {
        roomID
        merchantID
        ipAddress
        country
        administrativeArea
        accessDomain
        ownerUserID
        status
        createdAt
        userRooms {
          id
          userID
          deletedAt # 用來判斷是否已離開房間 重新加入會 = 0
          lastReadMessageID
          isPinned
          user {
            id
            username
            aliasName
            avatarURL
            accountType
            status
            isOnline
          }
        }
      }
    }
  }
`;
