import { gql } from '@apollo/client';

/**
 * ROOM 相關
 */
// CS_ROOM ----------------------------------
export const CS_DETAIL_FIELD = gql`
  fragment consultingDetailField on RoomConsultingDetail {
    roomID
    deactivatedAt
    deactivatedUserID
    ipAddress
    country
    administrativeArea
    sourceDomain
    accessDomain
    questionID
    deviceOS
    ownerUserID
    status
    isProactive
    createdAt
    question
    type
    userOrder {
      orderNo
      amount
      payType
      currency
      status
      createdAt
    }
  }
`;

export const CS_DETAIL_FIELD_LESS = gql`
  fragment consultingDetailLessField on RoomConsultingDetail {
    roomID
    ipAddress
    country
    administrativeArea
    sourceDomain
    accessDomain
    ownerUserID
    status
    isProactive
    createdAt
    type
  }
`;

export const CS_SETTING_FIELD = gql`
  fragment CSSetting on ConsultingRoomClientSetting {
    id
    consultingRoomOriginID
    backgroundColor
    backgroundImageURL
    header {
      title
      backgroundImageURL
      backgroundColor
      logoURL
      avatarURL
      isEnabled
      contentLogoEnabled
      contentLogoURL
      contentLogoPosition
    }
    content {
      backgroundColor
      backgroundImageURL
      boxBackgroundColor
      boxBackgroundImageURL
    }
    banner {
      imageURL
      linkUrl
      isEnabled
    }
    beepURL
    beepEnabled
    queueEnabled
    enterDirectlyEnabled
    redirectVerifyEnabled
    createdAt
  }
`;
export const CS_GREETING_FIELD = gql`
  fragment CSGreeting on ConsultingGreeting {
    id
    consultingRoomOriginID
    title
    content
    source
    afterSendSec
    isEnabled
  }
`;
export const CS_FORM_FIELD = gql`
  fragment CSForm on ConsultingRoomForm {
    id
    consultingRoomOriginID
    title
    isEnabled
    isVerificationCode
    formData {
      id
      type
      label
      content {
        value
        items {
          label
          value
        }
      }
      rule {
        require
        verifyType
      }
    }
  }
`;
export const CS_ORIGIN_FIELD = gql`
  fragment CSRoomOrigin on ConsultingRoomOrigin {
    id
    origin
    spinachPlatformKey
    merchantID
    customerServices {
      id
      username
    }
    remark
    createdAt
  }
`;

// USER_ROOMS
export const COMMENT_USER_ROOMS = gql`
  fragment userRoomsFields on UserRoom {
    id
    userID
    isOperator
    deletedAt # 用來判斷是否已離開房間 重新加入會 = 0
    lastReadMessageID
    isPinned
    user {
      ...userRoomFields
    }
  }
  fragment userRoomFields on User {
    id
    username
    aliasName
    avatarURL
    accountType
    status
    email
    area
    notes
    isOnline
  }
`;

/**
 * MSG 相關
 */
// SYSTEM
export const MSG_SYSTEM = gql`
  fragment MsgSystemField on MsgSystem {
    type
    content {
      ... on MsgSystemOrder {
        payType
        payMethod
        orderNo
        orderID
        orderStatus
        orderAmount
        remark
        createdAt
        updatedAt
        expiredAt
        bank {
          bankName
          branchName
          bankCode
          accountName
          cardNo
        }
        qrcode {
          url
          qUrl
          account
          accountName
        }
        redirect {
          url
        }
      }
      ... on MsgSystemPayList {
        amount
        payList {
          payeeID
          alias
          payType
        }
      }
      ... on MsgText {
        text
        isEncrypt
      }
    }
  }
`;

// BUBBLE
export const MSG_BUBBLE = gql`
  fragment MsgBubbleField on MsgBubble {
    type
    header {
      ...MsgBoxDetail
      ...MsgBoxRecursive
    }
    hero {
      ...MsgImageDetail
    }
    body {
      ...MsgBoxDetail
      ...MsgBoxRecursive
    }
    footer {
      ...MsgBoxDetail
      ...MsgBoxRecursive
    }
  }
  fragment MsgTextDeteil on MsgText {
    align
    color
    copied
    flex
    isEncrypt
    label
    margin
    size
    text
    type
    weight
  }

  fragment MsgActionDetail on MsgAction {
    type
    data
    text
    uri
  }

  fragment MsgImageDetail on MsgImage {
    action {
      ...MsgActionDetail
    }
    size
    type
  }

  fragment MsgButtonDetail on MsgButton {
    action {
      ...MsgActionDetail
    }
    color
    height
    width
    icon {
      ...MsgIconDetail
    }
    iconAlign
    style
    type
  }

  fragment MsgIconDetail on MsgIcon {
    type
    url
    size
    style
  }

  fragment MsgSeparatorDetail on MsgSeparator {
    type
    size
    color
    direction
  }

  fragment MsgBoxDetail on MsgBox {
    layout
    margin
    separator
    separatorColor
    spacing
    type
    padding
    justifyContent
    alignItems
  }

  fragment MsgBoxRecursive on MsgBox {
    contents {
      ... on MsgText {
        ...MsgTextDeteil
      }
      ... on MsgImage {
        ...MsgImageDetail
      }
      ... on MsgButton {
        ...MsgButtonDetail
      }
      ... on MsgIcon {
        ...MsgIconDetail
      }
      ... on MsgSeparator {
        ...MsgSeparatorDetail
      }
      ... on MsgBox {
        ...MsgBoxDetail
        contents {
          ... on MsgText {
            ...MsgTextDeteil
          }
          ... on MsgImage {
            ...MsgImageDetail
          }
          ... on MsgButton {
            ...MsgButtonDetail
          }
          ... on MsgIcon {
            ...MsgIconDetail
          }
          ... on MsgSeparator {
            ...MsgSeparatorDetail
          }
          ... on MsgBox {
            ...MsgBoxDetail
            contents {
              ... on MsgText {
                ...MsgTextDeteil
              }
              ... on MsgImage {
                ...MsgImageDetail
              }
              ... on MsgButton {
                ...MsgButtonDetail
              }
              ... on MsgIcon {
                ...MsgIconDetail
              }
              ... on MsgSeparator {
                ...MsgSeparatorDetail
              }
              ... on MsgBox {
                ...MsgBoxDetail
                contents {
                  ... on MsgText {
                    ...MsgTextDeteil
                  }
                  ... on MsgImage {
                    ...MsgImageDetail
                  }
                  ... on MsgButton {
                    ...MsgButtonDetail
                  }
                  ... on MsgIcon {
                    ...MsgIconDetail
                  }
                  ... on MsgSeparator {
                    ...MsgSeparatorDetail
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * 其他
 */
export const SPINACH_FIELD = gql`
  fragment spinachPlatform on SpinachPlatform {
    id
    key
    host
    secretKey
    remark
    createdAt
  }
`;
