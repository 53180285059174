/**
 * 推送相關
 */
import { gql } from '@apollo/client';

export const LIST_PUSH = gql`
  query listPushNotification(
    $filter: PushNotificationFilterInput!
    $pagination: PaginationInput
  ) {
    listPushNotification(filter: $filter, pagination: $pagination) {
      pushNotifications {
        id
        createdAt
        title
        content
        target
        isScheduled
        scheduledTime
        status
      }
      meta {
        total
      }
    }
  }
`;

export const CREATE_PUSH = gql`
  mutation createPushNotification($in: PushNotificationCreateInput!) {
    createPushNotification(in: $in)
  }
`;
export const CANCEL_PUSH = gql`
  mutation cancelPushNotification($filter: PushNotificationFilterInput!) {
    cancelPushNotification(filter: $filter)
  }
`;
