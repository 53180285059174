import React from 'react';
import { useRoutes } from 'react-router-dom';
import { client } from '@/router/client';
const clientRoutes = client();

const ClientApp = () => {
  const routing = useRoutes(clientRoutes);
  return <>{routing}</>;
};

export default ClientApp;
