import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useRef,
  useImperativeHandle,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ResizeObserver from 'resize-observer-polyfill';
import { Button, Box } from '@WRAP_COMP';
import ScrollDownBtn from './ScrollDownBtn';
import { IS_CLIENT, IS_MOBILE } from '@/const';
import useBrowser, { BrowserType } from '@/hook/useBrowser';

const EndMsg = ({ showEndMsg = true, loadPreRoomMsg = () => {} }) => {
  if (IS_CLIENT || !showEndMsg) return null;
  return (
    <Button variant='outline-secondary' mb='2' onClick={loadPreRoomMsg}>
      载入上个房间讯息
    </Button>
  );
};

const controlFooterHeight = 40;

const ChatContent = (
  {
    roomID = 0,
    roomMsgLength = 0,
    loadMoreMsg = () => {},
    loadPreRoomMsg = () => {},
    children = null,
    controlHeader = null,
    controlFooter = null,
    bgColor = '',
    bgImg = '',
    showEndMsg = true,
  },
  ref,
) => {
  const { browser } = useBrowser();
  const isSogou = browser === BrowserType['Sogou'];

  const [elHeight, setElHeight] = useState(0);
  const [roomContentEl, setRoomContentEl] = useState(null);

  const [wrapBoxRef, setWrapBoxRef] = useState(null);
  const [infiniteScrollNode, setInfiniteScrollNode] = useState(null);
  const [scrollToBtnShow, setScrollToBtnShow] = useState(false);

  const [hasNewMsg, setHasNewMsg] = useState(false);
  const [hasMoreMsg, setHasMoreMsg] = useState(true);

  const sogouBrowserScrollTop = () => {
    const target = infiniteScrollNode.getScrollableTarget();
    const outerDivHeight = target.clientHeight;
    const innerDivHeight = target.getElementsByClassName(
      'infinite-scroll-component__outerdiv',
    )[0].clientHeight;
    const currScrollTop = innerDivHeight - outerDivHeight;
    return currScrollTop;
  };

  /**
   * 聊天內容動態偵測高度
   */
  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) return;
        setRoomContentEl(entries[0].contentRect);
      });
    }),
  );
  // const cleanObserver = useCallback(() => {
  //   resizeObserver.current && resizeObserver.current.disconnect();
  // }, []);

  const observeTimeID = useRef(null);
  const getRoomContentEl = node => {
    if (!node || !resizeObserver.current) return;
    observeTimeID.current = setTimeout(() => {
      resizeObserver.current.observe(node);
    }, 0);
  };

  useEffect(() => {
    if (roomContentEl && roomContentEl.height) {
      let hight =
        roomContentEl.height - (controlFooter ? controlFooterHeight : 0);
      setElHeight(hight);
    }
    // return () => cleanObserver();
  }, [roomContentEl, controlFooter]);

  /**
   * 取得訊息
   */
  const loadMoreMsgHandle = async () => {
    const res = await loadMoreMsg();
    res && setHasMoreMsg(res.moreMsg);
  };
  const loadPreRoomMsgHandel = async () => {
    const res = await loadPreRoomMsg();
    res && setHasMoreMsg(res.moreMsg);
  };

  /**
   * 滾動相關操作
   */
  const getWrapBoxRef = useCallback(ref => ref && setWrapBoxRef(ref), []);

  const onScroll = e => {
    try {
      const scroll = e.target.scrollTop;

      // 搜狗是相反的
      if (isSogou) {
        const currScrollTop = sogouBrowserScrollTop();

        setScrollToBtnShow(scroll !== currScrollTop);
        scroll === currScrollTop && setHasNewMsg(false);
        scroll === 0 && loadMoreMsgHandle();
        return;
      }

      setScrollToBtnShow(scroll < 0);
      scroll >= 0 && setHasNewMsg(false);
    } catch (error) {
      console.log('onScroll error', error);
    }
  };

  const scrollNodeTo = (hight = 0) => {
    try {
      if (isSogou) {
        const currScrollTop = sogouBrowserScrollTop();
        infiniteScrollNode.getScrollableTarget().scrollTo(0, currScrollTop);
      } else {
        infiniteScrollNode.getScrollableTarget().scrollTo(0, hight);
      }
      setHasNewMsg(false);
      setScrollToBtnShow(false);
    } catch (error) {
      console.log('scrollNodeTo error', error);
    }
  };

  const getInfiniteScrollEl = useCallback(node => {
    node && setInfiniteScrollNode(node);
  }, []);

  /**
   * 新訊息提示
   */
  const setHasNewMsgHandle = () => {
    if (!infiniteScrollNode) return;
    if (isSogou) {
      const currScrollTop = sogouBrowserScrollTop();
      if (infiniteScrollNode.lastScrollTop !== currScrollTop) {
        setHasNewMsg(true);
        if (IS_MOBILE) setScrollToBtnShow(true);
      }
    } else {
      if (infiniteScrollNode.lastScrollTop < 0) {
        setHasNewMsg(true);
        if (IS_MOBILE) setScrollToBtnShow(true);
      }
    }
  };

  /**
   * 初始顯示
   * hasMoreMsg 為 false 才會顯示 EndMsg
   * */
  const initSetHasMoreMsg = useCallback(() => {
    if (!roomID || !roomMsgLength || !infiniteScrollNode) {
      setHasMoreMsg(false);
      return;
    }
    try {
      const infiniteChild = infiniteScrollNode.el.getElementsByClassName(
        'infinite-scroll-component__outerdiv',
      )[0];
      infiniteChild.offsetHeight < elHeight
        ? setHasMoreMsg(false)
        : setHasMoreMsg(true);
    } catch (error) {
      console.log('initSetHasMoreMsg error', error);
    }
  }, [infiniteScrollNode, elHeight, roomID, roomMsgLength]);
  useEffect(() => {
    initSetHasMoreMsg();
  }, [initSetHasMoreMsg]);

  useImperativeHandle(ref, () => ({
    setHasNewMsgHandle,
    scrollNodeTo,
  }));

  return (
    <Box
      ref={getRoomContentEl}
      position='relative'
      flex='1'
      overflow='hidden'
      bgColor={bgColor || 'gray-1'}
      backgroundImage={`url(${bgImg})`}
      backgroundSize='cover'
      backgroundPosition='center'
      backgroundRepeat='no-repeat'>
      {/* control header */}
      <Box position='absolute' zIndex={10} width='100%'>
        {controlHeader}
      </Box>
      {/* msg block */}
      <Box
        ref={getWrapBoxRef}
        height={`${elHeight}px`}
        overflow='auto'
        display='flex'
        flexDirection='column-reverse'
        p='3'
        pb='0'>
        {wrapBoxRef && (
          <InfiniteScroll
            ref={getInfiniteScrollEl}
            scrollableTarget={wrapBoxRef}
            dataLength={roomMsgLength}
            endMessage={
              <EndMsg
                showEndMsg={showEndMsg}
                loadPreRoomMsg={loadPreRoomMsgHandel}
              />
            }
            next={loadMoreMsgHandle}
            hasMore={hasMoreMsg}
            loader={
              <p style={{ textAlign: 'center' }}>
                <b>载入中...</b>
              </p>
            }
            inverse={true}
            style={{ display: 'flex', flexDirection: 'column-reverse' }} // 反轉內容順序
            onScroll={onScroll}>
            {children}
          </InfiniteScroll>
        )}
      </Box>
      {/* 邀請控制 */}
      {controlFooter}
      <ScrollDownBtn
        show={scrollToBtnShow}
        hasNewMsg={hasNewMsg}
        onClick={scrollNodeTo}
      />
    </Box>
  );
};

export default forwardRef(ChatContent);
