import React from 'react';
import { Box } from '@WRAP_COMP';

function CSRoomFullBg({ children = null, bgImgUrl = '', bgColor = '' }) {
  return (
    <Box
      height='100%'
      width='100%'
      position='relative'
      display='flex'
      justifyContent='center'
      alignItems='center'
      backgroundSize='cover'
      backgroundPosition='center'
      backgroundRepeat='no-repeat'
      overflow='hidden'
      bgColor={bgColor}
      {...(bgImgUrl ? { backgroundImage: `url(${bgImgUrl})` } : {})}>
      {children}
    </Box>
  );
}

export default CSRoomFullBg;
