import { createSlice } from '@reduxjs/toolkit';

/**
 * 房間訊息相關
 */
const initialState = {
  roomMsg: [],
  // roomMsgTotal: 0,
  lastReadID: 0,
  hasMoreMsg: true,
  currPreRoomIdx: 0,
  currPreRoomID: 0,
};

export const roomMsgSlice = createSlice({
  name: 'roomMsg',
  initialState,
  reducers: {
    setRoomMsg: (state, { payload }) => {
      state.roomMsg = payload;
    },
    // setRoomMsgTotal: (state, { payload }) => {
    //   state.roomMsgTotal = payload;
    // },
    setLastReadID: (state, { payload }) => {
      state.lastReadID = payload;
    },
    setHasMoreMsg: (state, { payload }) => {
      state.hasMoreMsg = payload;
    },
    setCurrPreRoomIdx: (state, { payload }) => {
      state.currPreRoomIdx = payload;
    },
    setCurrPreRoomID: (state, { payload }) => {
      state.currPreRoomID = payload;
    },
    resetRoomMsg: state => {
      state.roomMsg = [];
      state.roomMsgTotal = 0;
      state.lastReadID = 0;
      state.hasMoreMsg = true;
      state.currPreRoomIdx = 0;
      state.currPreRoomID = 0;
    },
  },
});

export const {
  setRoomMsg,
  // setRoomMsgTotal,
  setLastReadID,
  setHasMoreMsg,
  setCurrPreRoomIdx,
  setCurrPreRoomID,
  resetRoomMsg,
} = roomMsgSlice.actions;
export default roomMsgSlice.reducer;
