import React, { useEffect, useState, useCallback } from 'react';
import { Box } from '@WRAP_COMP';
import { apiType, useQueryApi } from '@/hook/useApi';

function DepositToken({ token = '' }) {
  const [code, setCode] = useState('');
  const { fetchData } = useQueryApi(apiType.GET_WEB_DEPOSIT_TOKEN);
  const handleFetchData = useCallback(async () => {
    if (!token) return;
    const { error, data } = await fetchData({ token });
    if (error) return;
    setCode(data?.getWebToken?.code || '');
  }, [token, fetchData]);
  useEffect(() => {
    handleFetchData();
  }, [handleFetchData]);

  if (!code) return null;
  return (
    <Box px='3' py='2' bgColor='warning-light' color='warning'>
      客服密码：{code}
    </Box>
  );
}

export default DepositToken;
