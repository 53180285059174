import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { Typography, Spin, Divider, Empty, Badge } from 'antd';
import { Popup, Selector, DatePickerView } from 'antd-mobile';
import { RightOutlined } from '@ant-design/icons';
import { Button, Row, Col, Box } from '@/plugin/wrap-comp';
import useRecMessage from '@HOOK/useRecMessage';
import { useQueryApi, apiType } from '@HOOK/useApi';
import {
  OrderStatusKeys,
  formatDateTime,
  formatSearchDateTime,
  setDateRanges,
  labelRenderer,
  toMoment,
  formatSearchDate,
  compareRangeDateStrSize,
  formatDateStr,
  ConsultingOrderTypeKeys,
  PayType,
  PayTypeImg,
} from '@/utils';
import orderURL from '@/assets/order.png';
import useOrder from '@/hook/useOrder';
import CountdownButton from '@/components/CountdownButton';
import ClientDrawerWrap from '@/components/Drawer/ClientDrawerWrap';
import useAlertMessage from '@/hook/useAlertMessage';
import useRWD from '@/hook/useRWD';


const dateTimeRange = setDateRanges(0);
const defaultSearchDataFormat = formatSearchDateTime([
  dateTimeRange.start,
  dateTimeRange.end,
]);

const dateRange = [
  toMoment(defaultSearchDataFormat[0]).toDate(),
  toMoment(defaultSearchDataFormat[1]).toDate(),
];

export const statusOptions = Object.keys(OrderStatusKeys).reduce(
  (acc, currKey) => {
    acc.push({
      label: OrderStatusKeys[currKey].label,
      value: OrderStatusKeys[currKey].key,
    });
    return acc;
  },
  [],
);

function OrderList({ roomID = 0 }, ref) {
  const clientDrawerWrapRef = useRef(null);
  const { isMobileSize } = useRWD();

  const { onErrorMsg } = useAlertMessage();

  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);

  const [searchData, setSearchData] = useState({
    createdAtGTE: defaultSearchDataFormat[0],
    createdAtLTE: defaultSearchDataFormat[1],
    statusIn: [OrderStatusKeys['Unknown'].key],
  });

  // console.log(
  //   'createdAtLTE',
  //   formatDateStr(toMoment(searchData.createdAtLTE).toDate()),
  // );

  const selectorVal = useRef(OrderStatusKeys['Unknown'].key);
  const [dateInputVal, setDateInputVal] = useState('1天');
  const [datePickerValue, setDatePickerValue] = useState(dateRange);

  const [visibleStatusPopup, setVisibleStatusPopup] = useState(false);
  const [visibleDatePopup, setVisibleDatePopup] = useState(false);

  const [visibleDatePickerView, setVisibleDatePickerView] = useState(null);

  const {
    cancelDepositConfirm,
    consultingOrder,
    loading: actionLoading,
  } = useOrder({
    onCancelSuccess: () => clientDrawerWrapRef.current.setVisible(false),
  });

  const { fetchData, loading } = useQueryApi(apiType.listOrder, {
    onSuccess: ({ listOrder }) => {
      setList(listOrder.orders || []);
      const _list = listOrder.orders.filter(item =>
        [
          OrderStatusKeys['Pending'].key,
          OrderStatusKeys['Waiting'].key,
        ].includes(item.status),
      );
      setCount(_list.length);
    },
  });
  const fetchPendingOrder = useCallback(
    async (searchData = {}) =>
      fetchData({
        pagination: { perPage: 999, page: 1 },
        in: { ...searchData },
      }),
    [fetchData],
  );

  const handleVisibleDatePickerView = index => {
    return () => {
      visibleDatePickerView === index
        ? setVisibleDatePickerView(null)
        : setVisibleDatePickerView(index);
    };
  };

  const onChangeDateRangeBtn = (rangeVal, dateInputVal) => {
    const dateRanges = setDateRanges(rangeVal);
    const defaultSearchDataFormat = formatSearchDateTime([
      dateRanges.start,
      dateRanges.end,
    ]);
    const _searchData = {
      ...searchData,
      createdAtGTE: defaultSearchDataFormat[0],
      createdAtLTE: defaultSearchDataFormat[1],
    };
    setSearchData(_searchData);
    setDateInputVal(dateInputVal);
    fetchPendingOrder(_searchData);
  };

  const onChangeDatePickerView = index => {
    return val => {
      let _v = [...datePickerValue];
      _v[index] = val;
      setDatePickerValue(_v);
    };
  };

  const onSubmitStatus = () => {
    const _searchData = { ...searchData, statusIn: [selectorVal.current] };
    setSearchData(_searchData);
    setVisibleStatusPopup(false);
    fetchPendingOrder(_searchData);
  };

  const onSubmitDateRange = () => {
    const isValid = compareRangeDateStrSize(...datePickerValue);
    if (!isValid) {
      onErrorMsg('开始时间不可大于结束时间');
      return;
    }

    const _searchData = {
      ...searchData,
      createdAtGTE: formatSearchDate(datePickerValue)[0],
      createdAtLTE: formatSearchDate(datePickerValue)[1],
    };
    setSearchData(_searchData);

    setDateInputVal('自定');
    setVisibleDatePickerView(null);
    setVisibleDatePopup(false);

    fetchPendingOrder(_searchData);
  };

  useEffect(() => {
    fetchPendingOrder({
      ...searchData,
      statusIn: [
        OrderStatusKeys['Pending'].key,
        OrderStatusKeys['Waiting'].key,
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useRecMessage({
    userOrder: useCallback(
      () => fetchPendingOrder(searchData),
      [fetchPendingOrder, searchData],
    ),
  });

  const handleOpen = () => {
    clientDrawerWrapRef.current.setVisible(true);
    fetchPendingOrder(searchData);
  };

  useImperativeHandle(ref, () => ({
    setVisible: visible => {
      if (visible) fetchPendingOrder(searchData);
      clientDrawerWrapRef.current.setVisible(visible);
    },
  }));

  return (
    <>
      <Button
        onClick={handleOpen}
        style={{
          background: '#E8F6FF',
          color: '#3392D1',
          border: 'none',
          borderRadius: '4px',
        }}>
        <Box display='flex' alignItems='center'>
          历史订单
          <Badge count={count} size='small' />
        </Box>
      </Button>

      <ClientDrawerWrap
        ref={clientDrawerWrapRef}
        title='历史订单'
        height='80vh'>
        <Spin spinning={loading}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-around'
            p={isMobileSize ? '3' : '0'}
            pb='3'>
            <Box
              display='flex'
              alignItems='center'
              style={{ whiteSpace: 'nowrap' }}
              mr='2'
              onClick={() => setVisibleStatusPopup(true)}>
              <Box mr='1'>状态</Box>
              <Box borderRadius='8px' border='1px solid #d7d7d7' px='2' py='1'>
                {OrderStatusKeys[searchData.statusIn[0]].label}
              </Box>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => setVisibleDatePopup(true)}
              mr='2'>
              <Box mr='1'>时间</Box>
              <Box borderRadius='8px' border='1px solid #d7d7d7' px='2' py='1'>
                {dateInputVal}
              </Box>
            </Box>
            <Box display='flex'>
              <Button
                size='small'
                type='text'
                onClick={() => onChangeDateRangeBtn(0, '1天')}>
                1天
              </Button>
              <Button
                size='small'
                type='text'
                onClick={() => onChangeDateRangeBtn(4, '3天')}>
                3天
              </Button>
              <Button
                size='small'
                type='text'
                onClick={() => onChangeDateRangeBtn(1, '7天')}>
                7天
              </Button>
              <Button
                size='small'
                type='text'
                onClick={() => onChangeDateRangeBtn(2, '30天')}>
                30天
              </Button>
            </Box>
          </Box>
          <Box style={{ overflow: 'auto' }} px={isMobileSize ? '3' : '0'}>
            {list.length === 0 && <Empty description={false} />}
            {list.map(item => {
              return (
                <Box
                  key={item.orderNo}
                  borderRadius='10px'
                  border='1px solid #d7d7d7'
                  mb='2'
                  overflow='hidden'>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    bgColor='#eff1fb'
                    mb='2'
                    p='2'>
                    <Typography.Text
                      ellipsis
                      style={{ fontSize: '12px', width: '100%' }}>
                      订单号：{item.orderNo}
                    </Typography.Text>
                    <CountdownButton
                      type='text'
                      loading={actionLoading}
                      style={{
                        borderColor: '#1591dc',
                        borderRadius: '4px',
                        color: '#1591dc',
                        padding: '0 5px',
                        height: '22px',
                        fontSize: '12px',
                        marginRight: '4px',
                      }}
                      onClick={async () => {
                        await consultingOrder({
                          in: {
                            roomID,
                            orderNo: item.orderNo,
                            type: ConsultingOrderTypeKeys['Consulting'],
                          },
                        });
                        clientDrawerWrapRef.current.setVisible(false);
                      }}>
                      咨询此单
                    </CountdownButton>
                    <Typography.Text
                      style={{
                        fontSize: '12px',
                        color: OrderStatusKeys[item.status]?.color,
                        whiteSpace: 'nowrap',
                      }}>
                      {OrderStatusKeys[item.status]?.label}
                    </Typography.Text>
                  </Box>

                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    px='3'
                    mb='2'>
                    <Box display='flex'>
                      <img src={PayTypeImg[item.payType] || orderURL} alt='' width='40' height='40' />
                      <Box ml='2'>
                        <Typography.Text strong>
                          {PayType[item.payType]?.label || '官方充值'}
                        </Typography.Text>
                        <br />
                        <Typography.Text style={{ fontSize: '12px' }}>
                          {formatDateTime(item.createdAt)}
                        </Typography.Text>
                      </Box>
                    </Box>
                    <Box fontSize='14px' color='#379aff' fontWeight='bolder'>
                      {item.amount} 元
                    </Box>
                  </Box>

                  {(item.status === 'Waiting' || item.status === 'Pending') && (
                    <>
                      <Divider style={{ margin: 0 }} />
                      <Row gutter='8' px='3'>
                        <Col span='11'>
                          <Button
                            type='text'
                            block
                            onClick={() =>
                              cancelDepositConfirm({
                                roomID,
                                orderNo: item.orderNo,
                              })
                            }>
                            取消订单
                          </Button>
                        </Col>
                        <Col span='2' style={{ textAlign: 'center' }}>
                          <Divider type='vertical' style={{ height: '32px' }} />
                        </Col>
                        <Col span='11'>
                          <CountdownButton
                            type='text'
                            block
                            loading={actionLoading}
                            onClick={async () => {
                              await consultingOrder({
                                in: {
                                  roomID,
                                  orderNo: item.orderNo,
                                  type: ConsultingOrderTypeKeys['Urging'],
                                },
                              });
                              clientDrawerWrapRef.current.setVisible(false);
                            }}>
                            催单
                          </CountdownButton>
                        </Col>
                      </Row>
                    </>
                  )}
                </Box>
              );
            })}
          </Box>
        </Spin>
      </ClientDrawerWrap>

      <Popup
        visible={visibleStatusPopup}
        onMaskClick={() => setVisibleStatusPopup(false)}
        onClose={() => setVisibleStatusPopup(false)}
        bodyStyle={{ padding: '14px' }}>
        <Selector
          style={{
            '--border-radius': '100px',
            '--border': 'solid transparent 1px',
            '--checked-border': 'solid var(--adm-color-primary) 1px',
            '--padding': '8px 24px',
          }}
          showCheckMark={false}
          options={statusOptions}
          defaultValue={searchData.statusIn[0]}
          onChange={v => (selectorVal.current = v[0])}
        />
        <Button
          type='primary'
          block
          onClick={onSubmitStatus}
          style={{ marginTop: '8px' }}>
          确认
        </Button>
      </Popup>

      <Popup
        visible={visibleDatePopup}
        onMaskClick={() => setVisibleDatePopup(false)}
        onClose={() => setVisibleDatePopup(false)}
        bodyStyle={{ padding: '14px' }}>
        <>
          {['开始时间', '结束时间'].map((item, idx) => (
            <React.Fragment key={idx}>
              <Box
                display='flex'
                justifyContent='space-between'
                onClick={handleVisibleDatePickerView(idx)}
                mb='3'
                fontSize='14px'>
                <Typography.Text>{item}</Typography.Text>
                <Typography.Text>
                  {formatDateStr(datePickerValue[idx])}
                  <RightOutlined />
                </Typography.Text>
              </Box>
              {visibleDatePickerView === idx && (
                <DatePickerView
                  defaultValue={datePickerValue[idx]}
                  value={datePickerValue[idx]}
                  onChange={onChangeDatePickerView(idx)}
                  renderLabel={labelRenderer}
                  style={{ '--height': '125px' }}
                />
              )}
            </React.Fragment>
          ))}
          <Button
            type='primary'
            block
            onClick={onSubmitDateRange}
            style={{ marginTop: '8px' }}>
            确认
          </Button>
        </>
      </Popup>
    </>
  );
}

export default forwardRef(OrderList);
