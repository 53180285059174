// 收集所有

const currFolderFiles = require.context('./', false, /\.js$/);

// 取得目錄下所有檔案名稱，並過濾不需要的檔案
const getModuleFiles = currFolderFiles.keys().filter((item) => {
  // 不需要的檔案寫進來
  const excludeFiles = ['./index.js'];

  return !excludeFiles.includes(item);
});

const APIs = (function outputAPI() {
  // 將需要的檔案export default組成物件
  let allAPI = {};
  getModuleFiles.forEach((path) => {
    // 用來集合所有API的物件
    const apiFuncs = {};

    // 取得非default export的內容，
    const apiNameList = Object.keys(currFolderFiles(path));
    apiNameList.forEach((ele) => {
      if (ele !== 'default') {
        apiFuncs[ele] = currFolderFiles(path)[ele];
      }
    });

    // 取得非default export的內容
    if (currFolderFiles(path).default) {
      const defaultAPIName = path.replace(/((.\/)|(.js))/g, '');
      apiFuncs[defaultAPIName] = currFolderFiles(path).default;
    }

    allAPI = {
      ...allAPI,
      ...apiFuncs
    };
  });

  return allAPI;
})();

export const defaultOptions = {
  isShowGeneralError: true, // api error 是否出現攔截器的錯誤視窗
  /**
   * api如果有錯, 會呼叫getError function讓組件處理錯誤
   * @param {String} message 錯誤碼
   * @param {Function} originHandler api攔截器原來的錯誤處理, 當遇到不需要特別處理的錯誤碼時, 可以呼叫它來執行原來的錯誤處理
   */
  getError: () => {}
};

export default APIs;
