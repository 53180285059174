import { gql } from '@apollo/client';
import { COMMENT_USER_ROOMS, CS_DETAIL_FIELD_LESS } from './Fragment';

// 聊天室列表(私聊)
export const LIST_DIRECT_ROOM = gql`
  ${COMMENT_USER_ROOMS}
  query listRoom($filter: RoomFilterInput!, $pagination: PaginationInput) {
    listRoom(filter: $filter, pagination: $pagination) {
      rooms {
        id
        uid
        type
        createdAt
        updatedAt
        userRooms {
          ...userRoomsFields
        }
      }
      meta {
        total
      }
    }
  }
`;

// 更新 聊天室
export const UPDATE_ROOM = gql`
  mutation updateRoom($filter: RoomFilterInput!, $in: RoomUpdateInput!) {
    updateRoom(filter: $filter, in: $in) {
      id
      uid
      type
      createdAt
      updatedAt
    }
  }
`;

// 刪除 聊天室 (*刪除但會繼續連接使用者，除非使用者離開)
export const DELETE_ROOM = gql`
  mutation deleteRoom($filter: RoomFilterInput!) {
    deleteRoom(filter: $filter)
  }
`;

export const UPDATE_ROOM_REMARK = gql`
  ${CS_DETAIL_FIELD_LESS}
  mutation updateConsultingRoomRemark($in: UpdateConsultingRoomRemarkInput!) {
    updateConsultingRoomRemark(in: $in) {
      ...consultingDetailLessField
    }
  }
`;

// ----------------------------------------------------------

// 我的聊天室
export const MY_ROOM_LIST = gql`
  ${COMMENT_USER_ROOMS}
  query listUserRoom(
    $filter: UserRoomFilterInput!
    $pagination: PaginationInput
  ) {
    # 我的聊天室
    listUserRoom(filter: $filter, pagination: $pagination) {
      userRooms {
        id
        # userID
        # roomID
        isOperator
        lastReadMessageID
        room {
          id
          name
          type
          status
          ipAddress
          country
          administrativeArea
          deviceOS
          isPublic
          ownerUserID
          createdAt
          updatedAt
        }
        userRooms {
          ...userRoomsFields
        }
        user {
          id
          username
        }
      }
    }
  }
`;

// 我的待加入聊天室
export const MY_INVITATION_ROOM_LIST = gql`
  ${COMMENT_USER_ROOMS}
  query listUserInvitation(
    $filter: UserInvitationFilterInput!
    $pagination: PaginationInput
  ) {
    listUserInvitation(filter: $filter, pagination: $pagination) {
      userInvitations {
        id
        type
        inviteeUserID
        inviterUserID
        createdAt
        room {
          id
          name
          type
          isPublic
          ownerUserID
          createdAt
          updatedAt
          userRooms {
            ...userRoomsFields
          }
        }
      }
    }
  }
`;

// 更新 我的聊天室
export const UPDATE_USER_ROOM = gql`
  mutation updateUserRoom(
    $filter: UserRoomFilterInput!
    $in: UserRoomUpdateInput!
  ) {
    updateUserRoom(filter: $filter, in: $in) {
      id
    }
  }
`;

// ----------------------------------------------------------

// room detail
export const GET_ROOM = gql`
  ${COMMENT_USER_ROOMS}
  query getRoom($filter: RoomFilterInput!) {
    getRoom(filter: $filter) {
      id
      type
      createdAt
      updatedAt
      # groupDetail {
      #   roomID
      #   imageURL
      #   name
      #   isPublic
      #   inviteLevel
      #   strangerLevel
      #   strangerPassword
      #   ownerUserID
      # }
      consultingDetail {
        roomID
        deactivatedAt
        deactivatedUserID
        ipAddress
        country
        administrativeArea
        sourceDomain
        accessDomain
        question
        deviceOS
        ownerUserID
        csUserID
        status
        type
        historyRooms
        remark
        userOrder {
          orderNo
          amount
          payType
          currency
          status
          createdAt
        }
        formData {
          id
          type
          label
          content {
            value
            items {
              label
              value
            }
          }
          rule {
            require
            verifyType
          }
        }
      }
      userRooms {
        ...userRoomsFields
      }
      # userInvitations {
      #   id
      #   inviteeUserID
      #   inviteeUser {
      #     ...userRoomFields
      #   }
      #   inviterUser {
      #     ...userRoomFields
      #   }
      # }
    }
  }
`;

// ----------------------------------------------------------

// 接受邀請
export const ACCEPT_INVITE = gql`
  mutation acceptInvite($invitationID: Uint64!) {
    acceptInvite(invitationID: $invitationID)
  }
`;

// 刪除邀請 || 拒絕邀請
export const DELETE_INVITE = gql`
  mutation deleteUserInvitation($filter: UserInvitationFilterInput!) {
    deleteUserInvitation(filter: $filter)
  }
`;

// 邀請XXX進入 群組聊天室
export const INVITE_GROUP = gql`
  mutation inviteGroup($in: inviteRoomInput!) {
    inviteGroup(in: $in)
  }
`;

// 離開 群組聊天室
export const LEAVE_GROUP = gql`
  mutation leaveGroup($roomID: Uint64!) {
    leaveGroup(roomID: $roomID)
  }
`;
