import React from 'react'
import { Box } from '@/plugin/wrap-comp';
import useRWD from '@/hook/useRWD';

function ContentWrap({ children }) {
  const { isMobileSize, isMobileDevice } = useRWD();
  const borderSize = isMobileSize || isMobileDevice ? 0 : 12;
  return (
    <Box
    display='flex'
    flexDirection='column'
    flex='1 1  auto'
    overflow='hidden'
    borderBottomRightRadius={borderSize}
    borderBottomLeftRadius={borderSize}>
    {children}
  </Box>
  )
}

export default ContentWrap