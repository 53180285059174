import { gql } from '@apollo/client';
import { MSG_SYSTEM, MSG_BUBBLE } from './Fragment';

export const MSG_REQUIRED = gql`
  fragment messageFields on Message {
    id
    roomID
    contentType
    timestamp
    status
    from {
      userID
      accountType
      username
      aliasName
      tags
    }
  }
`;

export const LIST_MSG = gql`
  ${MSG_REQUIRED}
  ${MSG_SYSTEM}
  ${MSG_BUBBLE}
  query listMessage(
    $filter: MessageFilterInput!
    $pagination: PaginationInput
  ) {
    listMessage(filter: $filter, pagination: $pagination) {
      messages {
        ...messageFields
        text {
          text
          isEncrypt
        }
        file {
          text
          fileURL
          type
        }
        join {
          userID
        }
        leave {
          userID
        }
        system {
          ...MsgSystemField
        }
        bubble {
          ...MsgBubbleField
        }
      }
      meta {
        total
      }
    }
  }
`;

// 傳送訊息 (私聊的第一個步驟 帶 userID 之後帶 roomID 即可)
export const POST_MSG = gql`
  ${MSG_REQUIRED}
  ${MSG_SYSTEM}
  ${MSG_BUBBLE}
  mutation postMessage($in: MessageInput!) {
    postMessage(in: $in) {
      ...messageFields
      text {
        text
        isEncrypt
      }
      file {
        text
        fileURL
        type
      }
      join {
        userID
      }
      leave {
        userID
      }
      system {
        ...MsgSystemField
      }
      renew
      bubble {
        ...MsgBubbleField
      }
    }
  }
`;

export const POST_TEMP_MSG = gql`
  ${MSG_REQUIRED}
  ${MSG_SYSTEM}
  ${MSG_BUBBLE}
  mutation postTemplateMessage($in: MsgTemplateInput!) {
    postTemplateMessage(in: $in) {
      ...messageFields
      text {
        text
        isEncrypt
      }
      file {
        text
        fileURL
        type
      }
      join {
        userID
      }
      leave {
        userID
      }
      system {
        ...MsgSystemField
      }
      renew
      bubble {
        ...MsgBubbleField
      }
    }
  }
`;

export const RECEIVE_MSG = gql`
  ${MSG_REQUIRED}
  ${MSG_SYSTEM}
  ${MSG_BUBBLE}
  subscription receiveMessage($userAuth: UserAuth!) {
    receiveMessage(userAuth: $userAuth) {
      ...messageFields
      consulting {
        roomID
        status
      }
      text {
        text
        isEncrypt
      }
      file {
        text
        fileURL
        type
      }
      voice {
        duration
        size
        mimeType
        URL
      }
      join {
        userID
      }
      invite {
        id
        inviteeUserID
        inviterUserID
        roomID
        inviteeUserIDs
      }
      leave {
        userID
      }
      lastReads {
        userID
        messageID
        roomID
      }
      roomUpdate {
        roomID
        roomUID
        roomType
        consultingDetail {
          roomID
          merchantID
          deactivatedAt
          deactivatedUserID
          ipAddress
          isProactive
          country
          administrativeArea
          sourceDomain
          accessDomain
          question
          deviceOS
          ownerUserID
          status
          createdAt
          isWaitFirstResponse
          isTimeoutResponse
        }
      }
      typing {
        userID
        roomID
        username
        content
      }
      userOnlineInfo {
        userID
        isOnline
      }
      businessSystemNotification {
        createdAt
        message
      }
      system {
        ...MsgSystemField
      }
      bubble {
        ...MsgBubbleField
      }
    }
  }
`;

// 打字
export const POST_TYPING = gql`
  ${MSG_REQUIRED}
  mutation postTyping($in: MsgTypingInput!) {
    postTyping(in: $in) {
      ...messageFields
    }
  }
`;

// 更新訊息 (撤回訊息, 取消撤回訊息)
export const UPDATE_MSG = gql`
  ${MSG_REQUIRED}
  mutation updateMessage($messageID: String!, $in: MessageUpdateInput!) {
    updateMessage(messageID: $messageID, in: $in) {
      ...messageFields
    }
  }
`;
