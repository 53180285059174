import { gql } from '@apollo/client';
import { CS_GREETING_FIELD } from './Fragment';

/**
 *  行銷問候語設定
 */
export const LIST_CS_GREETING = gql`
  ${CS_GREETING_FIELD}
  query listConsultingGreeting($filter: ConsultingGreetingFilterInput) {
    listConsultingGreeting(filter: $filter) {
      ConsultingGreetings {
        ...CSGreeting
      }
    }
  }
`;
export const CREATE_CS_GREETING = gql`
  ${CS_GREETING_FIELD}
  mutation createConsultingGreeting($in: ConsultingGreetingCreateInput!) {
    createConsultingGreeting(in: $in) {
      ...CSGreeting
    }
  }
`;
export const UPDATE_CS_GREETING = gql`
  ${CS_GREETING_FIELD}
  mutation updateConsultingGreeting(
    $filter: ConsultingGreetingFilterInput!
    $in: ConsultingGreetingUpdateInput!
  ) {
    updateConsultingGreeting(filter: $filter, in: $in) {
      ...CSGreeting
    }
  }
`;
export const DELETE_CS_GREETING = gql`
  mutation deleteConsultingGreeting($filter: ConsultingGreetingFilterInput!) {
    deleteConsultingGreeting(filter: $filter)
  }
`;
