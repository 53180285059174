import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBeepEnabled, setBeepAudioSrc } from '@/store/global';
import { useQueryApi, useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';

const BEEP_FILE_KEY = 'bochat.platform.consulting.beep';
const BEEP_ENABLED_KEY = 'bochat.platform.consulting.beep.enabled';
const BEEP_CLIENT_ENABLED_KEY =
  'bochat.platform.consulting.client.beep.enabled';

const useBeep = () => {
  const { onWarningMsg, onErrorMsg } = useAlertMessage();
  const { beepEnabled, beepAudioSrc } = useSelector(({ global }) => global);
  const dispatch = useDispatch();

  const { fetchData, loading } = useQueryApi(apiType.GET_PLATFORM_SETTING);
  const [updateData, { loading: updateLoading }] = useMutationApi(
    apiType.UPDATE_PLATFORM_SETTING,
  );

  // 提示音 音檔
  const fetchBeepFile = useCallback(
    async (key = BEEP_FILE_KEY) => {
      const { error, data } = await fetchData({
        filter: { PlatformSetting: { key } },
      });
      if (!error) {
        dispatch(setBeepAudioSrc(data?.getPlatformSetting?.value));
      }
    },
    [dispatch, fetchData],
  );

  // 更新提示音 音檔
  const updateBeepFile = useCallback(
    async ({ data }, key = BEEP_FILE_KEY) => {
      const { error } = await updateData({
        filter: { PlatformSetting: { key } },
        in: data,
      });
      if (!error) fetchBeepFile();
    },
    [fetchBeepFile, updateData],
  );

  // 提示音狀態
  const [beepClientEnabled, setClientBeepEnabled] = useState(false);
  const fetchBeepEnabled = useCallback(
    async (key = BEEP_ENABLED_KEY) => {
      const { error, data } = await fetchData({
        filter: { PlatformSetting: { key } },
      });
      if (error) return;
      const callback = {
        [BEEP_ENABLED_KEY]: () =>
          dispatch(setBeepEnabled(data.getPlatformSetting.value === 'Yes')),
        [BEEP_CLIENT_ENABLED_KEY]: () =>
          setClientBeepEnabled(data.getPlatformSetting.value === 'Yes'),
      };
      callback[key]?.();
    },
    [dispatch, fetchData],
  );

  // 更新提示音狀態
  const updateBeepEnabled = useCallback(
    async ({ data }, key = BEEP_ENABLED_KEY) => {
      const { error } = await updateData({
        filter: { PlatformSetting: { key } },
        in: data,
      });
      if (!error) fetchBeepEnabled(key);
    },
    [fetchBeepEnabled, updateData],
  );

  const playPromise = useCallback(
    audioObj => {
      if (!audioObj) return;
      const playAudioPromise = audioObj.play();
      if (playAudioPromise) {
        playAudioPromise
          .then(() => {})
          .catch(err => {
            err?.name === 'NotAllowedError'
              ? onWarningMsg('请启用浏览器声音播放权限')
              : onErrorMsg(err);
          });
      }
    },
    [onErrorMsg, onWarningMsg],
  );

  return {
    beepAudioSrc,
    beepEnabled,
    beepClientEnabled,
    loading,
    updateLoading,
    keys: { BEEP_FILE_KEY, BEEP_ENABLED_KEY, BEEP_CLIENT_ENABLED_KEY },
    playPromise,
    fetchBeepFile,
    fetchBeepEnabled,
    updateBeepEnabled,
    updateBeepFile,
  };
};

export default useBeep;
