import { createSlice } from '@reduxjs/toolkit';
import { getStorage, setStorage } from '@/utils';

/**
 * 存放權限相關
 * meInfo = {}
 * authRoutesInfo = [] // server 取得可看見的權限清單
 */

const authStorageKey = {
  meInfo: 'meInfo',
  authRoutesInfo: 'authRoutesInfo',
  authRoutesKey: 'authRoutesKey',
};

const initialState = {
  meInfo: getStorage(authStorageKey.meInfo) || {},
  authRoutesInfo: getStorage(authStorageKey.authRoutesInfo) || [],
  authRoutesKey: getStorage(authStorageKey.authRoutesKey) || [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMeInfo: (state, { payload }) => {
      setStorage(authStorageKey.meInfo, payload);
      state.meInfo = payload;
    },

    setAuthRoutesInfo: (state, { payload }) => {
      setStorage(authStorageKey.authRoutesInfo, payload);
      state.authRoutesInfo = payload;
    },

    setAuthRoutesKey: (state, { payload }) => {
      setStorage(authStorageKey.authRoutesKey, payload);
      state.authRoutesKey = payload;
    },

    // 移除暫存在 redux auth.js 裡面的內容
    resetAuth: state => {
      setStorage(authStorageKey.meInfo, null);
      setStorage(authStorageKey.authRoutesInfo, null);
      setStorage(authStorageKey.authRoutesKey, null);
      state.meInfo = {};
      state.authRoutesInfo = [];
      state.authRoutesKey = [];
    },
  },
});

export const { setMeInfo, setAuthRoutesInfo, setAuthRoutesKey, resetAuth } =
  authSlice.actions;
export default authSlice.reducer;
