import React from 'react';
import { Statistic } from 'antd';
import { Box } from '@WRAP_COMP';
const { theme } = require('@/plugin/theme');
function Countdown({
  value = 0,
  color = 'danger',
  text = '请在{value}内完成充值',
  size = 14,
  bgColor = '',
  onFinish = () => {},
  wrapProps = {},
}) {
  const textArr = text.split('{value}');
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      bgColor={bgColor}
      {...wrapProps}>
      {textArr[0]}
      <Statistic.Countdown
        value={value * 1000}
        valueStyle={{ color: theme[color] || color, fontSize: `${size}px` }}
        onFinish={onFinish}
        style={{ margin: '0 5px' }}
      />
      {textArr[1]}
    </Box>
  );
}
export default Countdown;
