import { useState, useEffect, useCallback } from 'react';
import { IS_MOBILE } from '@/const';
import useEventListener from '@/hook/useEventListener';

const isWebview = () => {
  var useragent = navigator.userAgent;
  var rules = [
    'WebView',
    '(iPhone|iPod|iPad)(?!.*Safari/)',
    'Android.*(wv|.0.0.0)',
  ];
  var regex = new RegExp(`(${rules.join('|')})`, 'ig');
  return Boolean(useragent.match(regex));
};

const useRWD = () => {
  const [size, setSize] = useState('mobile');

  const handleRWD = useCallback(() => {
    if (window.innerWidth > 1200) setSize('xl');
    else if (window.innerWidth > 992) setSize('lg');
    else if (window.innerWidth > 768) setSize('md');
    else if (window.innerWidth > 576) setSize('sm');
    else setSize('mobile');
  }, []);

  useEventListener('resize', handleRWD);
  useEffect(() => {
    handleRWD();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    size,
    isMobileSize: size === 'mobile',
    isMobileDevice: IS_MOBILE,
    isWebview: isWebview(),
  };
};

export default useRWD;
