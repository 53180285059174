import Icon from '@ant-design/icons';

const SoundMuteSvg = () => (
  <svg width="24px" height="20px" fill="currentColor">
    <path d="M3.13867 13.8978C2.43779 12.7297 2.43779 11.2704 3.13867 10.1022C3.36726 9.72125 3.75537 9.46355 4.19521 9.40072L6.5262 9.06772C6.82768 9.02465 7.09654 8.85521 7.26546 8.60182L7.63256 8.05118C7.9233 7.61507 8.06869 7.39699 8.23034 7.20094C8.80071 6.50925 9.54656 5.98363 10.3898 5.67914C10.6288 5.59284 10.883 5.52927 11.3915 5.40215L12.0046 5.24888C12.3447 5.16386 12.5147 5.12135 12.6486 5.16301C12.7648 5.19919 12.8638 5.27651 12.9271 5.38048C13 5.50024 13 5.67551 13 6.02606V17.974C13 18.3246 13 18.4998 12.9271 18.6196C12.8638 18.7236 12.7648 18.8009 12.6486 18.8371C12.5147 18.8787 12.3447 18.8362 12.0046 18.7512L11.3915 18.5979C10.883 18.4708 10.6288 18.4072 10.3898 18.3209C9.54656 18.0164 8.80071 17.4908 8.23034 16.7991C8.06868 16.6031 7.92331 16.385 7.63256 15.9489L7.26546 15.3983C7.09654 15.1449 6.82768 14.9754 6.5262 14.9324L4.19521 14.5994C3.75537 14.5365 3.36726 14.2788 3.13867 13.8978Z" />
    <path d="M20.7071 9.29293C21.0976 9.68345 21.0976 10.3166 20.7071 10.7071L19.4142 12L20.7071 13.2929C21.0976 13.6835 21.0976 14.3166 20.7071 14.7071C20.3166 15.0977 19.6834 15.0977 19.2929 14.7071L18 13.4142L16.7071 14.7071C16.3166 15.0977 15.6834 15.0977 15.2929 14.7071C14.9024 14.3166 14.9024 13.6835 15.2929 13.2929L16.5858 12L15.2929 10.7071C14.9024 10.3166 14.9024 9.68345 15.2929 9.29293C15.6834 8.90241 16.3166 8.90241 16.7071 9.29293L18 10.5858L19.2929 9.29293C19.6834 8.90241 20.3166 8.90241 20.7071 9.29293Z" />
  </svg>
);

export default function SoundMuteIcon(props) {
  return <Icon component={SoundMuteSvg} {...props} />;
}
