import React from 'react';
import { Box } from '@WRAP_COMP';

function FrontDrop({ children, opacity = 80, ...props }) {
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      bgColor={`rgb(255 255 255 / ${opacity}%)`}
      zIndex='2'
      position='absolute'
      top='0'
      bottom='0'
      right='0'
      left='0'
      {...props}>
      {children}
    </Box>
  );
}

export default FrontDrop;
