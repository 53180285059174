// |狀態碼|系統定義|說明|
// |-|-|-|
// |400|400000|輸入可能有誤, 或請求資源失敗|
// |400|400001|輸入有誤, 或所需要的欄位為空|
// |400|400002|資源已被綁定|

// |401|401001|需要有身份驗證|
// |401|401002|token 不存在, 重新登錄|
// |401|401003|帐号或密碼錯誤|
// |401|401004|系統更新, token 失效, 請重新登錄|
// |401|401004|帐号已被停用|
// |401|401005|IP非法|

// |403|403000|權限不足|
// |403|403001|不允許操作|

// |404|404001|資源不存在|
// |405|405001|該請求方法不存在|
// |408|408001|請求超時|
// |409|409000|資源衝突|
// |409|409001|該資源已存在|
// |500|500000|伺服器錯誤|
// |500|500001|伺服器內部錯誤|

export const errorCodes = {
  400000: '输入可能有误, 或请求资源失败',
  400001: '栏位参数错误', // "GRAPHQL_VALIDATION_FAILED"
  400002: '资源已被绑定',
  400003: '使用者已注册',
  400106: '会员不在线上，无法联系',

  401001: '需要身份验证',
  401002: '身份不存在',
  401003: '帐号或密碼錯誤',
  401004: '权限过期，请重新登录',
  401005: 'IP非法',

  403000: '权限不足',
  403001: '不允许操作',
  403002: '帐号已被停用',

  404001: '资源不存在',
  405001: '请求方法不存在',
  408001: '请求超时',

  409000: '资源冲突',
  409001: '该资源已存在',
  500000: '伺服器错误',
  500001: '500 伺服器内部错误',
};

/**
 * 取得錯誤代碼的i18n key
 * @param {} code error code
 */
export const getErrorMessage = code => {
  return errorCodes[code] || code;
};

export const isLogoutErrorCode = code => {
  return ['401001', '401002', '401004'].indexOf(code) > -1;
};

export const isSendSentryErrorCode = code => {
  return ['500000', '500001'].indexOf(code) > -1;
};
