/**
 * 上傳圖片
 */
import { gql } from '@apollo/client';

export const CREATED_UPLOAD_URL = gql`
  mutation createUploadURL($in: CreateUploadURLInput!) {
    createUploadURL(in: $in) {
      files {
        fileName
        uploadURL
      }
    }
  }
`;

export const NEW_CREATE_UPLOAD = gql`
  mutation NewCreateUpload($in: Upload) {
    newCreateUpload(in: $in)
  }
`;

/**
 * 客服系統紅點
 * 有待諮詢 & 我的諮詢單
 */
export const GET_RED_POINT = gql`
  query redPointInfo($userID: Uint64) {
    listUserRoom(
      filter: {
        userRoom: { roomType: Consulting, userID: $userID }
        roomStatusIn: [Processing]
      }
    ) {
      # TODO total 有問題 先用 userRooms 自己算
      userRooms {
        id
      }
      meta {
        total
        totalUnRead
      }
    }
    # listConsultingRoom(filter: { statusIn: Active }) {
    #   meta {
    #     total
    #   }
    # }
  }
`;
