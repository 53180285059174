import { gql } from '@apollo/client';
import { CS_SETTING_FIELD } from './Fragment';

/**
 * 諮詢窗口管理
 */
export const GET_CS_ROOM_SETTING = gql`
  ${CS_SETTING_FIELD}
  query getConsultingRoomClientSetting(
    $filter: ConsultingRoomClientSettingFilterInput!
  ) {
    getConsultingRoomClientSetting(filter: $filter) {
      ...CSSetting
    }
  }
`;
export const LIST_CS_ROOM_SETTING = gql`
  ${CS_SETTING_FIELD}
  query listConsultingRoomClientSetting(
    $filter: ConsultingRoomClientSettingFilterInput
  ) {
    listConsultingRoomClientSetting(filter: $filter) {
      consultingRoomClientSettings {
        ...CSSetting
      }
    }
  }
`;
export const CREATE_CS_ROOM_SETTING = gql`
  ${CS_SETTING_FIELD}
  mutation createConsultingRoomClientSetting(
    $in: ConsultingRoomClientSettingCreateInput!
  ) {
    createConsultingRoomClientSetting(in: $in) {
      ...CSSetting
    }
  }
`;
export const UPDATE_CS_ROOM_SETTING = gql`
  ${CS_SETTING_FIELD}
  mutation updateConsultingRoomClientSetting(
    $filter: ConsultingRoomClientSettingFilterInput!
    $in: ConsultingRoomClientSettingUpdateInput!
  ) {
    updateConsultingRoomClientSetting(filter: $filter, in: $in) {
      ...CSSetting
    }
  }
`;
