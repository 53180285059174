/* eslint-disable no-use-before-define */
import { useMemo, useCallback } from 'react';
import { Modal, Typography } from 'antd';
import { BrowserType, isStorageAccessAllow } from '@/utils';
export { BrowserType };

// const SOGOU_DOWNLOAD_URL = 'https://ie.sogou.com/';
const CHROME_DOWNLOAD_URL = 'https://www.google.com/intl/zh-CN/chrome/';

// 搜狗
// Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.87 Safari/537.36 SE 2.X MetaSr 1.0
// 獵豹
// Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.79 Safari/537.36
// IE
// Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; McAfee; rv:11.0) like Gecko
// UC
// Mozilla/5.0 (iPhone; CPU iPhone OS 12_4_1 like Mac OS X; zh-CN) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/16G102 UCBrowser/13.9.8.1767 Mobile  AliApp(TUnionSDK/0.1.20.4)

const AGENT = window.navigator.userAgent;
const checkBrowser = () => {
  if (
    AGENT.indexOf(BrowserType['IE']) > 0 ||
    AGENT.indexOf('Trident/') > 0 ||
    !!document.documentMode
  )
    return 'IE';
  if (AGENT.indexOf(BrowserType['Edge']) > 0 || !!window.StyleMedia) {
    return BrowserType['Edge'];
  }
  if (AGENT.indexOf(BrowserType['Firefox']) >= 0) return BrowserType['Firefox'];
  if (AGENT.indexOf(BrowserType['Opera']) >= 0) return BrowserType['Opera'];
  if (AGENT.indexOf(BrowserType['Sogou']) >= 0) return BrowserType['Sogou'];
  if (AGENT.indexOf(BrowserType['Chrome']) >= 0) return BrowserType['Chrome'];
  if (AGENT.indexOf(BrowserType['Safari']) >= 0) return BrowserType['Safari'];
  if (AGENT.indexOf(BrowserType['Netscape']) >= 0)
    return BrowserType['Netscape'];
  if (AGENT.indexOf(BrowserType['UC']) >= 0) return BrowserType['UC'];
  const text = 'no mapping browser';
  console.log(text, AGENT);
  return '';
};

export const fullScreenHandle = (href = '') => {
  let a = document.createElement('a');
  a.href = href || window.location.href;
  a.target = '_blank';
  a.click();
};

function useBrowser() {
  const browser = useMemo(() => checkBrowser(), []);

  /**
   * 推薦下載瀏覽器
   *  */
  const browserWarningModel = useCallback(okCallback => {
    Modal.warning({
      title: '注意',
      content: (
        <>
          <span>当前浏览器过旧！</span>
          <br />
          <span>为达到最好的体验品质，请更換浏览器。</span>
          <Typography.Title
            level={5}
            onClick={() => fullScreenHandle(CHROME_DOWNLOAD_URL)}
            style={{ color: '#6993FF', cursor: 'pointer' }}>
            立即下载 Chrome 浏览器
          </Typography.Title>
        </>
      ),
      okText: '已下载',
      centered: true,
      onOk: close => (okCallback ? okCallback(close) : close()),
    });
  }, []);

  // 檢查是否為IE
  const checkIsOldBrowser = useCallback(
    okCallback => {
      browser === 'IE'
        ? browserWarningModel(okCallback)
        : okCallback && okCallback();
    },
    [browser, browserWarningModel],
  );

  /**
   * cookie access 權限檢查 (不允許就另開全螢幕)
   */
  const accessWarningModal = useCallback(() => {
    Modal.confirm({
      content: (
        <>
          <Typography.Title level={4}>请允许 Cookie 存取!</Typography.Title>
          <p>
            为达到最好的服务品质
            <br />
            {'请至浏览器设定 > 隐私权 > 允许第三方 Cookie 存取或关闭阻挡第三方'}
            Cookie 设定。
            <br />
            设定完成后请重新整理页面。
          </p>
        </>
      ),
      okText: '已开启权限',
      cancelText: '不允许存取',
      centered: true,
      onOk: close => {
        checkIsAccessAllow(close);
      },
      onCancel: () => {
        // 不允許存取就開啟新頁面
        fullScreenHandle();
        checkIsAccessAllow();
      },
    });
  }, [checkIsAccessAllow]);

  const checkIsAccessAllow = useCallback(
    okCallback => {
      isStorageAccessAllow()
        ? okCallback && okCallback()
        : accessWarningModal(okCallback);
    },
    [accessWarningModal],
  );

  return { browser, checkIsOldBrowser, checkIsAccessAllow };
}

export default useBrowser;
