import Icon from '@ant-design/icons';
const DoubleCheckSvg = () => (
  <svg
    width="1em"
    viewBox="0 0 594.149 594.149"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M448.8,161.925l-35.7-35.7l-160.65,160.65l35.7,35.7L448.8,161.925z M555.899,126.225l-267.75,270.3l-107.1-107.1
       l-35.7,35.7l142.8,142.8l306-306L555.899,126.225z M0,325.125l142.8,142.8l35.7-35.7l-142.8-142.8L0,325.125z"
    />
  </svg>
);

export default function DoubleCheckIcon(props) {
  return <Icon component={DoubleCheckSvg} {...props} />;
}
