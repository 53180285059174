import { useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ReceiveMsgContentType,
  RoomStatusKeys,
  MsgStatusKeys,
  MsgSystemType,
  OrderStatusKeys,
} from '@/utils';

// 一般訊息類
const REC_MSG_COMMON_CONTENT_TYPE = [
  ReceiveMsgContentType['Text'],
  ReceiveMsgContentType['File'],
  ReceiveMsgContentType['Voice'],
  ReceiveMsgContentType['Join'],
  ReceiveMsgContentType['Leave'],
  ReceiveMsgContentType['Invite'],
  ReceiveMsgContentType['Bubble'],
];

/**
 * 接收ws 訊息 各種 type || 自定義狀態 處理
 * @param.msgID {Number} 自訂判斷 沒設定就直接回傳對應狀態，有設定就比對 roomID 回傳
 * @param.roomID {Number} 自訂判斷 沒設定就直接回傳對應狀態，有設定就比對 roomID 回傳
 */
function useRecMessage({
  msgID = 0,
  roomID = 0,
  meID = 0,

  // contentType
  hi = () => {},
  join = () => {},
  leave = () => {},
  roomUpdate = () => {},
  bizSystem = () => {},
  typing = () => {},
  userOnlineInfo = () => {},
  lastRead = () => {},
  system = () => {},
  bubble = () => {},

  // msg Status
  msgStatusRetract = () => {},

  // csRoom Status
  csRoomStatusActive = () => {},
  csRoomStatusProcessing = () => {},
  csRoomStatusDeactivated = () => {},

  // system
  userOrder = () => {},
  payList = () => {},
  systemText = () => {},

  // order
  orderStatusCancel = () => {},
  orderStatusPending = () => {},
  orderStatusApproving = () => {},

  // 自定義 type
  msgCommonType = () => {},
  returnCallback = () => {},
} = {}) {
  const { recMsg } = useSelector(({ subscribe }) => subscribe);
  const settingIDProcess = useCallback(async (settingID, recID, callback) => {
    if (!callback) return;
    settingID ? settingID === recID && callback() : callback();
  }, []);

  const roomUpdateHandle = useCallback(
    recMsg => {
      roomUpdate(recMsg);
      const { status } = recMsg.roomUpdate.consultingDetail;
      const csStatusTypeMap = {
        [RoomStatusKeys['Active']]: csRoomStatusActive,
        [RoomStatusKeys['Processing']]: csRoomStatusProcessing,
        [RoomStatusKeys['Deactivated']]: csRoomStatusDeactivated,
      };
      settingIDProcess(meID, recMsg.from.userID, () =>
        csStatusTypeMap[status]?.(recMsg),
      );
    },
    [
      roomUpdate,
      csRoomStatusActive,
      csRoomStatusProcessing,
      csRoomStatusDeactivated,
      meID,
      settingIDProcess,
    ],
  );

  const systemHandle = useCallback(
    recMsg => {
      system(recMsg);
      const { type, content } = recMsg.system;
      const userOrderHandle = ({ orderStatus }) => {
        userOrder(recMsg);
        const orderStatusMap = {
          [OrderStatusKeys['Canceled'].key]: orderStatusCancel, // 取消訂單
          [OrderStatusKeys['Pending'].key]: orderStatusPending, // 進行中訂單
          [OrderStatusKeys['Waiting'].key]: orderStatusApproving, // 審核中訂單
        };
        settingIDProcess(msgID, recMsg.id, () =>
          orderStatusMap[orderStatus]?.(recMsg),
        );
      };
      const payListHandle = () => payList(recMsg);
      const systemTextHandle = () => systemText(recMsg);
      const msgSystemTypeMap = {
        [MsgSystemType['UserOrder']]: userOrderHandle,
        [MsgSystemType['PayList']]: payListHandle,
        [MsgSystemType['Text']]: systemTextHandle,
      };
      msgSystemTypeMap[type] && msgSystemTypeMap[type](content);
    },
    [
      msgID,
      system,
      payList,
      systemText,
      userOrder,
      orderStatusCancel,
      orderStatusPending,
      orderStatusApproving,
      settingIDProcess,
    ],
  );

  const contentTypeHandleMap = useMemo(
    () => ({
      [ReceiveMsgContentType['Hi']]: hi,
      [ReceiveMsgContentType['Join']]: join,
      [ReceiveMsgContentType['Leave']]: leave,
      [ReceiveMsgContentType['Typing']]: typing,
      [ReceiveMsgContentType['UserOnlineInfo']]: userOnlineInfo,
      [ReceiveMsgContentType['BusinessSystemNotification']]: bizSystem,
      [ReceiveMsgContentType['LastRead']]: lastRead,
      [ReceiveMsgContentType['RoomUpdate']]: roomUpdateHandle,
      [ReceiveMsgContentType['System']]: systemHandle,
      [ReceiveMsgContentType['Bubble']]: bubble,
    }),
    [
      bizSystem,
      hi,
      join,
      leave,
      typing,
      userOnlineInfo,
      lastRead,
      roomUpdateHandle,
      systemHandle,
      bubble,
    ],
  );

  const typeHandle = useCallback(
    recMsg => {
      /**
       * contentType
       */
      const { contentType, status } = recMsg;
      settingIDProcess(roomID, recMsg.roomID, () =>
        contentTypeHandleMap[contentType]?.(recMsg),
      );

      // 自定義 msg common type
      if (REC_MSG_COMMON_CONTENT_TYPE.includes(contentType)) {
        settingIDProcess(roomID, recMsg.roomID, () => msgCommonType(recMsg));
      }

      // 訊息收回
      if (status === MsgStatusKeys['Retract']) {
        settingIDProcess(msgID, recMsg.id, () => msgStatusRetract(recMsg));
      }
    },
    [
      roomID,
      contentTypeHandleMap,
      msgCommonType,
      msgID,
      msgStatusRetract,
      settingIDProcess,
    ],
  );

  useEffect(() => {
    if (recMsg) typeHandle(recMsg);
    return () => {
      recMsg && returnCallback(recMsg);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recMsg]);
}

useRecMessage.propTypes = {
  hi: PropTypes.func,
  join: PropTypes.func,
  roomUpdate: PropTypes.func,
  bizSystem: PropTypes.func,
  typing: PropTypes.func,
  userOnlineInfo: PropTypes.func,
  csRoomStatusActive: PropTypes.func,
  csRoomStatusProcessing: PropTypes.func,
  csRoomStatusDeactivated: PropTypes.func,
  msgCommonType: PropTypes.func,
  returnCallback: PropTypes.func,
};

export default useRecMessage;
