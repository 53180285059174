/**
 * filter-加上千分位符號
 * @param {Any}
 * @return {String} 加上千分號的字串數字 || '-'
 *  */
export const commaFormat = value => {
  let info = '0';
  value &&
    (info = value
      .toString()
      .replace(
        /^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
        (all, pre, groupOf3Digital) => {
          return pre + groupOf3Digital.replace(/\d{3}/g, ',$&');
        },
      ));
  return info;
};

/**
 * __typename in query
 * https://github.com/apollographql/apollo-client/issues/1913#issuecomment-393721604
 */
export const omitTypename = (key, value) =>
  key === '__typename' ? undefined : value;
export const omitTypenameHandle = payload =>
  JSON.parse(JSON.stringify(payload), omitTypename);

/**
 * 深層複製資料
 * 如果不是 null、undefined、空字串、數字0就使用 JSON方法過濾, 否則直接返回 info 預設值(空字串)
 */
export const cloneDeep = object => {
  let info = '';
  if (!!object) info = JSON.parse(JSON.stringify(object));
  return info;
};
export const isFunction = v => typeof v === 'function';

/**
 * 回傳一級域名
 * @param {string} url
 * @return {string} 一級域名
 */
export const filterSubDomain = url => {
  if (url.match(/\.\w*\b/g).length > 1) {
    let arr = url.split('.');
    return arr.slice(arr.length - 2).join('.');
  }
  return url;
};

/**
 * 字串 濾掉 html tag
 */
export const stripHtmlTag = str => str.replace(/(<([^>]+)>)/gi, '').trim();

/**
 * lazyRetry avoid chunk load error
 * to update the code to the latest version
 * will handle refreshing the browser in the event of an error
 */
export const lazyRetry = (componentImport, componentName) => {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${componentName}-refreshed`) ||
        'false',
    );
    componentImport()
      .then(component => {
        window.sessionStorage.setItem(
          `retry-${componentName}-refreshed`,
          'false',
        ); // success so reset the refresh
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(
            `retry-${componentName}-refreshed`,
            'true',
          ); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error);
      });
  });
};
