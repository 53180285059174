import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Box, Button } from '@WRAP_COMP';

function RedirectVerifyModel() {
  const { csRoomSetting } = useSelector(({ client }) => client);
  const [visible, setVisible] = useState(false);
  const [step, setStep] = useState(1);
  const step1Timer = useRef(null);
  const step2Timer = useRef(null);

  useEffect(() => {
    setVisible(csRoomSetting.redirectVerifyEnabled);
    csRoomSetting.redirectVerifyEnabled &&
      (step1Timer.current = setTimeout(() => setStep(2), 1300));
    return () => {
      step1Timer && clearTimeout(step1Timer.current);
    };
  }, [csRoomSetting.redirectVerifyEnabled]);

  useEffect(() => {
    step > 1 && (step2Timer.current = setTimeout(() => setVisible(false), 800));
    return () => {
      step2Timer.current && clearTimeout(step2Timer);
    };
  }, [step]);

  if (!visible) return null;
  return (
    <Box position='fixed' top='0' right='0' bottom='0' left='0' zIndex={100}>
      <Box
        height='100%'
        bgColor='white'
        p={3}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 80 }} spin />} />
        <Box pt={5} width='100%' textAlign='center'>
          <Typography.Title level={3}>
            {step === 1 ? '正在检测...' : '已通过安全检测'}
          </Typography.Title>
          {step === 2 && <p>如果没有自动跳转，请点击下方按钮前往</p>}
          <Button variant='success' block onClick={() => setVisible(false)}>
            点击前往
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default RedirectVerifyModel;
