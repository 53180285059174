import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { API_JUMP_URL } from '@/const';
import { Spin } from 'antd';
import { wsStatusHandle } from '@/plugin/ws';
import {
  setClientUserInfo,
  setCSRoomSetting,
  setCsRoomForm,
  setCSRoomQueueList,
} from '@/store/client';
import { setWsStatusInfo } from '@/store/subscribe';
import { useQueryApi, apiType, useFetch } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
import useBrowser from '@/hook/useBrowser';
import CenterLayout from '@/components/Layout/CenterLayout';
import { asyncLocalStorage, csRoomUrlParams } from '@/utils';
import useTrackingCode from '@/hook/useTrackingCode';

const enabled = val => val === 'Yes';

const handleBrowserTabStyle = ({ title, icon }) => {
  document.title = title;
  if (icon) {
    const oldLink = document.getElementById('favicon-link');
    const link = document.createElement('link');
    link.id = 'favicon-link';
    link.rel = 'shortcut icon';
    link.href = icon;

    oldLink && document.head.removeChild(oldLink);
    document.head.appendChild(link);
  }
};

function GlobalClient() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  /**
   * ws 狀態
   */
  const { wsStatusNotify } = useAlertMessage();
  const { wsStatusInfo } = useSelector(({ subscribe }) => subscribe);
  useEffect(() => {
    wsStatusNotify(wsStatusInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsStatusInfo]);

  /**
   * 諮前表單處理
   */
  const csRoomFormHandle = form => {
    const formSettingData = {
      title: form.title || '',
      formDataList: form.formData || [],
      isVerificationCode: form.isVerificationCode === 'Yes',
      isEnabled: form.isEnabled === 'Yes',
    };
    dispatch(setCsRoomForm(formSettingData));
  };

  /**
   * 諮詢房設定處理
   */
  const csRoomSettingHandle = setting => {
    const { header = {}, content = {}, banner = {} } = setting;

    const settingData = {
      headerLogoURL: enabled(header.isEnabled) ? header.logoURL || '' : '',
      headerBgColor: header.backgroundColor || '',
      contentLogoURL: enabled(header.contentLogoEnabled)
        ? header.contentLogoURL || []
        : [],
      contentLogoPosition: header.contentLogoPosition,

      bgImgURL: content.backgroundImageURL || '',
      bgColor: content.backgroundColor || '',
      boxBgColor: content.boxBackgroundColor || '',
      boxBgImgURL: content.boxBackgroundImageURL || '',

      bannerImgURL: enabled(banner.isEnabled) ? banner.imageURL || '' : '',
      bannerLinkURL: banner.linkUrl || '',

      beepURL: setting.beepURL || '',
      showBeepBtn: enabled(setting.beepEnabled),
      beepEnabled: enabled(setting.beepEnabled),
      queueEnabled: enabled(setting.queueEnabled),
      autoCreatedCSEnable: enabled(setting.enterDirectlyEnabled),
      redirectVerifyEnabled: enabled(setting.redirectVerifyEnabled),
    };
    handleBrowserTabStyle({
      title: setting.header.title,
      icon: setting.header.avatarURL,
    });
    dispatch(setCSRoomSetting(settingData));
  };

  /**
   * 排隊進線問題處理
   */
  const csRoomQueueQsListHandle = list => {
    dispatch(setCSRoomQueueList(list));
  };

  /**
   * 取得 諮詢房設定 + 詢前表單
   */
  const { fetchData: fetchCSRoomSettingAndForm } = useQueryApi(
    apiType.GET_CS_ROOM_SETTING_AND_FORM,
    {
      context: { isShowGeneralError: false },
      onSuccess: data => {
        try {
          const {
            getConsultingRoomClientSetting,
            getConsultingRoomForm,
            listConsultingRoomQuestion: { consultingRoomQuestions: queueList },
          } = data;
          csRoomSettingHandle(getConsultingRoomClientSetting || {});
          csRoomFormHandle(getConsultingRoomForm);
          csRoomQueueQsListHandle(queueList);

          wsStatusHandle(({ status, data }) => {
            dispatch(setWsStatusInfo({ status, data }));
          });

          setLoading(false);
        } catch (error) {
          console.log('fetchCSRoomSettingAndFormHandel', error);
        }
      },
    },
  );

  /**
   * 檢查 local 資訊
   */
  const processInit = async () => {
    let userInfo = await asyncLocalStorage.getItem('clientUserInfo');
    dispatch(setClientUserInfo(userInfo || {}));
    fetchCSRoomSettingAndForm(); // setting 資料
  };

  /**
   * 埋碼處理
   */
  const { fetchAdTrackings } = useTrackingCode();

  /**
   * 跳轉處理
   */
  const redirectURLHandle = useCallback((url = '', { token = '' }) => {
    let redirectURL = '';
    let paramsSymbol = url.indexOf('?') > 0 ? '&' : '?';
    const URL_SEARCH = window.location.search;
    try {
      if (URL_SEARCH) {
        // 將 token 以及其他 params 重新組合 url (因為其他客服會有不同 params)
        let urlSearchParams = new URLSearchParams(URL_SEARCH);
        urlSearchParams.set('token', token); // 賦值最新 token
        redirectURL = `${url}${paramsSymbol}${urlSearchParams.toString()}`;
      } else {
        redirectURL = `${url}${paramsSymbol}token=${token}`;
      }
      let a = document.createElement('a');
      a.href = redirectURL;
      a.target = '_self';
      a.click();
    } catch (error) {
      console.log('redirectURLHandle error', error);
    }
  }, []);

  const { fetchApi, loading: jumpLoading } = useFetch();
  const jumpHandle = async () => {
    try {
      let isJump = false;
      let tokenParams = '';

      // jump api url 組合
      tokenParams = csRoomUrlParams.token
        ? csRoomUrlParams.token
        : (await asyncLocalStorage.getItem('jumpToken')) || '';

      const fetchUrl = `${API_JUMP_URL}?token=${tokenParams}&sd=${csRoomUrlParams.sourceDomain}`;
      const { error, data } = await fetchApi(fetchUrl, {
        headers: { 'content-type': 'application/json' },
      });
      if (error) return isJump;
      const { isJump: resIsJump = false, token = '', url = '' } = data;
      await asyncLocalStorage.setItem('jumpToken', token); // local 上的 token
      if (resIsJump) redirectURLHandle(url, { token });
      isJump = resIsJump;
      return isJump;
    } catch (error) {
      console.log('jumpHandle error', error);
    }
  };

  // 瀏覽器檢查
  const { checkIsOldBrowser, checkIsAccessAllow } = useBrowser();
  useEffect(() => {
    const initCheck = () => {
      fetchAdTrackings();
      checkIsOldBrowser(close => {
        close && close();
        checkIsAccessAllow(async close => {
          close && close();
          const isJump = await jumpHandle();
          if (!isJump) processInit();
        });
      });
    };
    initCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CenterLayout>
      {jumpLoading || loading ? (
        <Spin spinning={jumpLoading || loading} />
      ) : (
        <Outlet />
      )}
    </CenterLayout>
  );
}

export default GlobalClient;
