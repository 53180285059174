/**
 * 詢前表單
 */
import React, {
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box } from '@WRAP_COMP';
import Captcha from 'react-captcha-code';
import { Form, Input, Radio, Checkbox, Button, Typography, Empty } from 'antd';
import { CSFormFieldType, CSFormFieldVerifyType, cloneDeep } from '@/utils';
/**
 * @param {Array} formDataList
 * @returns  formDataList
 */

function CSForm({
  formTitle = '',
  formDataList = [],
  showVerificationCode = false,
  isReadType = false,
  cusFormCom = null,
  updateQsHandel = () => {},
  submitHandle = () => {},
}) {
  /**
   * 更新 content
   *  */
  const updateContentHandle = (itemIdx, content) => {
    let tempFormDataList = cloneDeep(formDataList);
    tempFormDataList[itemIdx].content = content;
    updateQsHandel(itemIdx, tempFormDataList[itemIdx]);
  };

  /**
   * 特定 fieldType 驗證規則
   */
  const fieldTypeVerify = {
    [CSFormFieldVerifyType['Age']]: (name, rule) => ({
      name,
      rules: [
        { required: rule.require, message: '必填' },
        {
          validator(_, value) {
            if (!value || (Number(value) >= 1 && Number(value) <= 120)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('请输入正确的年龄'));
          },
        },
      ],
    }),
    [CSFormFieldVerifyType['Email']]: (name, rule) => ({
      name,
      rules: [
        { required: rule.require, message: '必填' },
        { type: 'email', message: '请输入正确格式的邮箱' },
      ],
    }),
    [CSFormFieldVerifyType['QQ']]: (name, rule) => ({
      name,
      rules: [
        { required: rule.require, message: '必填' },
        {
          validator(_, value) {
            if (!value || /^[1-9][0-9]{4,10}$/gi.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('请输入正确的5~11位QQ号码格式'));
          },
        },
      ],
    }),
    [CSFormFieldVerifyType['WeChat']]: (name, rule) => ({
      name,
      rules: [
        { required: rule.require, message: '必填' },
        {
          validator(_, value) {
            if (!value || /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/gi.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('请输入正确的6~20位微信号码格式'));
          },
        },
      ],
    }),
  };
  const verifyTypeHandle = (name, rule) => {
    if (fieldTypeVerify[rule.verifyType]) {
      return fieldTypeVerify[rule.verifyType](name, rule);
    }
    return {
      name,
      rules: [{ required: rule.require, message: '必填' }],
    };
  };

  /**
   * Input type
   */
  const onChangeInput = (e, content, ItemIdx) => {
    const inputValue = e.target.value;
    let tempContent = cloneDeep(content);
    tempContent.value = inputValue;
    updateContentHandle(ItemIdx, tempContent);
  };
  const inputRender = (el, idx) => {
    return (
      <Form.Item
        key={el.id}
        label={el.label}
        {...verifyTypeHandle(el.id, el.rule)}>
        <Input
          placeholder='请输入'
          disabled={isReadType}
          onChange={e => onChangeInput(e, el.content, idx)}
        />
      </Form.Item>
    );
  };

  /**
   * Radio 單選 type
   */
  const onChangeRadio = (e, content, ItemIdx) => {
    const clickIdx = e.target.value;
    let tempContent = cloneDeep(content);
    tempContent.items.forEach((it, i) => (it.value = i === clickIdx));
    updateContentHandle(ItemIdx, tempContent);
  };
  const radioRender = (el, idx) => {
    return (
      <Form.Item
        key={el.id}
        label={el.label}
        {...verifyTypeHandle(el.id, el.rule)}>
        <Radio.Group
          onChange={e => onChangeRadio(e, el.content, idx)}
          disabled={isReadType}>
          {el.content.items.map((item, itemIdx) => (
            <Radio key={itemIdx} value={itemIdx}>
              {item.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  };

  /**
   * Checkbox 多選 type
   */
  const onChangeCheckbox = (e, clickIdx, content, ItemIdx) => {
    let tempContent = cloneDeep(content);
    tempContent.items[clickIdx].value = e.target.checked;
    updateContentHandle(ItemIdx, tempContent);
  };
  const checkboxRender = (el, idx) => {
    return (
      <Form.Item
        key={el.id}
        label={el.label}
        {...verifyTypeHandle(el.id, el.rule)}>
        <Checkbox.Group disabled={isReadType}>
          {el.content.items.map((item, itemIdx) => (
            <Checkbox
              key={itemIdx}
              value={itemIdx}
              onClick={e => onChangeCheckbox(e, itemIdx, el.content, idx)}>
              {item.label}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
    );
  };

  /**
   * 各種 fieldType 渲染
   */
  const fieldTypeRender = {
    [CSFormFieldType['Input']]: (el, idx) => inputRender(el, idx),
    [CSFormFieldType['Radio']]: (el, idx) => radioRender(el, idx),
    [CSFormFieldType['Checkbox']]: (el, idx) => checkboxRender(el, idx),
  };
  const formDataListRender = list => {
    return (
      <>
        {list.map((el, idx) => {
          if (fieldTypeRender[el.type]) {
            return fieldTypeRender[el.type](el, idx);
          } else {
            console.log('no mapping type > ', el);
            return null;
          }
        })}
      </>
    );
  };

  /**
   * 驗證碼
   */
  const captchaInputEl = useRef(null);
  const captchaRef = useRef(null);
  const charNum = 4;
  const [captchaCode, setCaptchaCode] = useState('');
  const [captchaError, setCaptchaError] = useState({
    validateStatus: '',
    help: '',
  });

  const clickChangeCaptcha = () => {
    captchaRef.current.refresh();
    setCaptchaCode();
  };

  useEffect(() => {
    if (showVerificationCode) {
      const array = [];
      for (let i = 0; i < charNum; i++) {
        const temp = Math.floor(Math.random() * 9);
        array.push(temp);
      }
      setCaptchaCode(array.join(''));
    }
  }, [showVerificationCode]);

  /**
   * 送出表單
   */
  const [CSForm] = Form.useForm();
  const clickSubmit = async () => {
    const fields = await CSForm.validateFields();

    // 檢查驗證碼
    if (showVerificationCode) {
      if (captchaInputEl.current.state.value !== captchaCode) {
        setCaptchaError({ validateStatus: 'error', help: '验证码错误' });
        return;
      } else {
        setCaptchaError({ validateStatus: '', help: '' });
      }
    }
    submitHandle({ ...fields, code: captchaCode });
  };

  /**
   * isReadType 的初始值
   */
  const fieldTypeInit = useMemo(
    () => ({
      [CSFormFieldType['Input']]: content => content.value,
      [CSFormFieldType['Radio']]: content => {
        let value = '';
        content.items.forEach((m, idx) => m.value && (value = idx));
        return value;
      },
      [CSFormFieldType['Checkbox']]: content => {
        let checkIdx = [];
        content.items.forEach((m, idx) => m.value && checkIdx.push(idx));
        return checkIdx;
      },
    }),
    [],
  );

  const initCSForm = useCallback(() => {
    let initValueObj = {};
    formDataList.forEach(el => {
      initValueObj[el.id] = fieldTypeInit[el.type](el.content);
    });
    CSForm.setFieldsValue(initValueObj);
  }, [formDataList, CSForm, fieldTypeInit]);

  useEffect(() => {
    if (isReadType) initCSForm();
  }, [isReadType, formDataList, initCSForm]);

  if (isReadType && !formDataList.length) return <Empty description={false} />;
  return (
    <>
      <Typography.Title level={4}>{formTitle}</Typography.Title>
      <Form form={CSForm} layout='vertical'>
        {formDataListRender(formDataList)}
      </Form>
      {cusFormCom}
      {showVerificationCode && (
        <>
          <Form layout='vertical'>
            <Form.Item
              {...captchaError}
              style={{ marginBottom: '8px' }}
              label='请输入验证码'
              name='captcha'
              rules={[{ required: true, message: '必填' }]}>
              <Input ref={captchaInputEl} placeholder='请输入' />
            </Form.Item>
          </Form>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='flex-end'
            mb='3'>
            <Captcha
              code={captchaCode}
              ref={captchaRef}
              charNum={charNum}
              onChange={code => setCaptchaCode(code)}
              height={50}
              width={200}
              fontSize={40}
            />
            <Button type='link' onClick={clickChangeCaptcha}>
              更换验证码
            </Button>
          </Box>
        </>
      )}

      {!isReadType && (
        <Button type='primary' block onClick={clickSubmit}>
          确认送出
        </Button>
      )}
    </>
  );
}

export default React.memo(CSForm);
