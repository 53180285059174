import { ThemeProvider } from 'styled-components';
const { theme } = require('@/plugin/theme');

const CusThemeProvider = ({ children, ...props }) => {
  return (
    <ThemeProvider theme={theme} {...props}>
      {children}
    </ThemeProvider>
  );
};

export { CusThemeProvider };
