import { gql } from '@apollo/client';
import { CS_FORM_FIELD } from './Fragment';
/**
 * 詢前表單
 */
export const GET_CS_FORM = gql`
  ${CS_FORM_FIELD}
  query getConsultingRoomForm($filter: ConsultingRoomFormFilterInput!) {
    getConsultingRoomForm(filter: $filter) {
      ...CSForm
    }
  }
`;
export const CREATE_CS_FORM = gql`
  ${CS_FORM_FIELD}
  mutation createConsultingRoomForm($in: ConsultingRoomFormCreateInput!) {
    createConsultingRoomForm(in: $in) {
      ...CSForm
    }
  }
`;
export const UPDATE_CS_FORM = gql`
  ${CS_FORM_FIELD}
  mutation updateConsultingRoomForm(
    $filter: ConsultingRoomFormFilterInput!
    $in: ConsultingRoomFormUpdateInput!
  ) {
    updateConsultingRoomForm(filter: $filter, in: $in) {
      ...CSForm
    }
  }
`;
export const DELETE_CS_FORM = gql`
  ${CS_FORM_FIELD}
  mutation deleteConsultingRoomForm($filter: ConsultingRoomFormFilterInput!) {
    deleteConsultingRoomForm(filter: $filter) {
      ...CSForm
    }
  }
`;
