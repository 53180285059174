import React from 'react';
import MsgInfoWrap from '@/components/Msg/MsgInfoWrap';
const { theme } = require('@/plugin/theme');

function CusUserMsg({ txt = '' }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <MsgInfoWrap bgColor={theme.primary} color={theme.white}>
        {txt}
      </MsgInfoWrap>
    </div>
  );
}

export default CusUserMsg;
