import React from 'react';
import { Navigate } from 'react-router-dom';
import GlobalClient from 'src/views/GlobalClient';
import Client from 'src/views/Client';

export const client = () => {
  return [
    {
      key: 'Client',
      path: '',
      element: <GlobalClient />,
      children: [
        { path: '/', element: <Client /> },
        { path: '*', element: <Navigate to='/' /> },
      ],
    },
  ];
};
