import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from '@WRAP_COMP';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';

const BoxWrap = styled(Box)`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`;
function CenterLayout({ children }) {
  const handleResize = () => {
    let vh = window.innerHeight * 0.01;
    document
      .getElementById('CenterLayout')
      .style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <BoxWrap
      id='CenterLayout'
      display='flex'
      alignItems='center'
      justifyContent='center'
      width='100vw'
      bgColor='gray-1'>
      {children || <Outlet />}
    </BoxWrap>
  );
}

CenterLayout.propTypes = {
  bg: PropTypes.string,
  pd: PropTypes.string,
  center: PropTypes.bool,
};

export default CenterLayout;
