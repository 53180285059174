import React from 'react';
import { Spin, Typography } from 'antd';
import { Box } from '@WRAP_COMP';
import { IS_CLIENT } from '@/const';
import { useMutationApi, apiType } from '@/hook/useApi';
import useAlertMessage from '@/hook/useAlertMessage';
// import { PayType } from '@/utils';
import PayIcons from './Icons';

function PayList({ roomID, amount, payList = [] }) {
  const { onErrorMsg } = useAlertMessage();
  const [postDeposit, { loading }] = useMutationApi(apiType.deposit, {
    context: { isShowGeneralError: false },
    onError: err => {
      const msgType = {
        400000: () => onErrorMsg('银行卡抽取失败，请联系客服'),
        400002: () => onErrorMsg('已有订单进行中'),
        402001: () => onErrorMsg('取消次数过多，请完成充值'),
        500002: () => onErrorMsg('呼叫第三方失败，请联系客服人员'),
      };

      msgType[err.message] ? msgType[err.message]() : onErrorMsg(err.message);
    },
  });

  const clickPayment = params => () => {
    IS_CLIENT
      ? postDeposit({ in: { roomID, ...params } })
      : onErrorMsg('无法操作');
  };

  return (
    <>
      <Spin spinning={loading}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'>
          <Box
            width='100%'
            textAlign='center'
            borderBottom='1px solid'
            borderCr='#f2f2f2'
            mb='2'
            pb='1'>
            <Typography.Text style={{ fontSize: '14px' }}>
              支付金額：
              <span style={{ color: '#3582db', fontWeight: 'bold' }}>
                ¥ {amount || 0}
              </span>
            </Typography.Text>
          </Box>
          <Typography.Text
            style={{ fontSize: '12px', color: '#aaaaaa', marginBottom: '5px' }}>
            欢迎使用官方充值代理，请选择你的充值方式
          </Typography.Text>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            mt='2'
            width='100%'>
            {payList.map(({ channelID, payType }) =>
              // PayType[payType]
              payType === 'bank' ? (
                <Box
                  key={channelID}
                  onClick={clickPayment({ amount, channelID })}
                  mx={2}
                  mb={1}>
                  <PayIcons payType={payType} />
                </Box>
              ) : null,
            )}
          </Box>
        </Box>
      </Spin>
    </>
  );
}

export default PayList;
