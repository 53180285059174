import { useQueryApi, apiType } from '@/hook/useApi';
import { sentryCaptureErr } from '@/plugin/sentry';

function useTrackingCode() {
  // const srcReg = /src=['"]?([^'"]*)['"]?/i;
  // const trackingCodeType = {
  //   [AdTrackingType['Google']]: codStr => {
  //     const divEl = document.createElement('div');
  //     divEl.innerHTML = codStr;
  //     divEl.childNodes.forEach(node => {
  //       if (node.nodeType === 1) {
  //         const newScript = document.createElement('script');
  //         node.src && (newScript.src = node.src);
  //         node.src && (newScript.async = true);
  //         node.innerHTML && (newScript.innerHTML = node.innerHTML);
  //         document.head.appendChild(newScript);
  //       }
  //     });
  //   },
  //   [AdTrackingType['CNZZ']]: codStr => {
  //     const divEl = document.createElement('div');
  //     divEl.innerHTML = codStr;
  //     console.log('childNodes', divEl.childNodes);
  //     // const script = document.createElement('script');
  //     // script.src =
  //     //   'https://s4.cnzz.com/z_stat.php?id=1281137078&web_id=1281137078';
  //     // document.body.appendChild(script);
  //   },

  //   [AdTrackingType['Baidu']]: codStr => {
  //     const script = document.createElement('script');
  //     script.src = codStr;
  //     document.head.appendChild(script);
  //   },
  // };

  const trackingCodeAppendHandle = codStr => {
    try {
      const divEl = document.createElement('div');
      divEl.innerHTML = codStr;
      divEl.childNodes.forEach(node => {
        if (node.nodeType === 1) {
          const newScript = document.createElement('script');
          node.src && (newScript.src = node.src);
          node.src && (newScript.async = true);
          node.innerHTML && (newScript.innerHTML = node.innerHTML);
          document.head.appendChild(newScript);
        }
      });
    } catch (error) {
      sentryCaptureErr(error, { func: 'trackingCodeAppendHandle' });
    }
  };

  const { fetchData } = useQueryApi(apiType.LIST_AD_TRACKING, {
    context: { isShowGeneralError: false },
  });
  const fetchAdTrackings = async () => {
    const { error, data } = await fetchData({
      filter: { isClient: true },
    });
    if (error) return;
    const adTrackings = data?.listAdTracking?.adTrackings || [];
    adTrackings.forEach(el => trackingCodeAppendHandle(el.adTrackingCode));
  };

  return { fetchAdTrackings };
}

export default useTrackingCode;
