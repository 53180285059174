import { gql } from '@apollo/client';

// 標籤列表
export const LIST_TAGS = gql`
  query listTag($filter: TagFilterInput!) {
    listTag(filter: $filter) {
      tags {
        id
        name
        RGBHex
        isEnable
        createdAt
        updatedAt
        createUserID
        updateUserID
      }
      meta {
        total
      }
    }
  }
`;

// 標籤明細
export const GET_TAG = gql`
  query getTag($filter: TagFilterInput!) {
    getTag(filter: $filter) {
      id
      name
      RGBHex
      isEnable
      createdAt
      updatedAt
      createUserID
      updateUserID
    }
  }
`;

// 新增標籤
export const CREATE_TAG = gql`
  mutation createTag($in: TagCreateInput!) {
    createTag(in: $in)
  }
`;

// 更新標籤
export const UPDATE_TAG = gql`
  mutation updateTag($filter: TagFilterInput!, $in: TagUpdateInput!) {
    updateTag(filter: $filter, in: $in)
  }
`;

// 刪除標籤
export const DELETE_TAG = gql`
  mutation deleteTag($filter: TagFilterInput!) {
    deleteTag(filter: $filter)
  }
`;
