import React from 'react';
import { Box } from '@/plugin/wrap-comp';
import { MsgContentTypeKeys } from '@/utils';

const arr = ['是的', '需要', '好的', '支付宝', '微信'];

const h = '30px';

function QuickMsgTab({ pushMsg = () => {} }) {
  return (
    <Box height={h} overflow='hidden'>
      <Box
        display='flex'
        flexWrap='no-wrap'
        overflowX='auto'
        style={{ paddingBottom: '12px', cursor: 'pointer' }}>
        {arr.map((item, idx) => (
          <Box
            key={idx}
            height={h}
            flex='0 0 auto'
            ml='3'
            px='3'
            color='#7F7F7F'
            bgColor='white'
            display='flex'
            justifyContent='center'
            alignItems='center'
            borderRadius='30px'
            onClick={() =>
              pushMsg({
                id: Date.now(),
                contentType: MsgContentTypeKeys['Text'],
                text: { text: item, isEncrypt: false },
              })
            }>
            {item}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default QuickMsgTab;
