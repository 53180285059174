import { createSlice } from '@reduxjs/toolkit';
/**
 * 存放 訂閱內容 ws狀態
 */
const initialState = {
  recMsg: null,
  wsStatusInfo: { status: '', data: {} },
};

export const authSlice = createSlice({
  name: 'subscribe',
  initialState,
  reducers: {
    setRecMsg: (state, { payload }) => {
      // console.log('recMsg===', payload);
      state.recMsg = payload;
    },
    setWsStatusInfo: (state, { payload }) => {
      state.wsStatusInfo = payload;
    },
  },
});

export const { setRecMsg, setWsStatusInfo } = authSlice.actions;
export default authSlice.reducer;
