import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMeInfo, setAuthRoutesInfo, setAuthRoutesKey } from '@/store/auth';
import { useQueryApi, apiType } from '@/hook/useApi';
import { AccountTypeKeys } from '@/utils';

/**
 * @returns {Function} fetchMeHandler
 */
const useMe = () => {
  const { meInfo, authRoutesKey, authRoutesInfo } = useSelector(
    ({ auth }) => auth,
  );
  const isSystemType = meInfo.accountType === AccountTypeKeys['System'];
  const isMonitorType = meInfo.accountType === AccountTypeKeys['Monitor'];

  /**
   * 根據 arr 組出 keys
   * @param {Array} arr
   * @returns {Array} keys
   */
  const authorityKeysHandle = useCallback(arr => {
    return arr.reduce((acc, cur) => {
      return acc.concat(
        Array.isArray(cur.next)
          ? [...authorityKeysHandle(cur.next), cur.key]
          : cur.key,
      );
    }, []);
  }, []);

  /**
   * 取得 me 資訊
   * @returns {Object} { data 使用者資訊, error 錯誤訊息 }
   */
  const dispatch = useDispatch();
  const { fetchData } = useQueryApi(apiType.GET_ME);
  const fetchMeHandler = useCallback(async () => {
    const { data, error } = await fetchData();
    if (error) return { error };
    const { menu = [], ...meInfo } = data.Me;
    const authKeys = authorityKeysHandle(menu);
    dispatch(setMeInfo(meInfo));
    dispatch(setAuthRoutesInfo(menu));
    dispatch(setAuthRoutesKey(authKeys));
    return { data: { ...data.Me, authKeys } };
  }, [authorityKeysHandle, dispatch, fetchData]);

  return {
    meInfo,
    authRoutesKey,
    authRoutesInfo,
    isSystemType,
    isMonitorType,
    fetchMeHandler,
    authorityKeysHandle,
  };
};
export default useMe;
