import { gql } from '@apollo/client';

export const AD_TRACKING_FIELD = gql`
  fragment adTrackingField on AdTracking {
    id
    consultingRoomOriginID
    origin
    adTrackingType
    adTrackingCode
    createdAt
    updatedAt
  }
`;

/**
 *  廣告代碼
 */
export const LIST_AD_TRACKING = gql`
  ${AD_TRACKING_FIELD}
  query listAdTracking(
    $filter: AdTrackingFilterInput
    $pagination: PaginationInput
  ) {
    listAdTracking(filter: $filter, pagination: $pagination) {
      adTrackings {
        ...adTrackingField
      }
      meta {
        total
      }
    }
  }
`;

export const GET_AD_TRACKING = gql`
  ${AD_TRACKING_FIELD}
  query getAdTracking($filter: AdTrackingFilterInput!) {
    getAdTracking(filter: $filter) {
      ...adTrackingField
    }
  }
`;

export const CREATE_AD_TRACKING = gql`
  ${AD_TRACKING_FIELD}
  mutation createAdTracking($in: AdTrackingCreateInput!) {
    createAdTracking(in: $in) {
      ...adTrackingField
    }
  }
`;

export const UPDATE_AD_TRACKING = gql`
  ${AD_TRACKING_FIELD}
  mutation updateAdTracking(
    $filter: AdTrackingFilterInput!
    $in: AdTrackingUpdateInput!
  ) {
    updateAdTracking(filter: $filter, in: $in) {
      ...adTrackingField
    }
  }
`;

export const DELETE_AD_TRACKING = gql`
  mutation deleteAdTracking($filter: AdTrackingFilterInput!) {
    deleteAdTracking(filter: $filter)
  }
`;
