import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

/**
 * level >> ['log', 'info', 'warn', 'error', 'debug', 'assert']
 * https://github.com/getsentry/sentry-electron/issues/90#issuecomment-425552746
 */

export const initSentry = () => {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV,
    dsn: 'https://8745fa511f0443989c38589ae0dd8e0e@o28588.ingest.sentry.io/5860240',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_VERSION,
    ignoreErrors: [
      'ReferenceError',
      'window.bannerNight',
      'Failed to fetch',
      'ResizeObserver loop',
      'fullwide',
      'Loading chunk',
    ],
  });
};

export const sentryCaptureErr = (
  errorTitle = '',
  extraObj = { details: 'This is some extra information' },
  level = 'error',
) => {
  Sentry.withScope(scope => {
    Object.keys(extraObj).forEach(key => {
      scope.setExtra(key, extraObj[key]);
    });
    scope.setLevel(level);
    Sentry.captureMessage(errorTitle);
  });
};

export const testErrorBtn = () => {
  const throwMessage = () => {
    Sentry.captureMessage('[TEST] This is a log5 message', 'log');
    // Sentry.captureMessage('this is a info message', 'info');
    // Sentry.captureMessage('this is a warn message', 'warn');
    // Sentry.captureMessage('this is a error message', 'error');
    // Sentry.captureMessage('this is a debug message', 'debug');
    // Sentry.captureMessage('this is a assert message', 'assert');
  };

  return <button onClick={throwMessage}>throw error</button>;
};
