import { gql } from '@apollo/client';

// 更新目前進入的房間的未讀訊息
export const UPDATE_IN_ROOM = gql`
  mutation updateInRoomID($roomID: Uint64!) {
    updateInRoomID(roomID: $roomID)
  }
`;

// 更新最後在聊天室讀取的訊息
export const UPDATE_LAST_READ_MSG = gql`
  mutation updateLastReadMessageID($roomID: Uint64!, $messageID: String!) {
    updateLastReadMessageID(roomID: $roomID, messageID: $messageID)
  }
`;
