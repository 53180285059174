import { useCallback } from 'react';
import useAlertMessage from './useAlertMessage';
import { useFetch } from './useApi';
import { CLIENT_API_DOMAIN, IS_CLIENT } from '@/const';
const domain = IS_CLIENT ? CLIENT_API_DOMAIN : process.env.REACT_APP_API_ORIGIN;

/**
 * @returns {Function} getBase64 轉 Base64
 * @returns {Function} getDownloadFile 下載檔案
 */
const useFile = () => {
  const { fetchApi, loading } = useFetch();
  const { onErrorMsg } = useAlertMessage();

  const getFileUrl = useCallback(file => {
    return URL.createObjectURL(file);
  }, []);

  /**
   * File 轉 Base64
   * @param {*} file
   */
  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  /**
   * Img 轉 Base64
   * @param {*} img
   */
  const getBase64Image = img => {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
    var dataURL = canvas.toDataURL('image/' + ext);
    return dataURL;
  };

  /**
   * 下載圖片
   * @param {String} fileUrl
   * @param {String} userID
   * @returns {String || Object} 返回 Blob url
   */
  const getDownloadFile = useCallback(
    async (fileUrl, userID) => {
      try {
        const { error, data: blob } = await fetchApi(`${domain}${fileUrl}`, {
          resType: 'blob',
          headers: { 'content-type': 'image/png' },
        });
        if (error) {
          onErrorMsg('图片下载失败');
          return { error };
        }
        let resData = null;
        const objectURL = URL.createObjectURL(blob);
        resData = userID ? { userID, objectURL } : objectURL;
        return { data: resData };
      } catch (error) {
        console.log('getDownloadFile error', error);
      }
    },
    [fetchApi, onErrorMsg],
  );

  /**
   * 下載多張圖片
   * @param {Array} [{ userID, avatarURL }]
   * @returns
   */
  const getDownloadFiles = useCallback(
    (avatarsInfo = []) => {
      return Promise.all(
        avatarsInfo.map(item => getDownloadFile(item.avatarURL, item.userID)),
      );
    },
    [getDownloadFile],
  );

  /**
   * 檢查是否為 blob 字串
   * @param {String}
   * @returns {Boolean}
   */
  const checkBlobStr = useCallback(str => {
    return str ? str.includes('blob:') : '';
  }, []);

  /**
   * 檢查 是否為圖片 image/*
   */
  const checkIsFileImage = useCallback(file => {
    return file && file['type'].split('/')[0] === 'image';
  }, []);

  /**
   * 判斷是否為 file 檔案，是的話返回 Url
   */
  const changeFileToUrl = useCallback(
    val => {
      if (val instanceof File) {
        console.log('is file');
        return getFileUrl(val);
      }
      return val;
    },
    [getFileUrl],
  );

  /**
   * 將 url 轉成 File 物件
   */
  const changeUrlToFile = useCallback(async url => {
    if (typeof url !== 'string') return url;

    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
        let base64 = getBase64Image(img);
        let file = btof(base64, 'test');
        resolve(file);
      };
      img.onerror = reject;
      img.setAttribute('crossOrigin', 'Anonymous');
      const timestamp = new Date().getTime();
      img.src = `${url}?timestamp=${timestamp}`;
    });
  }, []);

  function btof(data, fileName) {
    const dataArr = data.split(',');
    const byteString = atob(dataArr[1]);

    const options = {
      type: 'image/png',
      endings: 'native',
    };
    const u8Arr = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      u8Arr[i] = byteString.charCodeAt(i);
    }
    return new File([u8Arr], fileName + '.png', options);
  }
  // const changeUrlToFile = useCallback(
  //   async url => {
  //     if (typeof url === 'string') {
  //       try {
  //         const { error, data: blob } = await fetchApi(`url`, {
  //           resType: 'blob',
  //           headers: { 'content-type': 'image/*' },
  //         });
  //         if (error) {
  //           onErrorMsg('图片下载失败');
  //           return '';
  //         }
  //         let imgFile = new File([blob], 'fileName.png', { type: 'image/png' });
  //         console.log('imgFile', imgFile);
  //         console.log('imgFile URL', URL.createObjectURL(imgFile));

  //         return imgFile;
  //       } catch (error) {
  //         console.log('changeUrlToFile', changeUrlToFile);
  //       }
  //     }
  //     return url;
  //   },
  //   [fetchApi, onErrorMsg],
  // );

  return {
    loading,
    getFileUrl,
    getBase64,
    getDownloadFile,
    getDownloadFiles,
    checkBlobStr,
    checkIsFileImage,
    changeFileToUrl,
    changeUrlToFile,
  };
};

export default useFile;
