import { configureStore } from '@reduxjs/toolkit';
import global from './global';
import auth from './auth';
import client from './client';
import room from './room';
import roomMsg from './roomMsg';
import subscribe from './subscribe';

// 創建 store
const configureAppStore = () => {
  const store = configureStore({
    reducer: {
      global,
      auth,
      client,
      room,
      roomMsg,
      subscribe,
    },
  });
  return store;
};

const store = configureAppStore();
export default store;
