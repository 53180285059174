import { decode } from './storage';

const handleDecode = name => (URL_PARAMS[name] ? decode(URL_PARAMS[name]) : '');
const URL_SEARCH = window.location.search;
const URL_SEARCH_PARAMS = new URLSearchParams(URL_SEARCH);
const URL_PARAMS = Object.fromEntries(URL_SEARCH_PARAMS.entries());
const autoContact = URL_PARAMS.contact || '';
const roomType = URL_PARAMS.roomType
  ? URL_PARAMS.roomType.replace(/^./, URL_PARAMS.roomType[0].toUpperCase())
  : '';
const username = handleDecode('userName') || handleDecode('username');
const realName = handleDecode('realName'); // 真實姓名
const reqUrl = handleDecode('reqUrl');
const backUrl = handleDecode('backUrl'); // 返回鍵要返回的路由
const depositToken = URL_PARAMS.depositToken || '';
const depositAmount = URL_PARAMS.depositAmount || 0; // 充值金額
const payType = URL_PARAMS.payType || ''; // 支付類型
const jobID = URL_PARAMS.jobID || ''; // 返利類型 非必填
const sourceDomain = reqUrl ? reqUrl.substring(reqUrl.indexOf('://') + 3) : ''; // 來源域名

export const csRoomUrlParams = {
  ...URL_PARAMS,
  roomType,
  username,
  realName,
  reqUrl,
  backUrl,
  depositToken,
  depositAmount,
  payType,
  jobID,
  sourceDomain,
  autoContact,
};
