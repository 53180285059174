import { createSlice } from '@reduxjs/toolkit';
import { getStorage, setStorage } from '@/utils';

/**
 * 存放全局相關
 */

const globalStorageKey = {
  loginInfo: 'loginInfo',
  errorInfo: 'errorInfo',
};

const initialState = {
  isLogin: getStorage(globalStorageKey.loginInfo)?.token ? true : false,
  loginInfo: getStorage(globalStorageKey.loginInfo) || {},
  loading: false,
  menuList: [],
  // controller
  visibleDrawer: { name: '', visible: false },
  beepEnabled: false,
  beepAudioSrc: '',
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsLogin: (state, { payload }) => {
      state.isLogin = payload;
    },

    setLoginInfo: (state, { payload }) => {
      setStorage(globalStorageKey.loginInfo, payload);
      state.loginInfo = payload;
    },

    setLogout: state => {
      // 保留 deviceUID
      const deviceUID = getStorage(globalStorageKey.loginInfo)?.deviceUID || '';
      const storage = deviceUID ? { deviceUID } : null;
      setStorage(globalStorageKey.loginInfo, storage);
      state.isLogin = false;
    },

    setGLoading: (state, { payload }) => {
      state.loading = payload;
    },

    setMenuList: (state, { payload }) => {
      state.menuList = payload;
    },

    setVisibleDrawer: (state, { payload }) => {
      state.visibleDrawer = payload;
    },
    setBeepEnabled: (state, { payload }) => {
      state.beepEnabled = payload;
    },
    setBeepAudioSrc: (state, { payload }) => {
      state.beepAudioSrc = payload;
    },
  },
});

export const {
  setIsLogin,
  setLoginInfo,
  setLogout,
  setGLoading,
  setMenuList,
  setVisibleDrawer,
  setBeepEnabled,
  setBeepAudioSrc,
} = globalSlice.actions;
export default globalSlice.reducer;
