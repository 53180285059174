import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@WRAP_COMP';
/**
 * 對話訊息 邀請,加入,離開 顯示用
 * @param {Object} param0
 */
const MsgControlItem = ({ text = '', bgColor = 'white' }) => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' mb='2'>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        color='gray-3'
        bgColor={bgColor}
        py='1'
        px='3'
        borderRadius='30px'>
        {text}
      </Box>
    </Box>
  );
};

MsgControlItem.propTypes = {
  text: PropTypes.string,
  bgColor: PropTypes.string,
};
export default MsgControlItem;
