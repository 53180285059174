/**
 * 主題
 */
const theme = {
  primary: '#6993FF',
  'primary-light': '#D8E3FF',
  secondary: '#93B4D4',
  'secondary-light': '#e3f2ff',
  success: '#2AC5BD',
  'success-light': '#B7F5F2',
  warning: '#FBA801',
  'warning-light': '#FFF1D5',
  danger: '#F64E60',
  'danger-light': '#FCC7CD',
  white: '#fff',
  black: '#475F7B',
  'gray-0': '#F7F9FB',
  'gray-1': '#EEF3F9',
  'gray-2': '#D5DFEA',
  'gray-3': '#9AAABD',
  comp: {
    headerHeight: '50px',
  },
};

/**
 * 覆蓋 antd theme
 */
const AntTheme = {
  '@black': theme.black,
  '@primary-color': theme.primary,
};

module.exports = { AntTheme, theme };
