import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Skeleton } from 'antd';
import useFile from '@/hook/useFile';
const { theme } = require('@/plugin/theme');

/**
 * 顯示 大頭貼
 * 顯示：丟入 avatarURL 獲取有效頭像 src
 */
const AvatarFetch = ({ avatarURL = '', size = 'default', ...props }) => {
  const [src, setSrc] = useState('');
  const { getDownloadFile, loading } = useFile();
  const getDownloadFileHandle = useCallback(
    async url => {
      const { error, data } = await getDownloadFile(url);
      if (error) return;
      setSrc(data);
    },
    [getDownloadFile],
  );

  useEffect(() => {
    if (avatarURL) {
      avatarURL.includes('static') || avatarURL.includes('data:image')
        ? setSrc(avatarURL)
        : getDownloadFileHandle(avatarURL);
    }
    return () => {
      setSrc('');
    };
  }, [avatarURL, getDownloadFileHandle]);

  if (loading) return <Skeleton.Avatar active={loading} size={size} />;
  return (
    <>
      <Avatar
        src={src}
        icon={<UserOutlined />}
        style={{ background: theme['gray-2'] }}
        size={size}
        {...props}
      />
    </>
  );
};

AvatarFetch.propTypes = {
  avatarURL: PropTypes.string,
  size: PropTypes.string,
};
export default AvatarFetch;
