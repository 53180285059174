import React from 'react';
import { Badge } from 'antd';
import { Button, Box } from '@WRAP_COMP';
import { DownOutlined } from '@ant-design/icons';
const { theme } = require('@/plugin/theme');

const ScrollDownBtn = ({
  show = false,
  hasNewMsg = false,
  onClick = () => {},
}) => {
  if (!show) return null;
  return (
    <Box position='absolute' zIndex='1' right='15px' bottom='15px'>
      <Badge dot={hasNewMsg} offset={[0, 4]}>
        <Button
          shape='circle'
          icon={<DownOutlined />}
          onClick={() => onClick()}
          style={{
            background: theme['gray-1'],
            border: `1px solid ${theme['gray-3']}`,
          }}
        />
      </Badge>
    </Box>
  );
};
export default ScrollDownBtn;
