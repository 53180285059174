import { SubscriptionClient } from 'subscriptions-transport-ws'; // 使用 subscription
import { WebSocketLink } from '@apollo/client/link/ws';
import { WS_URI } from '@/const';
// import { sentryCaptureErr } from './sentry';

// 執行 close
const originalClose = SubscriptionClient.prototype.close;
SubscriptionClient.prototype.close = function close(...args) {
  if (this.client) {
    this.client.onopen = null;
    this.client.onclose = null;
    this.client.onerror = null;
    this.client.onmessage = null;
  }
  originalClose.apply(this, args);
};
export const wsClient = new SubscriptionClient(WS_URI, {
  reconnect: true,
});
export const wsLink = new WebSocketLink(wsClient);

export const wsStatusHandle = (callback = () => {}) => {
  wsClient.onError(error => {
    callback({ status: 'onError' });
    // sentryCaptureErr(error);
    wsClient.client &&
      (wsClient.client.onclose = function (e) {
        callback({
          status: 'onClose',
          data: { code: e.code || '', reason: e.reason },
        });
        // sentryCaptureErr('ws onClose', { code: e.code || '', reason: e.reason });
      });
  });

  wsClient.onConnecting(e => {
    console.log('onConnecting', e);
    callback({ status: 'onConnecting', data: e });
  });
  wsClient.onConnected(e => {
    console.log('onConnected', e);
    callback({ status: 'onConnected', data: e });
  });
  wsClient.onReconnecting(e => {
    console.log('onReconnecting', e);
    callback({ status: 'onReconnecting', data: e });
  });
  wsClient.onReconnected(e => {
    console.log('onReconnected', e);
    callback({ status: 'onReconnected', data: e });
  });
  wsClient.onDisconnected(e => {
    console.log('onDisconnected', e);
    callback({ status: 'onDisconnected', data: e });
  });
};
