import React, {
  lazy,
  Suspense,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { SendOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { Button, Box, Space } from '@/plugin/wrap-comp';
import { IS_CLIENT } from '@/const';
import useDragDropFiles, { DragInDrop } from '@/hook/useDragDropFiles';
import useAlertMessage from '@/hook/useAlertMessage';
import useFile from '@/hook/useFile';
import useRWD from '@/hook/useRWD';
import { MsgContentTypeKeys, stripHtmlTag, lazyRetry } from '@/utils';
// import AudioModal from '@/components/Modal/Audio';
import FrontDrop from './FrontDrop';
import InputTextArea from '@/components/InputTextArea';

const PreviewFileModal = lazy(() =>
  lazyRetry(
    () =>
      import(/*webpackChunkName: "preview-file-modal" */ './PreviewFileModal'),
    'PreviewFileModal',
  ),
);
const InputPayment = lazy(() =>
  lazyRetry(
    () => import(/*webpackChunkName: "payment-input" */ './InputPayment'),
    'InputPayment',
  ),
);
const BtnEmojiPicker = lazy(() =>
  lazyRetry(
    () => import(/*webpackChunkName: "emoji-picker" */ './BtnEmojiPicker'),
    'BtnEmojiPicker',
  ),
);
const BtnUploadFile = lazy(() =>
  lazyRetry(
    () => import(/*webpackChunkName: "upload-file" */ './BtnUploadFile'),
    'BtnUploadFile',
  ),
);

const InputBar = (
  {
    pushMsg = () => {},
    paymentInputSubmit = () => {},
    paymentBtnLoading = false,
    showFrontDrop = false,
    onChangeInputValue = () => {},
    frontDropControl = null,
    loading = false,
    showPaymentCancelBtn = true,
    paymentChannelListBtn = null,
    showUploadBtn = true,
    showEmojiBtn = false,
  },
  ref,
) => {
  const { isMobileDevice } = useRWD();
  const [inputValue, setInputValue] = useState('');
  const [showPaymentInput, setShowPaymentInput] = useState(false);
  // const [showPaymentBtn, setShowPaymentBtn] = useState(false);

  const inputChangeHandle = e => {
    onChangeInputValue(e.target.value);
    setInputValue(e.target.value);
  };

  /**
   * 傳送 文字訊息
   */
  const pushTextHandle = () => {
    const text = stripHtmlTag(inputValue);
    if (!text || loading) return;
    pushMsg({
      id: Date.now(),
      contentType: MsgContentTypeKeys['Text'],
      text: { text, isEncrypt: false },
    });
    setInputValue('');
  };

  // 傳送 圖片訊息
  const pushFileHandle = (file, text = '') => {
    pushMsg({
      id: Date.now(),
      contentType: MsgContentTypeKeys['File'],
      file: { upload: file, text, type: 'Image' },
    });
    return false;
  };

  // 傳送 錄音訊息
  // const [showAudioModal, setShowAudioModal] = useState(false);
  // const postAudioHandle = voice => {
  //   postMessage({
  //     id: Date.now(),
  //     contentType: MsgContentTypeKeys['Voice'],
  //     voice,
  //   });
  // };

  // 表情符號
  const [showEmojiBox, setShowEmojiBox] = useState(false);
  const onEmojiAdd = e => {
    let emoji = e.native;
    setInputValue(inputValue + emoji);
  };

  // 圖片預覽
  const [previewFileModal, setPreviewFileModal] = useState({ visible: false });

  // 圖片拖曳處理
  const { checkIsFileImage } = useFile();
  const { onWarningMsg } = useAlertMessage();
  const dropFilesHandle = useCallback(
    _files => {
      const isImage = _files ? checkIsFileImage(_files[0]) : false;
      if (!_files || !isImage) {
        onWarningMsg('格式错误');
        return;
      }
      setPreviewFileModal({ visible: true, file: _files[0] });
    },
    [checkIsFileImage, onWarningMsg],
  );
  const { setDropElRef, isDragging } = useDragDropFiles({
    onSetDropFiles: dropFilesHandle,
  });
  const getInputBarNode = node => node && setDropElRef(node);

  // 圖片貼上處理
  const pasteHandle = useCallback(
    e => {
      const file = e.clipboardData.files[0];
      // console.log('clipboardData', e.clipboardData.getData('text'));
      if (file && checkIsFileImage(file)) {
        setPreviewFileModal({ visible: true, file });
        e.preventDefault();
      }
    },
    [checkIsFileImage],
  );

  useImperativeHandle(ref, () => ({
    setShowPaymentInput,
    // setShowPaymentBtn,
    setInputValue,
  }));

  return (
    <>
      <Box
        ref={getInputBarNode}
        display='flex'
        flexDirection='column'
        bgColor='white'
        position='relative'
        p='2'>
        {/* FrontDrop */}
        <>
          {showPaymentInput && (
            <Suspense fallback={<></>}>
              <InputPayment
                showCancelBtn={showPaymentCancelBtn}
                loading={paymentBtnLoading}
                onSubmit={paymentInputSubmit}
                onCloseInput={() => setShowPaymentInput(false)}
              />
            </Suspense>
          )}
          {showFrontDrop && <FrontDrop>{frontDropControl}</FrontDrop>}
          {!showFrontDrop && isDragging && (
            <FrontDrop>
              <DragInDrop />
            </FrontDrop>
          )}
        </>

        {/* Input */}
        <>
          {IS_CLIENT && (
            <Input
              value={inputValue}
              onChange={inputChangeHandle}
              onPressEnter={pushTextHandle}
              onPaste={pasteHandle}
              bordered={false}
              placeholder='输入讯息'
              onFocus={() => setShowEmojiBox(false)}
              style={{ letterSpacing: '0.08rem', color: 'black' }}
            />
          )}
          {IS_CLIENT || (
            <InputTextArea
              value={inputValue}
              setValue={setInputValue}
              onPressEnter={pushTextHandle}
              onChange={inputChangeHandle}
              pasteHandle={pasteHandle}
              onFocus={() => setShowEmojiBox(false)}
              disabled={loading}
            />
          )}
        </>

        {/* Btn group */}
        <>
          <Space mt='2' style={{ justifyContent: 'flex-end' }}>
            {/* {showAudioBtn && (
              <Button
                size='large'
                shape='circle'
                icon={<AudioOutlined />}
                onClick={() => setShowAudioModal(true)}
              />
            )} */}
            {showEmojiBtn && !isMobileDevice && (
              <Suspense fallback={<></>}>
                <BtnEmojiPicker
                  showEmojiBox={showEmojiBox}
                  onClickEmoji={onEmojiAdd}
                  setShowEmojiBox={setShowEmojiBox}
                />
              </Suspense>
            )}
            {paymentChannelListBtn}
            {showUploadBtn && (
              <Suspense fallback={<></>}>
                <BtnUploadFile postFile={pushFileHandle} />
              </Suspense>
            )}
            {/* {showPaymentBtn && (
              <Button
                variant='secondary'
                onClick={() => setShowPaymentInput(true)}>
                充值
              </Button>
            )} */}
            <Button
              type='primary'
              icon={<SendOutlined />}
              onClick={pushTextHandle}
            />
          </Space>
        </>
      </Box>
      {/* <AudioModal
        visible={showAudioModal}
        setVisible={setShowAudioModal}
        onOk={postAudioHandle}
      /> */}
      {previewFileModal.visible && (
        <Suspense fallback={<></>}>
          <PreviewFileModal
            visible={previewFileModal.visible}
            setVisible={visible => setPreviewFileModal({ visible })}
            file={previewFileModal.file || null}
            postFile={pushFileHandle}
          />
        </Suspense>
      )}
    </>
  );
};

export default forwardRef(InputBar);
