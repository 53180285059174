import { useCallback } from 'react';
import { message as antdMessage } from 'antd';
import { getErrorMessage } from '@/utils';
const useAlertMessage = () => {
  /**
   * 提示 message
   */
  const onSuccessMsg = useCallback((content = null, config = {}) => {
    content = content ? content : '操作成功';
    antdMessage.success({ content, ...config });
  }, []);
  const onWarningMsg = useCallback((content = null, config = {}) => {
    content = content ? content : '警告';
    antdMessage.warning({ content, ...config });
  }, []);
  const onErrorMsg = useCallback((content = null, config = {}) => {
    let showContent = '';
    if (typeof content === 'string') {
      if (content === '401004') antdMessage.destroy();
      showContent = getErrorMessage(content);
    } else if (content?.message) {
      showContent = content.message;
    } else {
      showContent = content || '錯誤';
    }
    antdMessage.error({ content: showContent, ...config });
  }, []);

  /**
   * ws 狀態提示
   */
  const WS_ALERT_MSG_KEY = 'wsAlertMsg';
  const wsStatusNotify = useCallback(({ status } = {}) => {
    const success = () =>
      antdMessage.success({ content: '连线稳定', key: WS_ALERT_MSG_KEY });
    const loading = content =>
      antdMessage.loading({ content, duration: 0, key: WS_ALERT_MSG_KEY });

    const type = {
      onDisconnected: () => loading('网路不稳'),
      onReconnecting: () => loading('网路连线中'),
      onConnected: success,
      onReconnected: success,
    };
    type[status] && type[status]();
  }, []);

  return {
    onSuccessMsg,
    onWarningMsg,
    onErrorMsg,
    wsStatusNotify,
  };
};

export default useAlertMessage;
