import { useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import apiType, { defaultOptions } from '@/services';
import { getToken, getDeviceId, isFunction } from '@/utils';

/**
 * 不主動呼叫 , refetch 不會改變內建 loading 狀態
 * onCompleted bug > https://github.com/apollographql/react-apollo/issues/3943
 * @param {*} gql
 * @returns
 */
const useQueryApi = (gql, options = {}) => {
  if (!gql) throw new Error('gql is required');
  const [loading, setLoading] = useState(false);
  const { refetch } = useQuery(gql, {
    skip: true,
    ...options,
    context: { ...defaultOptions, ...options.context },
  });
  const fetchData = useCallback(
    async variables => {
      try {
        setLoading(true);
        const { data, errors: error } = await refetch(variables);
        options?.onSuccess && options.onSuccess(data);
        return { data, error };
      } catch (error) {
        // console.log('useQueryApi err message: ', JSON.stringify(error));
        options?.onError && options.onError(error);
        return { data: null, error };
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch],
  );
  return { fetchData, loading };
};

/**
 * @param {*} gql apiType ( can use apiType to find )
 * @param {*} isShowGeneralError 是否要走通用error彈窗
 * @param {*} callback 要自訂error callback的話用這個
 */
const useMutationApi = (gql, options = {}, callback = {}) => {
  if (!gql) throw new Error('gql is required');
  const {
    onSuccess = () => {},
    onError = () => {},
    context,
    ..._options
  } = options;
  const [mutate, result] = useMutation(gql, {
    skip: true,
    ..._options,
    context: { ...defaultOptions, ...context },
  });

  const mutateWrap = useCallback(
    async variables => {
      try {
        const res = await mutate({ variables });
        if (res.errors) throw new Error(res.errors);
        onSuccess(res, { ...variables });
        return [null, res];
      } catch (errors) {
        // 檢查自定義 callback
        const { message } = errors;
        if (isFunction(callback[message])) callback[message]();
        onError(errors);
        return [errors, null];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutate],
  );
  return [mutateWrap, result];
};

const useFetch = () => {
  const token = getToken(true);
  const deviceUID = getDeviceId();
  const [loading, setLoading] = useState(false);

  const fetchApi = useCallback(
    async (
      url,
      {
        cusHeaders = null,
        headers = {},
        method = 'GET',
        body = null,
        resType = 'json',
      } = {},
    ) => {
      setLoading(true);
      try {
        const response = await fetch(url, {
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          method,
          body,
          headers: cusHeaders || {
            'X-Device-ID': deviceUID,
            Authorization: token,
            ...headers,
          },
        });
        if (response.status !== 200) {
          const errorJson = await response.json();
          return { error: errorJson };
        }
        const returnType = {
          blob: res => res.blob(),
          json: res => res.json(),
        };
        return {
          data: returnType[resType]
            ? await returnType[resType](response)
            : response,
        };
      } catch (error) {
        console.log('getFetch error', error);
        return { error };
      } finally {
        setLoading(false);
      }
    },
    [deviceUID, token],
  );

  return { fetchApi, loading };
};

export { useQueryApi, useMutationApi, apiType, useFetch };
