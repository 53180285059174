import { gql } from '@apollo/client';
import { COMMENT_MENU } from './menuList';
/**
 * 員工系統 - 角色列表 Manager
 */

// 角色列表
export const LIST_ROLE = gql`
  ${COMMENT_MENU}
  query listRole($filter: RoleFilterInput!, $pagination: PaginationInput) {
    listRole(filter: $filter, pagination: $pagination) {
      meta {
        total
      }
      roles {
        id
        name
        authority {
          ...menuFields
          next {
            ...menuFields
            next {
              ...menuFields
            }
          }
        }
        supportAccountType
        createdAt
        createUserID
        updatedAt
        updateUserID
      }
      meta {
        total
      }
    }
  }
`;

// 角色明細
export const GET_ROLE = gql`
  ${COMMENT_MENU}
  query getRole($filter: RoleFilterInput!) {
    getRole(filter: $filter) {
      id
      name
      authority {
        ...menuFields
        next {
          ...menuFields
          next {
            ...menuFields
            next {
              ...menuFields
            }
          }
        }
      }
      supportAccountType
      createdAt
      createUserID
      updatedAt
      updateUserID
    }
  }
`;

// 新增角色
export const CREATED_ROLE = gql`
  mutation createRole($in: RoleCreateInput!) {
    createRole(in: $in)
  }
`;

// 更新角色
export const UPDATE_ROLE = gql`
  mutation updateRole($filter: RoleFilterInput!, $in: RoleUpdateInput!) {
    updateRole(filter: $filter, in: $in)
  }
`;

// 刪除角色
export const DELETE_ROLE = gql`
  mutation deleteRole($filter: RoleFilterInput!) {
    deleteRole(filter: $filter)
  }
`;

/**
 * 使用者角色 相關處理
 */

// 組合api (使用者 角色 + 所有角色列表)
export const LIST_USER_ROLE = gql`
  query listUserRole(
    $userRoles: UserRoleFilterInput!
    $listRole: RoleFilterInput!
  ) {
    listUserRole(filter: $userRoles) {
      userRoles {
        id
        userID
        roleID
        isAdmin
        createUserID
        updateUserID
        createdAt
        updatedAt
        role {
          id
          name
          supportAccountType
          createdAt
          createUserID
          updatedAt
          updateUserID
        }
      }
      meta {
        total
      }
    }
    # 角色列表
    listRole(filter: $listRole) {
      roles {
        id
        name
      }
    }
  }
`;

// 使用者 新增角色
export const CREATE_USER_ROLE = gql`
  mutation createUserRole($in: UserRoleCreateInput!) {
    createUserRole(in: $in)
  }
`;

// 使用者 刪除角色
export const DELETE_USER_ROLE = gql`
  mutation deleteUserRole($filter: UserRoleFilterInput!) {
    deleteUserRole(filter: $filter)
  }
`;
