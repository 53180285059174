import React from 'react';
import { Box } from '@/plugin/wrap-comp';
import useRWD from '@/hook/useRWD';
import useIframe from '@/hook/useIframe';
import useFile from '@/hook/useFile';

function Carousel({ position = 'Right', logoList = [] }) {
  const { isMobileSize } = useRWD();
  const { isInIframe } = useIframe();
  const { changeFileToUrl } = useFile();

  const carouselStyle =
    position === 'Right' ? { right: '-55%' } : { left: '-55%' };
  const showContentLogoURL =
    !isMobileSize && !isInIframe && logoList.length > 0;

  
    if (!showContentLogoURL) return null;
  
    return (
    <Box
      position='absolute'
      top={0}
      bottom={0}
      {...carouselStyle}
      display='flex'
      justifyContent='center'
      alignItems='center'>
      <Box width={250}>
        <Carousel effect='fade' autoplay dots={false}>
          {logoList.map((item, idx) => (
            <div key={idx}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // height: 100,
                }}>
                <img src={changeFileToUrl(item)} alt='' width={200} />
              </div>
            </div>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
}

export default Carousel;
