import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSubscription } from '@apollo/client';
import { setClientUserInfo, setIsLogin } from '@/store/client';
import { setRoomDetail } from '@/store/room';
import { setRecMsg, setWsStatusInfo } from '@/store/subscribe';
import { useMutationApi, apiType } from '@HOOK/useApi';
import useRoom, { headerAvatarURL } from '@HOOK/useRoom';
import useIframe from '@HOOK/useIframe';
import useRecMessage from '@HOOK/useRecMessage';
import useAlertMessage from '@/hook/useAlertMessage';

import FullBg from '@/components/CSRoomClient/FullBg';
import Container from '@/components/CSRoomClient/Container';
import CSRoomOrderList from '@/views/Client/components/OrderList';
import CSRoomContentWrap from '@/components/ChatRoomContent/ContentWrap';
import Carousel from '@/components/ChatRoomContent/Carousel';
import Header from '@/components/ChatRoomHeader/ClientHeader';
import {
  omitTypenameHandle,
  asyncLocalStorage,
  RoomStatusKeys,
  CSRoomTypeKey,
  cloneDeep,
  csRoomUrlParams,
} from '@UTILS';
import FullRoomLoading from './components/FullRoomLoading';
import BeforeEnter from './components/BeforeEnter';
import RedirectVerifyModel from './components/RedirectVerifyModel';
import CSRoom from './CSRoom';

// http://localhost:3001/?userName=eWVsbG93MDcw   (yellow070
// console.log('username', csRoomUrlParams.username);

const arr = [1, 2, 3, 4];
const random = arr[Math.floor(Math.random() * arr.length)];

function Client() {
  const dispatch = useDispatch();
  const { roomUsersMap } = useSelector(({ room }) => room);
  const { clientUserInfo, csRoomSetting, csRoomForm } = useSelector(
    ({ client }) => client,
  );
  const { onErrorMsg } = useAlertMessage();
  const { postToBack } = useIframe(csRoomUrlParams.reqUrl); // iframe 互動

  const csRoomRef = useRef(null);

  // 推波開關
  const [connectWS, setConnectWS] = useState({ skip: true });

  const [showCSRoom, setShowCSRoom] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [CSFormData, setCSFormData] = useState([]);

  // 排隊
  const [queueQsID, setQueueQsID] = useState(0);
  const [isQueue, setIsQueue] = useState(false);

  const isDepositRoomType =
    csRoomUrlParams.roomType === CSRoomTypeKey['Deposit'] ||
    clientUserInfo.roomType === CSRoomTypeKey['Deposit'];

  const { updateInRoom, fetchRoomInfo, fetchRoom } = useRoom({
    getRoomContext: { context: { isShowGeneralError: false } },
  });

  const resetUserInfo = () => {
    dispatch(setClientUserInfo({}));
    dispatch(setRoomDetail(null));
  };

  /**
   * 詢前表單
   */
  const updateQsHandel = useCallback((idx, data) => {
    const handle = org => {
      let tempList = cloneDeep(org);
      tempList[idx] = data;
      return tempList;
    };
    setCSFormData(pre => handle(pre));
  }, []);
  useEffect(() => {
    csRoomForm.isEnabled && setCSFormData(csRoomForm.formDataList);
  }, [csRoomForm]);

  /**
   * 排隊處理
   */
  const queueHandle = useCallback(
    roomStatus => {
      if (!csRoomSetting.queueEnabled) return;
      setIsQueue(false);
      if (roomStatus === RoomStatusKeys['Active']) setIsQueue(true);
      if (roomStatus === RoomStatusKeys['Processing']) setIsQueue(false);
    },
    [csRoomSetting.queueEnabled],
  );

  /**
   * 建立諮詢房
   */
  // 建立後的處理
  const afterCreatedRoomHandle = useCallback(
    async ({ token, deviceUID, roomID, type = '', ownerUserID }) => {
      const userInfo = {
        token,
        deviceUID,
        roomID,
        roomType: type.toLowerCase(),
        id: ownerUserID,
        username: csRoomUrlParams.username,
        avatarURL: `user-avatar${random}.jpg`,
      };
      dispatch(setClientUserInfo(userInfo));
      dispatch(setIsLogin(true));
      setConnectWS({ skip: false, token, deviceUID, roomID });
      await asyncLocalStorage.setItem('clientUserInfo', userInfo);
    },
    [dispatch],
  );

  // 建立諮詢房 一般房進線處理
  const [createdCSRoom] = useMutationApi(apiType.CREATED_CS_ROOM, {
    onError: err => {
      if (err.message === '401004') {
        resetUserInfo();
        // window.location.reload();
        // createdCSRoomHandle();
      }
      onErrorMsg('建立咨询房失败');
    },
    onSuccess: async data => {
      const {
        token = '',
        deviceUID = '',
        room: { consultingDetail },
      } = data.data.createConsultationRoom;
      await afterCreatedRoomHandle({ token, deviceUID, ...consultingDetail });
      queueHandle(consultingDetail.status);
      // 獲取第一次房間資訊&訊息
      const { error } = await fetchRoomInfo({ id: consultingDetail.roomID });
      if (error) return;
      setShowCSRoom(true);
      setInitLoading(false);
    },
  });

  const createdCSRoomHandle = useCallback(
    async (params = {}) => {
      await createdCSRoom({
        in: {
          accessDomain: document.domain,
          sourceDomain: csRoomUrlParams.sourceDomain,
          username: csRoomUrlParams.username,
          formData: omitTypenameHandle(CSFormData),
          questionID: queueQsID,
          ...params,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [CSFormData, dispatch],
  );

  // 建立諮詢房 充值房進線處理
  const [createCSDepositRoom] = useMutationApi(
    apiType.CREATED_CS_DEPOSIT_ROOM,
    {
      onError: err => {
        if (err.message === '401004') {
          resetUserInfo();
          // window.location.reload();
          // createCSDepositRoomHandle();
        }
        onErrorMsg('建立充值房失败');
      },
      onSuccess: async data => {
        const {
          token = '',
          deviceUID = '',
          room: { consultingDetail },
        } = data.data.createUserDepositRoom;
        await afterCreatedRoomHandle({ token, deviceUID, ...consultingDetail });
        await fetchRoomInfo({ id: consultingDetail.roomID });
        setShowCSRoom(true);
        setInitLoading(false);
      },
    },
  );
  const createCSDepositRoomHandle = useCallback(async () => {
    await createCSDepositRoom({
      in: {
        accessDomain: document.domain,
        sourceDomain: csRoomUrlParams.sourceDomain,
        username: csRoomUrlParams.username || clientUserInfo.username,
        realName: csRoomUrlParams.realName,
        amount: csRoomUrlParams.depositAmount,
        payType: csRoomUrlParams.payType,
        jobID: csRoomUrlParams.jobID,
      },
    });
  }, [clientUserInfo.username, createCSDepositRoom]);

  /**
   * 訂閱 ws
   */

  useSubscription(apiType.RECEIVE_MSG, {
    skip: connectWS.skip,
    variables: {
      userAuth: { token: connectWS.token, deviceUID: connectWS.deviceUID },
    },
    onSubscriptionData: ({ subscriptionData: subData }) => {
      // console.log('RecMsg', subData?.data?.receiveMessage);
      dispatch(setRecMsg(subData ? subData.data.receiveMessage : null));
    },
  });
  useRecMessage({
    hi: useCallback(() => {
      dispatch(setWsStatusInfo({ status: 'onConnected' }));
      updateInRoom({ roomID: connectWS.roomID });
    }, [connectWS.roomID, dispatch, updateInRoom]),
    join: useCallback(
      ({ roomID }) => fetchRoomInfo({ id: roomID }),
      [fetchRoomInfo],
    ),
    leave: useCallback(
      ({ roomID }) => fetchRoomInfo({ id: roomID }),
      [fetchRoomInfo],
    ),
    roomUpdate: useCallback(
      ({ roomUpdate }) => queueHandle(roomUpdate.consultingDetail.status),
      [queueHandle],
    ),
    csRoomStatusProcessing: useCallback(
      ({ roomUpdate }) => fetchRoomInfo({ id: roomUpdate.roomID }),
      [fetchRoomInfo],
    ),
  });

  /**
   * 關閉時重新檢查是否自動進線
   */
  const onFinishBeforeEnter = () => {
    createdCSRoomHandle();
  };

  /**
   * iniProcess
   * 照順序檢查
   */
  const iniProcess = useCallback(
    async ({ roomID, token = '' }) => {
      // ## 充值房 -> 直接進線
      if (isDepositRoomType) {
        resetUserInfo();
        createCSDepositRoomHandle({
          in: {
            accessDomain: document.domain,
            sourceDomain: csRoomUrlParams.sourceDomain,
            username: csRoomUrlParams.username || clientUserInfo.username,
          },
        });
        return;
      }

      // ## 復原 local 房間
      if (token) {
        const { error, data } = await fetchRoom({
          filter: { room: { id: roomID } },
        });
        // 權限未過期 || 房間未封存
        if (
          !error &&
          data.getRoom.consultingDetail.status !== RoomStatusKeys['Deactivated']
        ) {
          await createdCSRoomHandle();
          return;
        }
      }

      // ## 主動聯繫會員 || ## 自動進線 -> 直接進線
      if (
        csRoomUrlParams.autoContact === 'auto' ||
        csRoomSetting.autoCreatedCSEnable
      ) {
        resetUserInfo();
        createdCSRoomHandle();
        return;
      }

      // ## 詢前表單
      if (csRoomForm.isEnabled) {
        setInitLoading(false);
        return;
      }

      resetUserInfo();
      setInitLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [csRoomSetting, csRoomForm, csRoomUrlParams],
  );

  useEffect(() => {
    iniProcess(clientUserInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FullBg bgImgUrl={csRoomSetting.bgImgURL} bgColor={csRoomSetting.bgColor}>
        <Container
          headerLogoURL={csRoomSetting.headerLogoURL}
          headerBgColor={csRoomSetting.headerBgColor}
          bannerImgURL={csRoomSetting.bannerImgURL}
          bannerLinkURL={csRoomSetting.bannerLinkURL}
          chatRoomHeader={
            <>
              <Header
                title='客服咨询'
                roomID={
                  clientUserInfo.roomStatus === RoomStatusKeys['Deactivated']
                    ? null
                    : clientUserInfo.roomID
                }
                avatarURL={headerAvatarURL(roomUsersMap)}
                backToHandle={postToBack}
                showBeepBtn={csRoomSetting.showBeepBtn}
                beepEnabled={csRoomSetting.beepEnabled}
                showBackBtn={!!csRoomUrlParams.backUrl}
                backUrl={csRoomUrlParams.backUrl}
                rightExtra={
                  isDepositRoomType &&
                  !initLoading &&
                  showCSRoom && (
                    <CSRoomOrderList
                      roomID={clientUserInfo.roomID}
                      pushMsg={msg => csRoomRef.current.pushMsgHandle(msg)}
                    />
                  )
                }
              />
            </>
          }
          chatRoomContent={
            <CSRoomContentWrap>
              {initLoading && <FullRoomLoading />}
              {!initLoading && (
                <>
                  {showCSRoom ? (
                    <CSRoom
                      ref={csRoomRef}
                      isQueue={isQueue}
                      setConnectWS={setConnectWS}
                      connectAndFetchRoomData={createdCSRoomHandle}
                      fetchRoomInfo={fetchRoomInfo}
                    />
                  ) : (
                    <BeforeEnter
                      setQueueQsID={setQueueQsID}
                      updateQsHandel={updateQsHandel}
                      onfinish={onFinishBeforeEnter}
                    />
                  )}
                </>
              )}
            </CSRoomContentWrap>
          }
          chatRoomContentCourse={
            <Carousel
              position={csRoomSetting.contentLogoPosition}
              logoList={csRoomSetting.contentLogoURL}
            />
          }
        />
      </FullBg>
      <RedirectVerifyModel />
    </>
  );
}

export default Client;
