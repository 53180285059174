import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Space, Divider } from 'antd';
import { Button } from '@/plugin/wrap-comp';
import MsgInfoWrap from '@/components/Msg/MsgInfoWrap';
import ResetHtmlWrap from '@/components/ResetHtmlWrap';
import ConnectCSBtn from './ConnectCSBtn';

function CusFaqMsg({
  faqList = [],
  preData = {},
  clickFaq = () => {},
  connectCS = () => {},
}) {
  const { csRoomSetting } = useSelector(({ client }) => client);
  const { currentRoomID } = useSelector(({ room }) => room);
  return (
    <MsgInfoWrap>
      {/* 項目內文 */}
      {preData?.asked ? (
        <ResetHtmlWrap html={preData.asked} />
      ) : (
        <Typography.Title level={5}>您想咨询哪个问题？</Typography.Title>
      )}

      {/* 問題按鈕 */}
      <Space
        wrap
        style={{
          justifyContent: 'flex-start',
          width: '100%',
          marginTop: '8px',
        }}>
        {faqList.map(faq => (
          <Button
            key={faq.id}
            variant='secondary'
            onClick={() => clickFaq({ parentID: faq.id }, faq, currentRoomID)}>
            {faq.question}
          </Button>
        ))}
      </Space>
      {/* 進線按鈕 */}
      {faqList.length === 0 &&
        !csRoomSetting.autoCreatedCSEnable &&
        !currentRoomID && (
          <>
            <Divider style={{ margin: '8px 0px' }} />
            <ConnectCSBtn connectCS={connectCS} question={preData.question} />
          </>
        )}
    </MsgInfoWrap>
  );
}

export default CusFaqMsg;
