import { createSlice } from '@reduxjs/toolkit';
import { removeStorage, setStorage } from '@/utils';

const initialState = {
  clientUserInfo: {}, // 遊客相關資訊
  isLogin: false,
  isLoading: false,
  gLoading: true,
  csRoomSetting: {},
  csRoomForm: {},
  csRoomQueueList: [],
  renew: false, // 重新拉取房間 msgList
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientUserInfo: (state, { payload }) => {
      state.clientUserInfo = payload;
      setStorage('clientUserInfo', payload);
    },

    setIsLogin: (state, { payload }) => {
      state.isLogin = payload;
    },

    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setGLoading: (state, { payload }) => {
      state.gLoading = payload;
    },

    setCSRoomSetting: (state, { payload }) => {
      state.csRoomSetting = payload;
    },
    setCSRoomSettingBeepEnabled: state => {
      state.csRoomSetting = {
        ...state.csRoomSetting,
        beepEnabled: !state.csRoomSetting.beepEnabled,
      };
    },
    setCsRoomForm: (state, { payload }) => {
      state.csRoomForm = payload;
    },
    setCSRoomQueueList: (state, { payload }) => {
      state.csRoomQueueList = payload;
    },
    setRenew: (state, { payload }) => {
      state.renew = payload;
    },

    resetClient: state => {
      removeStorage('clientUserInfo');
      state.clientUserInfo = {};
      state.isLogin = false;
      state.isLoading = false;
      state.renew = false;
    },
  },
});

export const {
  setClientUserInfo,
  setIsLogin,
  setIsLoading,
  setGLoading,
  setCSRoomSetting,
  setCSRoomSettingBeepEnabled,
  setCsRoomForm,
  setCSRoomQueueList,
  resetClient,
  setRenew,
} = clientSlice.actions;
export default clientSlice.reducer;
