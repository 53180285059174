import styled from 'styled-components';
import {
  Button as AButton,
  Row as ARow,
  Col as ACol,
  Tag as ATag,
  Card as ACard,
  Space as ASpace,
  Divider as ADivider,
} from 'antd';
import {
  variant,
  color,
  background,
  space,
  border,
  flexbox,
  layout,
  position,
  typography,
  shadow,
} from 'styled-system'; // 定義元件的個別樣式用
const { theme } = require('@/plugin/theme');

/**
 * 擴充 主題樣式
 */
const MainTheme = [
  'primary',
  'primary-light',
  'secondary',
  'secondary-light',
  'success',
  'warning',
  'danger',
  'info',
];
const mapObj = (prefix, styleObjFun) => {
  return MainTheme.reduce(
    // eslint-disable-next-line no-sequences
    (acc, cur) => ((acc[`${prefix}${cur}`] = styleObjFun(cur)), acc),
    {},
  );
};

// Button
const btnGeneralObj = val => {
  let color = theme.white;
  if ('secondary-light' === val) color = val.black;
  return {
    color,
    bg: theme[val],
    borderColor: theme[val],
  };
};
const btnOutlineObj = val => ({
  color: theme[val],
  bg: theme.white,
  borderColor: theme[val],
});
const AButtonVariant = styled(AButton)(
  variant({
    variants: {
      ...mapObj('', btnGeneralObj),
      ...mapObj('outline-', btnOutlineObj),
    },
  }),
);
const Button = styled(AButtonVariant)`
  ${color}
  ${space}
  ${background}
  ${border}
`;

// Tags
const tagGeneralObj = val => ({
  color: val.white,
  bg: theme[val],
  borderColor: theme[val],
});
const tagOutlineObj = val => ({
  color: theme[val],
  borderColor: theme[val],
  bg: theme[`${val}-light`],
});
const ATagVariant = styled(ATag)(
  variant({
    variants: {
      ...mapObj('', tagGeneralObj),
      ...mapObj('outline-', tagOutlineObj),
    },
  }),
);
const Tag = styled(ATagVariant)`
  ${border}
`;

// Card
const cardGeneralObj = val => ({
  color: val.white,
  bg: theme[val],
  borderColor: theme[val],
});

const ACardVariant = styled(ACard)(
  variant({
    variants: {
      ...mapObj('', cardGeneralObj),
      shadow: {
        'box-shadow': `0px 0px 4px 0px rgb(71 95 123 / 20%)`,
      },
      'shadow-lg': {
        'box-shadow': `0px 0px 24px 0px rgb(71 95 123 / 20%)`,
      },
    },
  }),
);
const Card = styled(ACardVariant)`
  ${border}
  ${space}
  ${color}
`;

/**
 * 擴充 其他
 */
const Space = styled(ASpace)`
  ${space}
  ${flexbox}
`;
const Row = styled(ARow)`
  ${space}
  ${layout}
  ${flexbox}
`;
const Col = styled(ACol)`
  ${space}
  ${layout}
  ${flexbox}
`;
const Divider = styled(ADivider)`
  ${space}
`;

const Box = styled.div`
  box-sizing: border-box;
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${border}
  ${background}
  ${typography}
  ${shadow}
  ${({ color, bgColor, borderCr, theme }) => {
    return `
      ${bgColor ? `background-color: ${theme[bgColor] || bgColor};` : ''}
      ${color ? `color: ${theme[color] || color};` : ''}
      ${borderCr ? `border-color: ${theme[borderCr] || borderCr};` : ''}
    `;
  }}
`;

export { Button, Row, Col, Space, Tag, Divider, Card, Box };
