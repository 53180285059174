/**
 * 報表系統
 */

import { gql } from '@apollo/client';

/**
 * 會員統計報表
 *  */
export const REPORT_COMMON_USER = gql`
  query reportCommonUser(
    $baseFilter: BaseFilterInput
    $pagination: PaginationInput
  ) {
    # 使用者每日統計
    listUserDailySummary(
      filter: { baseFilter: $baseFilter }
      pagination: $pagination
    ) {
      userDailySummaries {
        date
        registerCountOfCommonUser # 會員註冊數
        onlineCountOfCommonUser # 會員在線數
        createdAt
      }
      meta {
        registerCountTotalOfCommonUser # 會員註冊數總數
        onlineCountTotalOfCommonUser # 會員在線數
        total
      }
    }
    # 房間每日統計
    listRoomDailySummary(filter: { baseFilter: $baseFilter }) {
      roomDailySummaries {
        date
        createdAt
        createGroupCount # 群組創建數
      }
      meta {
        createGroupCountTotal # 創建群組總數
        total
      }
    }
  }
`;

/**
 * 諮詢房統計
 */
export const REPORT_CONSULTING_ROOM = gql`
  query listConsultationRoomSummary($filter: RoomSummaryFilterInput!) {
    listConsultationRoomSummary(filter: $filter) {
      consultationRoomSummaries {
        date
        countOfCommonUser
        countOfTouristUser
      }
    }
  }
`;

/**
 * 客服統計報表
 *  */
export const REPORT_CONSULTING_ROOM_SERVICE = gql`
  query reportConsultingRoom($filter: RoomDailySummaryFilterInput!) {
    # 房間每日統計
    listRoomDailySummary(filter: $filter) {
      roomDailySummaries {
        date
        createdAt
        createConsultationCountOfCommonUser # 新增諮詢室會員數量(進線會員)
        createConsultationCountOfTourist #新增諮詢室遊客數量
        createConsultationCount # 創建諮詢室數量
        validConsultationCount # 有效諮詢數
        averageResponseSecond # 平均首次回應時間
        averageConversationSecond # 平均對話時長
      }
      meta {
        createGroupCountTotal # 創建群組總數
        createConsultationCountOfCommonUserTotal # 新增諮詢室會員數量
        createConsultationCountTotal #創建諮詢室數量
        validConsultationCountTotal # 有效諮詢數
        averageResponseSecondTotal # 平均首次回應時長
        averageConversationSecondTotal # 平均對話時長
        total
      }
    }
  }
`;

/**
 * 客服統計報表 + 各地排行
 *  */
export const REPORT_CONSULTING_ROOM_SERVICE_AREA = gql`
  query reportConsultingRoom(
    $roomServiceFilter: RoomDailySummaryFilterInput!
    $roomAreaFilter: ConsultingRoomFilterInput!
  ) {
    # 房間每日統計
    listRoomDailySummary(filter: $roomServiceFilter) {
      roomDailySummaries {
        date
        createdAt
        createConsultationCountOfCommonUser # 新增諮詢室會員數量(進線會員)
        createConsultationCountOfTourist #新增諮詢室遊客數量
        createConsultationCount # 創建諮詢室數量
        validConsultationCount # 有效諮詢數
        averageResponseSecond # 平均首次回應時間
        averageConversationSecond # 平均對話時長
        faqCount # FAQ數
        invalidFaqCount # 無效FAQ數
      }
      meta {
        createGroupCountTotal # 創建群組總數
        createConsultationCountOfCommonUserTotal # 新增諮詢室會員數量
        validConsultationCountTotal # 有效諮詢數
        averageResponseSecondTotal # 平均首次回應時長
        averageConversationSecondTotal # 平均對話時長
        total
      }
    }
    # 各地排行
    statisticsConsultationRoomArea(filter: $roomAreaFilter) {
      statistics {
        administrativeArea
        count
      }
    }
  }
`;

/**
 * 各地排行
 */
export const REPORT_STATISTICS_CONSULTING_ROOM_AREA = gql`
  query statisticsConsultationRoomArea($filter: ConsultingRoomFilterInput) {
    statisticsConsultationRoomArea(filter: $filter) {
      statistics {
        administrativeArea
        count
      }
    }
  }
`;

/**
 * 客服個人報表
 *  */
export const REPORT_CUSTOMER_SERVICE = gql`
  query listCustomerServiceSummary(
    $filter: CustomerServiceSummaryFilterInput!
    $pagination: PaginationInput
  ) {
    listCustomerServiceSummary(filter: $filter, pagination: $pagination) {
      customerServiceSummaries {
        user {
          id
          username
        }
        firstEntryCount # 首位客服
        entryCount # 進入房間數
        validConsultationCount # 有效諮詢數
        casesClosedCount # 結案數
        averageResponseSecond # 平均回應時長 (秒)
        averageConversationSecond # 平均對話時間 (秒)
        averageResponseByItemSecond # 平均逐條回應時間 (秒)
        loginSecond # 登錄時長
        onlineSecond # 在線時間
        stealthSecond # 隱身時間
      }
      meta {
        total
      }
    }
  }
`;
