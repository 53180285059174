import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@/plugin/wrap-comp';

function ConnectCSBtn({ connectCS = () => {}, question }) {
  const { isLogin } = useSelector(({ client }) => client);
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        type='link'
        onClick={() => connectCS({ question })}
        disabled={isLogin}>
        联系客服
      </Button>
    </div>
  );
}

export default ConnectCSBtn;
