/**
 * 運營類 訊息
 * - 行銷問候語
 * - FAQ
 */
import React from 'react';
import styled from 'styled-components';
import useRWD from '@/hook/useRWD';

const MsgInfoStyle = styled.div`
  margin-bottom: 1em;
  background: ${({ theme, bgColor }) => bgColor || theme.white};
  border-radius: 6px;
  padding: 8px 12px;
  color: ${({ theme, color }) => color || theme.black};
  ${({ isMobile }) =>
    isMobile &&
    `& img {
        width: 100% !important;
        height: auto !important;
    }`}
`;

const MsgInfoWrap = ({
  children = null,
  bgColor = '',
  color = '',
  ...props
}) => {
  const { isMobileSize } = useRWD();
  return (
    <MsgInfoStyle
      borderRadius={5}
      bgColor={bgColor}
      color={color}
      isMobile={isMobileSize}
      {...props}>
      {children}
    </MsgInfoStyle>
  );
};

export default MsgInfoWrap;
