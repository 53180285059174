import { gql } from '@apollo/client';

/**
 * FAQ
 */
export const LIST_FAQ_KEYS = gql`
  query listFrequentlyQuestion($filter: FrequentlyQuestionFilterInput!) {
    listFrequentlyQuestion(filter: $filter) {
      frequentlyQuestions {
        id
        question
        parentID
        depth
      }
    }
  }
`;
export const LIST_FAQ_DETAIL = gql`
  query listFrequentlyQuestion($filter: FrequentlyQuestionFilterInput!) {
    listFrequentlyQuestion(filter: $filter) {
      frequentlyQuestions {
        id
        consultingRoomOriginID
        question
        asked
        parentID
        sequence
        source
        depth
        createdAt
        updatedAt
        isEnabled
      }
      meta {
        total
      }
    }
  }
`;
export const CREATE_FAQ = gql`
  mutation createFrequentlyQuestion($in: FrequentlyQuestionCreateInput!) {
    createFrequentlyQuestion(in: $in)
  }
`;
export const UPDATE_FAQ = gql`
  mutation updateFrequentlyQuestion(
    $filter: FrequentlyQuestionFilterInput!
    $in: FrequentlyQuestionUpdateInput!
  ) {
    updateFrequentlyQuestion(filter: $filter, in: $in)
  }
`;
export const DELETE_FAQ = gql`
  mutation deleteFrequentlyQuestion($filter: FrequentlyQuestionFilterInput!) {
    deleteFrequentlyQuestion(filter: $filter)
  }
`;
