import React, {
  Suspense,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRoomDetail } from '@/store/room';
import { setRenew } from '@/store/client';

import { apiType, useMutationApi, useQueryApi } from '@/hook/useApi';
import useRoomMessage from '@/hook/useRoomMessage';
import useRecMessage from '@/hook/useRecMessage';
import useBeep from '@/hook/useBeep';
import useAlertMessage from '@/hook/useAlertMessage';
import useOrder from '@/hook/useOrder';

import { Box } from '@WRAP_COMP';
import ChatRoomContent from '@/components/ChatRoomContent';
import ChatRoomInputBar from '@/components/ChatRoomInputBar';
import MsgList from '@/components/MsgList';
import MsgInfoWrap from '@/components/Msg/MsgInfoWrap';
import ResetHtmlWrap from '@/components/ResetHtmlWrap';
import {
  CSRoomTypeKey,
  MsgSystemType,
  OrderStatusKeys,
  asyncLocalStorage,
  csRoomUrlParams,
  MsgContentTypeKeys,
} from '@/utils';

import CusFaqMsg from './components/CusFaqMsg';
import CusUserMsg from './components/CusUserMsg';
import ConnectCSBtn from './components/ConnectCSBtn';
import QueueInfo from './components/QueueInfo';
import DepositToken from './components/DepositToken';
import UploadProofDrawer from '@/views/Client/components/UploadProofDrawer';
import ClientOrderDetailDrawer from '@/components/Drawer/ClientOrderDetailDrawer';
import Countdown from '@/components/Countdown';
import QuickMsgTab from './components/QuickMsgTab';

let hasTypingTimeoutID = null;
// let disConnectedInterID = null;
// let refreshInterID = null;

const CSRoom = (
  {
    isQueue = false,
    createdCSRoomHandle = () => {},
    fetchRoomInfo = () => {},
    setConnectWS = () => {},
  },
  ref,
) => {
  const dispatch = useDispatch();
  const operatingProcessDone = useRef(false);
  const inputBarRef = useRef(null);
  const chatRoomContentRef = useRef(null);
  const uploadProofDrawerRef = useRef(null);
  const clientOrderDetailDrawerRef = useRef(null);
  const { onErrorMsg } = useAlertMessage();

  const { currentRoomID, roomDetail, roomUsersMap } = useSelector(
    ({ room }) => room,
  );
  const { renew, csRoomSetting, isLogin, clientUserInfo } = useSelector(
    ({ client }) => client,
  );

  const isDepositRoomType = useMemo(
    () =>
      csRoomUrlParams.roomType === CSRoomTypeKey['Deposit'] ||
      clientUserInfo.roomType === CSRoomTypeKey['Deposit'],
    [clientUserInfo.roomType],
  );

  const [expiredAt, setExpiredAt] = useState(0);

  const userOrder = roomDetail?.room?.userOrder || {};

  /**
   * 訂單
   */
  // 取消訂單
  const { expireDeposit } = useOrder(); // 取消訂單

  // 審核訂單
  const [payDeposit] = useMutationApi(apiType.paidDeposit);
  const _payDeposit = async file => {
    const [err] = await payDeposit({
      in: {
        roomID: roomDetail.room.roomID,
        orderNo: roomDetail.room.userOrder.orderNo,
      },
    });
    if (err) {
      onErrorMsg(err?.message || '完成存款错误');
    } else {
      uploadProofDrawerRef.current.setVisible(false);
      pushMsgHandle({
        id: Date.now(),
        contentType: MsgContentTypeKeys['File'],
        file: {
          upload: file,
          text: '我已完成付款，请确认!',
          type: 'Image',
        },
      });
    }
  };

  /**
   * 提示音
   */
  const { playPromise } = useBeep();
  const playBeep = useCallback(() => {
    if (!csRoomSetting.beepURL || !csRoomSetting.beepEnabled) return;
    const beepObj = new Audio(csRoomSetting.beepURL);
    playPromise(beepObj);
  }, [csRoomSetting.beepEnabled, csRoomSetting.beepURL, playPromise]);

  /**
   * 行銷問候語 + FAQ depth: 1
   */
  const { fetchData: greetingAndFaq } = useQueryApi(
    apiType.GET_GREETING_AND_FAQ,
  );
  const fetchGreetingAndFaq = useCallback(async () => {
    const { error, data } = await greetingAndFaq({
      source: csRoomUrlParams.source || '',
    });
    if (error) return { error };
    return {
      greetingList: data.listConsultingGreeting.ConsultingGreetings,
      faqList: data.listFrequentlyQuestion.frequentlyQuestions,
    };
  }, [greetingAndFaq]);

  /**
   * FAQ
   */
  const [countFAQ] = useMutationApi(apiType.FAQ_COUNT);
  const { fetchData: fetchFaqList } = useQueryApi(apiType.LIST_FAQ_DETAIL);
  const fetchFaqListHandle = useCallback(
    async frequentlyQuestion => {
      const { error, data } = await fetchFaqList({
        filter: { frequentlyQuestion, isClient: true },
      });
      if (error) return { error };
      return { list: data.listFrequentlyQuestion.frequentlyQuestions };
    },
    [fetchFaqList],
  );

  const {
    roomMsg,
    pushCusMsg,
    pushMsg,
    setRoomMsgHandle,
    fetchInRoomMsgHandle,
    postSystemMsg,
    loadMoreMsg,
    loadPreRoomMsg,
    lastReadID,
  } = useRoomMessage({
    currentRoomID,
    currUserRooms: roomDetail?.room.userRooms || [],
    currHistoryRooms: roomDetail?.room.historyRooms || [],
  });

  /**
   * 推訊息
   */
  const pushMsgHandle = msg => {
    pushMsg(msg);
    chatRoomContentRef.current.scrollNodeTo();
  };

  /**
   * 使用者正在輸入處理 (加入延遲)
   */
  const [postTyping, { loading }] = useMutationApi(apiType.POST_TYPING);
  const lazyTyping = useCallback(
    content => {
      if (hasTypingTimeoutID) return;
      postTyping({ in: { roomID: clientUserInfo.roomID, content } });
      hasTypingTimeoutID = setInterval(function () {
        clearInterval(hasTypingTimeoutID);
        hasTypingTimeoutID = null;
      }, 500);
    },
    [clientUserInfo.roomID, postTyping],
  );

  /**
   * 監聽
   */
  // 封存處理
  const recDeactivatedHandle = () => {
    pushCusMsg({
      render: id => (
        <MsgInfoWrap
          key={id}
          style={{ display: 'flex', justifyContent: 'center' }}>
          咨询已结束
        </MsgInfoWrap>
      ),
    });
    setConnectWS({ skip: true });
    dispatch(setRoomDetail(null));
    asyncLocalStorage.removeItem('clientUserInfo');
    // if (refreshInterID) {
    //   window.clearInterval(refreshInterID);
    //   refreshInterID = null;
    // }
  };
  // 新訊息處理
  const recNewMsgHandle = recMsg => {
    if (recMsg.from.userID !== roomDetail?.ownerUserData?.id) {
      chatRoomContentRef.current?.setHasNewMsgHandle();
      playBeep();
    }
  };

  const recOrderPendingHandle = msg => {
    pushMsgHandle(msg);
    setExpiredAt(msg.system.content.expiredAt);
  };

  useRecMessage({
    msgCommonType: recNewMsgHandle,
    csRoomStatusDeactivated: recDeactivatedHandle,
    orderStatusPending: recOrderPendingHandle,
    orderStatusCancel: () => {
      setExpiredAt(0);
    },
    orderStatusApproving: () => {
      setExpiredAt(0);
    },
    payList: pushMsgHandle,
    systemText: () => {
      chatRoomContentRef.current?.setHasNewMsgHandle();
      chatRoomContentRef.current?.scrollNodeTo();
    },
  });

  /**
   * 訊息漏接處理
   */
  const gogoRetry = useCallback(() => {
    Promise.all([
      fetchInRoomMsgHandle(clientUserInfo.roomID, 'AFRESH', roomMsg.length),
      fetchRoomInfo({ id: clientUserInfo.roomID }),
    ]);
  }, [
    clientUserInfo.roomID,
    fetchInRoomMsgHandle,
    fetchRoomInfo,
    roomMsg.length,
  ]);
  useEffect(() => {
    if (renew) {
      gogoRetry();
      dispatch(setRenew(false));
    }
  }, [renew, gogoRetry, dispatch]);
  /**
   * ws 重連處理
   */
  // ws 斷線輪詢處理
  // const gogoRetry = () => {
  //   Promise.all([
  //     fetchInRoomMsgHandle(clientUserInfo.roomID, 'AFRESH', roomMsg.length),
  //     fetchRoomInfo(clientUserInfo.roomID),
  //   ]);
  // };
  // const disConnectedInterHandle = async () => {
  //   if (!isLogin) return;
  //   // 3s輪詢 (連上線後停止)
  //   // eslint-disable-next-line no-unused-vars
  //   if (!disConnectedInterID) {
  //     disConnectedInterID = setInterval(async () => {
  //       gogoRetry();
  //     }, 3000);
  //   }
  //   // 30s輪詢 (不停止)
  //   // eslint-disable-next-line no-unused-vars
  //   if (!refreshInterID) {
  //     refreshInterID = setInterval(async () => {
  //       gogoRetry();
  //     }, 30000);
  //   }
  // };

  // const clearDisConnectedInter = () => {
  //   if (!disConnectedInterID) return;
  //   window.clearInterval(disConnectedInterID);
  //   disConnectedInterID = null;
  // };
  // const wsStatusHandle = ({ status }) => {
  //   const type = {
  //     onDisconnected: disConnectedInterHandle,
  //     onReconnected: clearDisConnectedInter,
  //     onConnected: clearDisConnectedInter,
  //   };
  //   type[status] && type[status]();
  // };
  // useEffect(() => {
  //   wsStatusHandle(wsStatusInfo);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [wsStatusInfo]);

  /**
   * 進線按鈕
   */
  const connectCSBtnRender = question => {
    pushCusMsg({
      render: id => (
        <MsgInfoWrap key={id}>
          <ConnectCSBtn connectCS={createdCSRoomHandle} question={question} />
        </MsgInfoWrap>
      ),
    });
  };

  /**
   * FAQ 發送處理
   */
  const clickFaq = async (params = {}, preData = {}, roomID) => {
    pushCusMsg({
      render: id => <CusUserMsg key={id} txt={preData.question} />,
    });
    chatRoomContentRef.current.scrollNodeTo();
    roomID && countFAQ({ roomID });
    const { error, list } = await fetchFaqListHandle(params);
    if (error) {
      !roomID && connectCSBtnRender();
      return;
    }
    faqContentRender(list, preData);
  };
  const faqContentRender = (faqList = [], preData = {}) => {
    pushCusMsg({
      render: id => (
        <CusFaqMsg
          key={id}
          faqList={faqList}
          preData={preData}
          clickFaq={clickFaq}
          connectCS={createdCSRoomHandle}
        />
      ),
    });
  };

  /**
   * 行銷問候語發送處理
   */
  const greetingHandle = async el => {
    return new Promise(resolve => {
      setTimeout(() => {
        pushCusMsg({
          render: id => (
            <MsgInfoWrap key={id}>
              <ResetHtmlWrap html={el.content} />
            </MsgInfoWrap>
          ),
        });
        resolve();
      }, el.afterSendSec * 1000);
    });
  };

  /**
   * 運營流程 (顯示順序：行銷問候語 > faq > 房間舊訊息)
   */
  useEffect(() => {
    // 非自動進線登入後流程
    isLogin &&
      operatingProcessDone.current &&
      fetchInRoomMsgHandle(clientUserInfo.roomID, 'PUSH');
  }, [isLogin, clientUserInfo.roomID, fetchInRoomMsgHandle]);

  // 運營訊息發完才能顯示房間訊息
  const operatingProcess = async isLogin => {
    const { error, greetingList, faqList } = await fetchGreetingAndFaq();
    if (error) return;
    // 行銷問候語發完才發 FAQ
    if (greetingList.length)
      await Promise.all(greetingList.map(el => greetingHandle(el)));
    if (faqList.length) faqContentRender(faqList);
    // 沒有 FAQ 並且為未登入 才顯示進線按鈕
    if (!faqList.length && !isLogin) connectCSBtnRender();
    isLogin && fetchInRoomMsgHandle(clientUserInfo.roomID, 'PUSH');
    operatingProcessDone.current = true;
  };

  const handleDepositRoomInit = async () => {
    const msg = await fetchInRoomMsgHandle(clientUserInfo.roomID, 'PUSH');
    const pendingOrder = msg?.find(({ system }) => {
      return (
        system &&
        system.type === MsgSystemType['UserOrder'] &&
        system.content.orderStatus === OrderStatusKeys['Pending'].key
      );
    });
    pendingOrder && setExpiredAt(pendingOrder.system.content.expiredAt);
  };

  useEffect(() => {
    if (isDepositRoomType) {
      handleDepositRoomInit();
    } else {
      operatingProcess(isLogin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    pushMsgHandle,
    postSystemMsg,
  }));

  return (
    <>
      <ChatRoomContent
        ref={chatRoomContentRef}
        roomID={currentRoomID}
        roomMsgLength={roomMsg.length}
        loadMoreMsg={loadMoreMsg}
        loadPreRoomMsg={loadPreRoomMsg}
        bgColor={csRoomSetting.boxBgColor}
        bgImg={csRoomSetting.boxBgImgURL}
        controlHeader={
          <>
            <DepositToken token={csRoomUrlParams.depositToken} />
            {isQueue && <QueueInfo />}
            {expiredAt > 0 && (
              <Suspense fallback={<></>}>
                <Box
                  pt='3'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  color='#e43737'>
                  <Countdown
                    value={expiredAt}
                    text='订单进行中，剩余时间'
                    size='12'
                    color='#e43737'
                    bgColor='#ffdddd'
                    onFinish={() => {
                      expireDeposit({
                        in: {
                          roomID: currentRoomID,
                          orderNo: userOrder.orderNo,
                        },
                      });
                    }}
                    wrapProps={{
                      px: 3,
                      py: 1,
                      borderRadius: '6px',
                      fontSize: '12px',
                    }}
                  />
                </Box>
              </Suspense>
            )}
          </>
        }
        controlFooter={
          isDepositRoomType && (
            <Box
              height='40px'
              display='flex'
              alignItems='center'
              justifyContent='center'>
              <UploadProofDrawer
                ref={uploadProofDrawerRef}
                roomID={clientUserInfo.roomID}
                payDeposit={_payDeposit}
              />
              <QuickMsgTab pushMsg={pushMsgHandle} />
            </Box>
          )
        }>
        <MsgList
          roomMsg={[...roomMsg]}
          roomID={currentRoomID}
          lastReadID={lastReadID}
          usersMap={roomUsersMap}
          setRoomMsgHandle={setRoomMsgHandle}
          onOrderDetail={() =>
            clientOrderDetailDrawerRef.current.setVisible(true)
          }
        />
      </ChatRoomContent>

      <div style={{ position: 'relative' }}>
        <ChatRoomInputBar
          ref={inputBarRef}
          onChangeInputValue={val => loading || lazyTyping(val)}
          pushMsg={pushMsgHandle}
          showFrontDrop={!roomDetail}
          showPaymentCancelBtn={!!roomMsg.length}
        />
      </div>

      <ClientOrderDetailDrawer
        ref={clientOrderDetailDrawerRef}
        roomID={clientUserInfo.roomID}
        {...userOrder}
      />
    </>
  );
};

export default forwardRef(CSRoom);
