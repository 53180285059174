import React from 'react';
import { Image } from 'antd';

function MsgImage({ fileURL = '', text = '' }) {
  return (
    <>
      <Image width={200} src={fileURL} />
      <br />
      <span>{text}</span>
    </>
  );
}

export default MsgImage;
