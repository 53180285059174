import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IS_CLIENT } from '@/const';
import { useQueryApi, useMutationApi, apiType } from '@/hook/useApi';
import useIframe from '@/hook/useIframe';

import {
  CusMsgContentType,
  MsgStatusKeys,
  RoomTypeKey,
  MsgContentTypeKeys,
  MsgFromTags,
} from '@/utils';
import useRecMessage from './useRecMessage';
import useAlertMessage from './useAlertMessage';

const MSG_LIMIT = 20;

/**
 * 訊息處理
 * @param {Object} param0
 * @returns {Object}
 */
const useRoomMessage = ({
  currentRoomID = 0,
  currUserRooms = [],
  currHistoryRooms = [],
}) => {
  const { meInfo } = useSelector(({ auth }) => auth);
  const { clientUserInfo } = useSelector(({ client }) => client);
  const MeID = IS_CLIENT ? clientUserInfo.id : meInfo.id;
  const { onWarningMsg } = useAlertMessage();

  const [lastReadID, setLastReadID] = useState(0);
  const [roomMsg, setRoomMsg] = useState([]);
  const roomMsgTotal = useRef(0);
  const historyRooms = useRef([]);
  const currPreRoomIdx = useRef(0);
  const currPreRoomID = useRef(0);

  useEffect(() => {
    historyRooms.current = [...currHistoryRooms].reverse();
    currPreRoomIdx.current = 0;
    currPreRoomID.current = 0;
  }, [currHistoryRooms]);

  // 最後已讀的訊息 ID
  useEffect(() => {
    if (currentRoomID && currUserRooms.length > 0) {
      // 找到 room.userRooms 裡最大的已讀ID
      const lastReadID = currUserRooms.reduce((acc, curr) => {
        // 排除自己、已離開的人
        if (
          curr.userID !== MeID &&
          curr.deletedAt === 0 &&
          curr.lastReadMessageID > acc
        ) {
          return curr.lastReadMessageID;
        }
        return acc;
      }, 0);
      setLastReadID(lastReadID);
    }
  }, [currentRoomID, currUserRooms, MeID]);

  /**
   * 房間訊息處理
   * @param {String} type
   * INITIAL:初始化訊息,
   * PUSH:往後添加,
   * UNSHIFT:往前添加,
   * RESET:重置,
   * AFRESH: 略過 cusMsg 覆蓋所有訊息
   */
  const setRoomMsgHandle = useCallback(
    (type = 'INITIAL', { msgList = [], idx, msg } = {}) => {
      const handleType = {
        INITIAL: ({ msgList }) => setRoomMsg(msgList),
        PUSH: ({ msgList }) => setRoomMsg(pre => [...msgList, ...pre]),
        UNSHIFT: ({ msgList }) => setRoomMsg(pre => [...pre, ...msgList]),
        UPDATE: ({ id, msg }) =>
          setRoomMsg(pre => pre.map(m => (m.id === id ? msg : m))),
        AFRESH: () =>
          setRoomMsg(pre => {
            const cusMsg = pre.filter(m => m.contentType === CusMsgContentType);
            return [...msgList, ...cusMsg];
          }),
        RESET: () => setRoomMsg([]),
      };
      handleType[type] && handleType[type]({ msgList, idx, msg });
    },
    [],
  );

  // 推訊息 帶 lastMessageID
  const pushMsg = msg => {
    const lastMessageID =
      roomMsg[1]?.contentType === CusMsgContentType ? '' : roomMsg[1]?.id;
    setRoomMsgHandle('PUSH', { msgList: [{ ...msg, lastMessageID }] });
  };

  // 推前端客製訊息 (不傳後端
  const pushCusMsg = useCallback(
    ({ render }) => {
      setRoomMsgHandle('PUSH', {
        msgList: [
          {
            id: Date.now(),
            contentType: CusMsgContentType,
            render,
          },
        ],
      });
    },
    [setRoomMsgHandle],
  );

  /**
   * 推送系統訊息 (不會收到回傳推波
   */
  const [postMsg] = useMutationApi(apiType.POST_MSG, {
    context: { isClient: true },
  });
  const postSystemMsg = useCallback(
    async (params = {}, contentType = MsgContentTypeKeys['System']) => {
      const [err, data] = await postMsg({
        in: {
          roomID: currentRoomID,
          type: RoomTypeKey['Consulting'],
          contentType,
          ...params,
        },
      });
      IS_CLIENT &&
        setRoomMsgHandle('PUSH', { msgList: [data.data.postMessage] });
      return [err, data];
    },
    [currentRoomID, postMsg, setRoomMsgHandle],
  );

  /**
   * 取得訊息
   */
  const { fetchData: fetchRoomMsg } = useQueryApi(apiType.LIST_MSG);
  const fetchRoomMsgHandle = useCallback(
    async (roomID, setMsgType = 'INITIAL') => {
      const { error, data } = await fetchRoomMsg({
        filter: { roomID },
        pagination: { offsetType: 'New', limit: MSG_LIMIT },
      });
      if (error) return;
      setRoomMsgHandle(setMsgType, { msgList: data.listMessage.messages });
      roomMsgTotal.current = data.listMessage.meta.total;
    },
    [fetchRoomMsg, setRoomMsgHandle],
  );

  /**
   * 取得訊息 並更新已讀id (進入房間用的
   */
  // 處理房間已讀訊息
  const [updateLastMsg] = useMutationApi(apiType.UPDATE_LAST_READ_MSG);
  const fetchInRoomMsgHandle = useCallback(
    async (roomID, setMsgType = 'INITIAL', limit = MSG_LIMIT) => {
      const { error, data } = await fetchRoomMsg({
        filter: { roomID },
        pagination: { offsetType: 'New', limit },
      });
      if (error) return;
      const messages = data?.listMessage.messages;
      messages.length && updateLastMsg({ roomID, messageID: messages[0].id });
      setRoomMsgHandle(setMsgType, { msgList: messages });
      roomMsgTotal.current = data.listMessage.meta.total;
      return messages;
    },
    [fetchRoomMsg, updateLastMsg, setRoomMsgHandle],
  );

  /**
   * 返回有效訊息
   * @returns {Array}
   *  */
  const filterEfficientMsg = useCallback(() => {
    return roomMsg.filter(
      ({ contentType }) => ![CusMsgContentType].includes(contentType),
    );
  }, [roomMsg]);

  /**
   * 載入更多訊息
   *  */
  const loadMoreMsg = useCallback(async () => {
    const roomID = currPreRoomID.current || currentRoomID;
    if (!roomID) return;
    const efficientMsg = filterEfficientMsg(); // 取得有效訊息
    if (!efficientMsg.length || efficientMsg.length >= roomMsgTotal.current) {
      return { moreMsg: false };
    }
    const { error, data } = await fetchRoomMsg({
      filter: { roomID },
      pagination: {
        offsetType: 'Old',
        // 找到最舊的有效訊息 id
        offsetID: efficientMsg[efficientMsg.length - 1].id,
        limit: 10, // 要的筆數
      },
    });
    if (error) return;
    setRoomMsgHandle('UNSHIFT', { msgList: data.listMessage.messages });
  }, [currentRoomID, fetchRoomMsg, filterEfficientMsg, setRoomMsgHandle]);

  /**
   * 載入上個房間訊息
   */
  const loadPreRoomMsg = async () => {
    const preRoomID = historyRooms.current[currPreRoomIdx.current] || 0;
    currPreRoomIdx.current = currPreRoomIdx.current + 1;
    currPreRoomID.current = preRoomID;
    if (!preRoomID) {
      onWarningMsg('已载入全部讯息');
      return;
    }
    const { error, data } = await fetchRoomMsg({
      filter: { roomID: preRoomID },
      pagination: { offsetType: 'New', limit: MSG_LIMIT },
    });
    if (error) return;
    setRoomMsgHandle('UNSHIFT', { msgList: data.listMessage.messages });
    roomMsgTotal.current = data.listMessage.meta.total + roomMsgTotal.current;
    return { moreMsg: true };
  };

  /**
   * 監聽處理
   */
  // 處理 已讀
  const recLastReadIDHandle = useCallback(
    async (lastReads = []) => {
      if (!lastReads.length) return;
      const lastReadID = lastReads.reduce((acc, curr) => {
        if (curr.userID !== MeID && curr.messageID > acc) {
          return curr.messageID;
        }
        return acc;
      }, 0);
      lastReadID && setLastReadID(lastReadID);
    },
    [MeID],
  );

  // 處理 對方訊息
  const { postUnRead } = useIframe();
  const recOtherSideMsgHandle = useCallback(
    msg => {
      setRoomMsgHandle('PUSH', { msgList: [msg] });
      postUnRead();
    },
    [postUnRead, setRoomMsgHandle],
  );

  // 處理 自己送出訊息的ws即時回應
  const recOwnMsgHandle = msg => {
    recLastReadIDHandle(msg.lastReads || []); // 接收 自己傳送訊息 對方的即時已讀

    // 客服的自動回覆訊息
    if (msg.from.tags && msg.from.tags.includes(MsgFromTags['AutoReplay'])) {
      setRoomMsgHandle('PUSH', { msgList: [msg] });
      return;
    }

    // bubble 類訊息
    if (msg.contentType === MsgContentTypeKeys['Bubble']) {
      setRoomMsgHandle('PUSH', { msgList: [msg] });
    }
  };

  useRecMessage({
    roomID: currentRoomID,
    // 處理一般訊息 (處理已讀)
    msgCommonType: recMsg => {
      if (recMsg.status === MsgStatusKeys['Retract']) return;
      recMsg.from.userID !== MeID
        ? recOtherSideMsgHandle(recMsg)
        : recOwnMsgHandle(recMsg);
    },
    // 接收已讀狀態 (對方進入房間觸發已讀)
    lastRead: ({ lastReads }) => recLastReadIDHandle(lastReads || []),
  });

  return {
    roomMsg,
    updateLastMsg,
    fetchRoomMsgHandle,
    fetchInRoomMsgHandle,
    setRoomMsgHandle,
    postSystemMsg,
    pushCusMsg,
    pushMsg,
    loadMoreMsg,
    loadPreRoomMsg,
    lastReadID,
  };
};

export default useRoomMessage;
