import { gql } from '@apollo/client';
import { COMMENT_USER_ROOMS } from './Fragment';

// 群組列表
export const LIST_GROUP_ROOM = gql`
  ${COMMENT_USER_ROOMS}
  query listGroupRoom(
    $filter: GroupRoomFilterInput!
    $pagination: PaginationInput
  ) {
    listGroupRoom(filter: $filter, pagination: $pagination) {
      groupRooms {
        roomID
        imageURL
        name
        isPublic
        inviteLevel # 加入的層級
        strangerLevel # 陌生人的等級
        strangerPassword
        ownerUserID
        createdAt
        userRooms {
          ...userRoomsFields
        }
      }
      meta {
        total
      }
    }
  }
`;

// 建立 群組聊天室
export const CREATE_GROUP = gql`
  mutation createGroup($in: GroupCreateInput!) {
    createGroup(in: $in) {
      id
      uid
      type
      createdAt
      updatedAt
    }
  }
`;
