import { gql } from '@apollo/client';

/**
 * 聯繫人客服
 */

// 列表
export const LIST_CONTACT_CUS_SVS = gql`
  query listContactCustomerService(
    $filter: ContactCustomerServiceFilterInput
    $pagination: PaginationInput
  ) {
    listContactCustomerService(filter: $filter, pagination: $pagination) {
      contactCustomerServices {
        id
        name
        avatarURL
        isDisplayEnabled
        createdAt
        updatedAt
      }
      meta {
        total
      }
    }
  }
`;

// 明細
export const GET_CONTACT_CUS_SVS = gql`
  query listContactCustomerService($filter: ContactCustomerServiceFilterInput) {
    listContactCustomerService(filter: $filter) {
      id
      name
      avatarURL
      isDisplayEnabled
      createdAt
      updatedAt
    }
  }
`;

// 新增
export const CREATED_CONTACT_CUS_SVS = gql`
  mutation createContactCustomerService(
    $in: ContactCustomerServiceCreateInput!
  ) {
    createContactCustomerService(in: $in) {
      id
      name
      avatarURL
      isDisplayEnabled
      createdAt
      createdAt
    }
  }
`;

// 更新
export const UPDATE_CONTACT_CUS_SVS = gql`
  mutation updateContactCustomerService(
    $filter: ContactCustomerServiceFilterInput!
    $in: ContactCustomerServiceUpdateInput!
  ) {
    updateContactCustomerService(filter: $filter, in: $in) {
      id
      name
      avatarURL
      isDisplayEnabled
      createdAt
      createdAt
    }
  }
`;

// 刪除
export const DELETE_CONTACT_CUS_SVS = gql`
  mutation deleteContactCustomerService(
    $filter: ContactCustomerServiceFilterInput!
  ) {
    deleteContactCustomerService(filter: $filter)
  }
`;
