import React, { useEffect } from 'react';
import { Button, Form, Select } from 'antd';
import { useSelector } from 'react-redux';
import { Box } from '@WRAP_COMP';
import CSForm from '@/components/ChatRoomContent/CSForm';

function BeforeEnter({
  updateQsHandel = () => {},
  setQueueQsID = () => {},
  onfinish = () => {},
}) {
  const { csRoomForm, csRoomQueueList, csRoomSetting } = useSelector(
    ({ client }) => client,
  );

  const [queueForm] = Form.useForm();
  const submitQueueForm = async () => {
    await queueForm.validateFields();
    onfinish();
  };
  const onChange = id => setQueueQsID(id);

  const queueFormRender = (cusType = false) => {
    return (
      <Form
        form={queueForm}
        layout='vertical'
        style={cusType ? {} : { width: '50%', marginTop: '-10%' }}>
        <Form.Item
          name='questionID'
          label='咨询问题'
          rules={[{ required: true, message: '请选择' }]}>
          <Select onChange={onChange} placeholder='请选择'>
            {csRoomQueueList.map(qus => (
              <Select.Option key={qus.id} value={qus.id}>
                {qus.question}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {!cusType && (
          <Button type='primary' block onClick={submitQueueForm}>
            送出
          </Button>
        )}
      </Form>
    );
  };

  const isShowQueue = () =>
    csRoomSetting.queueEnabled && csRoomQueueList.length > 0;

  useEffect(() => {
    if (!csRoomForm.isEnabled && !isShowQueue()) onfinish();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box bgColor='gray-1' p='3' flex='1 1 auto' overflow='scroll'>
      {csRoomForm.isEnabled ? (
        <CSForm
          formTitle={csRoomForm.title}
          formDataList={csRoomForm.formDataList}
          showVerificationCode={csRoomForm.isVerificationCode}
          updateQsHandel={updateQsHandel}
          submitHandle={submitQueueForm}
          cusFormCom={isShowQueue() ? queueFormRender(true) : null}
        />
      ) : (
        isShowQueue() && (
          <Box
            height='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'>
            {queueFormRender()}
          </Box>
        )
      )}
    </Box>
  );
}

export default BeforeEnter;
